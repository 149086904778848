import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { FlowSpinner } from '../atoms/Loading';

const propTypes = {
  children: PropTypes.element,
};

const defaultProps = {};

const LoadingSuspense = ({ children }) => {
  return <Suspense fallback={<FlowSpinner />}>{children}</Suspense>;
};

LoadingSuspense.propTypes = propTypes;
LoadingSuspense.defaultProps = defaultProps;

export default LoadingSuspense;
