import { setLocale } from 'yup';
import { suggestive } from 'yup-locale-ja';

// yup-locale-ja で定義されていないものがあるので追加
// (PR 送るなら yup-locale-ja ではなく yup-locales に送ったほうがいいかも)
const ja = {
  ...suggestive,
  array: {
    ...suggestive.array,
    length: ({ label, length }) =>
      (label ? label + 'は' : '') + `${length} つ選択してください`,
  },
};

const locales = { ja };

export default (locale = I18n.locale) => {
  if (locales[locale]) {
    setLocale(locales[locale]);
  }
};
