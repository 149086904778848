/* eslint @typescript-eslint/explicit-function-return-type: off */

import PropTypes from 'prop-types';
import ApplicationModel from '../ApplicationModel';

const SOURCE_DATA_TYPES = ['number', 'counter'];

const TARGET_TABLE_FIELDS = [
  // 計測ツール側のインプレッション数とクリック数はあくまで「コンバージョン」として扱う
  // なので媒体側のインプレション数やクリック数は一旦コメントアウト
  // (計測ツールのデータだけでなく、媒体側のデータも取り込めるようになったらコメントをはずす)
  // 'insights#impressions',
  // 'insights#clicks',
  'insights#conversions',
];

const propTypes = {
  sourceColumnName: PropTypes.string,
  sourceDataType: PropTypes.oneOf(SOURCE_DATA_TYPES),
  sourceDelimiter: PropTypes.string,
  sourceSeparator: PropTypes.string,
  sourceColumnValue: PropTypes.string,
  sourceCounterValue: PropTypes.string,
  targetTableField: PropTypes.oneOf(TARGET_TABLE_FIELDS),
  targetConversionName: PropTypes.string,
  validated: PropTypes.bool,
  ...ApplicationModel.propTypes,
};

const availableSourceDataTypesFor = ({ dataSourceType }) => {
  switch (dataSourceType) {
    case 'converted_events':
      return SOURCE_DATA_TYPES;
    case 'aggregated_conversions':
      return ['number'];
    default:
      return [];
  }
};

const initialProps = (dataSource) => ({
  sourceColumnName: '',
  sourceDataType: availableSourceDataTypesFor(dataSource)[0],
  sourceDelimiter: '',
  sourceSeparator: '',
  sourceColumnValue: '',
  sourceCounterValue: '',
  targetTableField: TARGET_TABLE_FIELDS[0],
  targetConversionName: '',
});

class MetricField extends ApplicationModel {
  static SOURCE_DATA_TYPES = SOURCE_DATA_TYPES;
  static TARGET_TABLE_FIELDS = TARGET_TABLE_FIELDS;
  static propTypes = propTypes;
  static initialProps = initialProps;
  static availableSourceDataTypesFor = availableSourceDataTypesFor;

  get sourceOptions() {
    switch (this.sourceDataType) {
      case 'number':
        return {
          sourceDelimiter: { isRequired: false },
          sourceSeparator: { isRequired: false },
        };
      case 'counter':
        return {
          sourceColumnValue: { isRequired: false },
          sourceCounterValue: { isRequired: false },
        };
      default:
        return {};
    }
  }

  get targetOptions() {
    switch (this.targetTableField) {
      case 'insights#conversions':
        return { targetConversionName: { isRequired: false } };
      default:
        return {};
    }
  }
}

export default MetricField;
