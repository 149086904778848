import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, ButtonToolbar, ButtonGroup } from 'react-bootstrap';

const propTypes = {
  table: PropTypes.element,
  info: PropTypes.element,
  length: PropTypes.element,
  pagination: PropTypes.element,
  colVis: PropTypes.element,
  searchBuilder: PropTypes.element,
  buttons: PropTypes.element,
};

const TableLayout = ({
  table,
  info,
  length,
  pagination,
  colVis,
  searchBuilder,
  buttons,
}) => {
  return (
    <div className='dataTables_wrapper dt-bootstrap'>
      <Row>
        <Col sm={6}>
          <div className='dataTables_length'>
            {length}
            {' / '}
            {info}
          </div>
        </Col>
        <Col sm={6} className='text-right'>
          <ButtonToolbar style={{ display: 'inline-block' }}>
            {searchBuilder}
            {colVis}
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>{table}</Col>
      </Row>
      <Row>
        <Col sm={5}>
          <ButtonGroup className='dt-buttons'>{buttons}</ButtonGroup>
        </Col>
        <Col sm={7} className='text-right'>
          <div className='dataTables_paginate'>{pagination}</div>
        </Col>
      </Row>
    </div>
  );
};

TableLayout.propTypes = propTypes;

export default TableLayout;
