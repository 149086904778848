/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import CopyBudgetsModal from './CopyBudgetsModal';
import * as FlashMessage from '../../lib/FlashMessage';

const scope = 'frontend.components.BudgetReport.ActionSelectDropdown';

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onBudgetsCopy: PropTypes.func,
  disabled: PropTypes.bool,
};

class ActionSelectDropdown extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.state = {
      copyModal: { show: false, submitting: false },
    };
  }

  handleModalToggle = (name, show) => {
    return () => {
      const modalState = Object.assign({}, this.state[name], { show });
      this.setState({ [name]: modalState });
    };
  };

  handleModalSubmit = (name, callback) => {
    return (params) => {
      return Promise.resolve()
        .then(() => {
          const modalState = Object.assign({}, this.state[name], {
            submitting: true,
          });
          this.setState({ [name]: modalState });
          return params;
        })
        .then(callback)
        .then(() =>
          FlashMessage.add(
            'success',
            I18n.t(`${scope}.flash.copy_budgets.success`)
          )
        )
        .catch((error) => {
          console.error(error);
          FlashMessage.add(
            'error',
            I18n.t(`${scope}.flash.copy_budgets.error`)
          );
        })
        .finally(() => {
          const modalState = Object.assign({}, this.state[name], {
            submitting: false,
            show: false,
          });
          this.setState({ [name]: modalState });
        });
    };
  };

  render = () => {
    const { id, onBudgetsCopy, disabled } = this.props;
    const { copyModal } = this.state;

    return (
      <>
        <DropdownButton
          id={id}
          title={
            <>
              <i className='fa fa-cogs' />
              <span style={{ margin: '0 5px' }}>
                {I18n.t(`${scope}.label`)}
              </span>
            </>
          }
          disabled={disabled}
          pullRight
        >
          <MenuItem onClick={this.handleModalToggle('copyModal', true)}>
            {I18n.t(`${scope}.selection.copy_budgets`)}
          </MenuItem>
        </DropdownButton>
        <CopyBudgetsModal
          {...copyModal}
          onSubmit={this.handleModalSubmit('copyModal', onBudgetsCopy)}
          onHide={this.handleModalToggle('copyModal', false)}
        />
      </>
    );
  };
}

export default ActionSelectDropdown;
