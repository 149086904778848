import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactTableContext } from '../../../contexts';
import { formatInteger } from '../../../lib/NumberUtils';

const scope =
  'frontend.components.molecules.ReactTableDataTableStyled.PagingInfo';

const propTypes = {
  totalCount: PropTypes.number,
};

const PagingInfo = ({ totalCount }) => {
  if (totalCount < 1) {
    return null;
  }

  const { getState } = useContext(ReactTableContext);
  const {
    pagination: { pageIndex, pageSize: limit },
  } = getState();

  const totalPages =
    parseInt(totalCount / limit) + (totalCount % limit ? 1 : 0);
  const currentPage = pageIndex + 1;

  const displayStart = (currentPage - 1) * limit + 1;
  const displayEnd =
    currentPage == totalPages ? totalCount : currentPage * limit;

  return (
    <>
      {I18n.t([scope, 'text'], {
        totalCount: formatInteger(totalCount),
        displayStart: formatInteger(displayStart),
        displayEnd: formatInteger(displayEnd),
      })}
    </>
  );
};

PagingInfo.propTypes = propTypes;

export default PagingInfo;
