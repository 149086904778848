/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import { propTypes } from '../DataTableBeLike.propTypes';
import Loading from './TableBody/Loading';
import Empty from './TableBody/Empty';

const TableBody = ({ columns, data, loading, emptyDataMessage }) => {
  if (loading) {
    return <Loading columns={columns} />;
  }

  if (!data || data.length == 0) {
    return <Empty columns={columns} message={emptyDataMessage} />;
  }

  return (
    <tbody>
      {data.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {columns.map((column, columnIndex) => (
            <td
              key={columnIndex}
              className={
                typeof column.className == 'function'
                  ? column.className(row, column, rowIndex)
                  : column.className
              }
            >
              {typeof column.data == 'function'
                ? column.data(row, column, rowIndex)
                : row[column.data]}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

TableBody.propTypes = {
  columns: propTypes.columns,
  data: propTypes.data,
  loading: propTypes.loading,
  emptyDataMessage: propTypes.emptyDataMessage,
};

export default TableBody;
