import React from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const withDnDProvider = (Component) => {
  const WrappedComponent = (props) => (
    <DndProvider backend={HTML5Backend}>
      <Component {...props} />
    </DndProvider>
  );

  WrappedComponent.displayName = `withDnDProvider(${
    Component.displayName || Component.name || 'Component'
  })`;
  WrappedComponent.propTypes = Component.propTypes;
  WrappedComponent.defaultProps = Component.defaultProps;

  return WrappedComponent;
};
