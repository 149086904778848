/* eslint @typescript-eslint/explicit-function-return-type: off */

import PropTypes from 'prop-types';
import ApplicationModel from './ApplicationModel';
import ReportRow from './MonthlyBudget/ReportRow';
import ReportRowFilter from './MonthlyBudget/ReportRowFilter';

const propTypes = {
  ...ApplicationModel.propTypes,
  projectId: PropTypes.number,
  ResourceType: PropTypes.number,
  ResourceId: PropTypes.number,
  channel: PropTypes.number,
  adAccountId: PropTypes.number,
  adCampaignId: PropTypes.number,
  month: PropTypes.string,
  amountMicro: PropTypes.number,
};

class MonthlyBudget extends ApplicationModel {
  static propTypes = propTypes;
}

export default MonthlyBudget;
export { ReportRow, ReportRowFilter };
