import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ReactTableContext } from '../../../../../contexts';
import { useCurrentProjectAccessibility } from '../../../../../hooks';
import { connect, Field } from 'formik';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import {
  Table,
  PageLength,
  Pager,
  PagingInfo,
  Layout,
} from '../../../../molecules/ReactTableDataTableStyled';
import { FormGroup, ErrorMessage } from '../../../../../react-bootstrap-formik';
import {
  FormControl,
  ControlLabel,
  ButtonGroup,
  Button,
} from 'react-bootstrap';
import BulkChangeAmountModal from './BulkChangeAmountModal';
import EqualAllocateAmountModal from './EqualAllocateAmountModal';
import { formatCurrency } from '../../../../../lib/NumberUtils';
import { scope } from '..';

const propTypes = {
  formik: PropTypes.object,
};

const defaultProps = {};

const DailyAmountsField = ({
  formik: {
    values: { dailyAmounts },
  },
}) => {
  const {
    accessibility: { project },
  } = useCurrentProjectAccessibility();

  const totalAmount = dailyAmounts.reduce((totalAmount, { amount }) => {
    return amount ? totalAmount + amount : totalAmount;
  }, 0);

  const columnHelper = createColumnHelper();
  const columns = useMemo(
    () => [
      columnHelper.accessor('scheduledDate', {
        header: I18n.t([scope, 'dailyAmounts.table.header.scheduledDate']),
        cell: ({ getValue }) => I18n.l('date.formats.medium', getValue()),
      }),
      columnHelper.accessor('amount', {
        header: I18n.t([scope, 'dailyAmounts.table.header.amount']),
        cell: ({ row: { index, original: dailyAmount } }) => {
          const [amount, setAmount] = useState(dailyAmount.amount);

          return (
            <Field name={`dailyAmounts[${index}].amount`}>
              {({ field: { name, onBlur } }) => (
                <FormGroup name={name}>
                  <FormControl
                    type='number'
                    name={name}
                    value={amount}
                    onChange={({ target: { value } }) =>
                      setAmount(value ? Number(value) : '')
                    }
                    onBlur={(ev) => {
                      // field の onChange に値を渡してしまうと dailyAmounts の配列が全部再描画されてしまい、
                      // タブキーで次の入力ボックスに移動したフォーカスが解除されてしまって UX がよくない
                      // そのため、配列の一部を直接差し替えている (あまりよろしくないかもしれないが)
                      dailyAmounts[index] = { ...dailyAmount, amount };
                      onBlur(ev);
                    }}
                  />
                  <ErrorMessage name={name} />
                </FormGroup>
              )}
            </Field>
          );
        },
      }),
    ],
    [dailyAmounts]
  );

  const table = useReactTable({
    columns,
    data: dailyAmounts,
    initialState: { pagination: { pageSize: 100 } },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <FormGroup>
      <ControlLabel>{I18n.t([scope, 'dailyAmounts.label'])}</ControlLabel>
      <div style={{ marginBottom: '12px' }}>
        <ButtonGroup>
          <EqualAllocateAmountModal>
            {({ showModal }) => (
              <Button onClick={showModal} disabled={dailyAmounts.length == 0}>
                {I18n.t([
                  scope,
                  'dailyAmounts.table.button.equalAllocateAmount',
                ])}
              </Button>
            )}
          </EqualAllocateAmountModal>
          <BulkChangeAmountModal>
            {({ showModal }) => (
              <Button onClick={showModal} disabled={dailyAmounts.length == 0}>
                {I18n.t([scope, 'dailyAmounts.table.button.bulkChangeAmount'])}
              </Button>
            )}
          </BulkChangeAmountModal>
        </ButtonGroup>
      </div>
      <ReactTableContext.Provider value={table}>
        <Layout
          table={<Table responsive />}
          length={<PageLength />}
          pagination={<Pager />}
          info={
            <>
              <PagingInfo totalCount={dailyAmounts.length} />
              {' / '}
              {!!dailyAmounts.length &&
                I18n.t([scope, 'dailyAmounts.table.info.totalAmount'], {
                  totalAmount: formatCurrency(totalAmount, project.currency),
                })}
            </>
          }
        />
      </ReactTableContext.Provider>
    </FormGroup>
  );
};

DailyAmountsField.propTypes = propTypes;
DailyAmountsField.defaultProps = defaultProps;

export default connect(DailyAmountsField);
