/* eslint @typescript-eslint/explicit-function-return-type: off */

import * as Routes from '../routes/CommissionConfig';
import * as RESTAPI from '../lib/RESTAPI';

export const list = (projectId) => {
  const url = Routes.indexPath(projectId);
  return RESTAPI.get(url);
};

export const create = (projectId, configParams) => {
  const url = Routes.createPath(projectId);
  return RESTAPI.post(url, { commissionConfig: configParams });
};

export const update = (projectId, configId, configParams) => {
  const url = Routes.updatePath(projectId, configId);
  return RESTAPI.patch(url, { commissionConfig: configParams });
};

export const destroy = (projectId, configId) => {
  const url = Routes.destroyPath(projectId, configId);
  return RESTAPI.destroy(url);
};
