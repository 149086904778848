/* eslint @typescript-eslint/explicit-function-return-type: off */

import PropTypes from 'prop-types';
import ApplicationModel from '../ApplicationModel';

const SOURCE_DATA_TYPES = ['date', 'datetime'];

const TARGET_TABLE_FIELDS = ['insights#based_on'];

const propTypes = {
  ...ApplicationModel.propTypes,
  sourceColumnName: PropTypes.string,
  sourceDataType: PropTypes.oneOf(SOURCE_DATA_TYPES),
  sourceDateFormat: PropTypes.string,
  sourceDatetimeFormat: PropTypes.string,
  sourceTimezone: PropTypes.string,
  targetTableField: PropTypes.oneOf(TARGET_TABLE_FIELDS),
  validated: PropTypes.bool,
};

const availableSourceDataTypesFor = ({ dataSourceType, level }) => {
  switch (`${dataSourceType}-${level}`) {
    case 'converted_events-daily':
      return ['datetime'];
    case 'aggregated_conversions-daily':
      return ['date'];
    default:
      return [];
  }
};

const initialProps = (dataSource) => ({
  sourceColumnName: '',
  sourceDataType: availableSourceDataTypesFor(dataSource)[0],
  sourceDateFormat: '',
  sourceDatetimeFormat: '',
  sourceTimezone: '',
  targetTableField: TARGET_TABLE_FIELDS[0],
});

class SegmentField extends ApplicationModel {
  static SOURCE_DATA_TYPES = SOURCE_DATA_TYPES;
  static TARGET_TABLE_FIELDS = TARGET_TABLE_FIELDS;
  static propTypes = propTypes;
  static initialProps = initialProps;
  static availableSourceDataTypesFor = availableSourceDataTypesFor;

  get sourceOptions() {
    switch (this.sourceDataType) {
      case 'datetime':
        return {
          sourceDatetimeFormat: { isRequired: false },
          sourceTimezone: { isRequired: false },
        };
      case 'date':
        return { sourceDateFormat: { isRequired: false } };
      default:
        return {};
    }
  }
}

export default SegmentField;
