import React, { createContext } from 'react';
import { queryProjectAccessibility } from '../graphql';
import retry from 'async-retry';

const Context = createContext();

export default Context;
export const { Provider, Consumer } = Context;

export const withCurrentProjectAccessibility = (Component) => {
  let currentProjectAccessibility;
  let error;

  const WrappedComponent = (props) => {
    const matchResult = location.pathname.match(/\/projects\/(\d+)/);
    if (!matchResult) {
      return <Component {...props} />;
    }

    if (error) {
      throw error;
    }

    const [, projectId] = matchResult;
    if (!currentProjectAccessibility) {
      throw retry(
        async (_bail, _attempt) => {
          currentProjectAccessibility = await queryProjectAccessibility(
            projectId
          );
        },
        { retries: 2 }
      ).catch((err) => (error = err));
    }

    return (
      <Provider value={{ accessibility: currentProjectAccessibility }}>
        <Component {...props} />
      </Provider>
    );
  };

  WrappedComponent.displayName = `withCurrentProjectAccessibility(${
    Component.displayName || Component.name || 'Component'
  })`;
  WrappedComponent.propTypes = Component.propTypes;
  WrappedComponent.defaultProps = Component.defaultProps;

  return WrappedComponent;
};
