/* eslint @typescript-eslint/explicit-function-return-type: off */

const getContainer = () => {
  return document.querySelector('.flash-messages-container');
};

export const add = (level, message) => {
  const container = getContainer();
  if (!container) {
    return;
  }

  let containerClass = 'alert-info';
  let iconClass = 'fa-info';
  switch (level) {
    case 'success':
      containerClass = 'alert-success';
      iconClass = 'fa-check';
      break;
    case 'warning':
      containerClass = 'alert-warning';
      iconClass = 'fa-warning';
      break;
    case 'error':
      containerClass = 'alert-danger';
      iconClass = 'fa-ban';
      break;
  }

  const closeButton = document.createElement('button');
  closeButton.textContent = 'x';
  closeButton.classList.add('close');
  closeButton.setAttribute('type', 'button');
  closeButton.setAttribute('data-dismiss', 'alert');
  closeButton.setAttribute('aria-hidden', true);

  const icon = document.createElement('i');
  icon.classList.add('fa', iconClass, 'icon');

  const messageContainer = document.createElement('div');
  messageContainer.classList.add('alert', containerClass, 'alert-dismissible');
  messageContainer.appendChild(closeButton);
  messageContainer.appendChild(icon);
  messageContainer.insertAdjacentHTML('beforeend', message);

  container.appendChild(messageContainer);

  return messageContainer;
};

export const remove = (messageContainer) => {
  const container = getContainer();
  if (!container) {
    return;
  }

  container.removeChild(messageContainer);
};

export const removeAll = () => {
  const container = getContainer();
  if (!container) {
    return;
  }

  while (container.firstChild) {
    container.removeChild(container.firstChild);
  }
};
