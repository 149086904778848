import React from 'react';
import PropTypes from 'prop-types';
import { connect, FieldArray } from 'formik';
import { ControlLabel, Tabs, Tab, Badge } from 'react-bootstrap';
import { FormGroup, ErrorMessage } from '../../../../../react-bootstrap-formik';
import HelpIconTooltip from '../../../../atoms/HelpIconTooltip';
import { RESOURCE_COUNT_LIMIT } from '../../../Form';
import { ResourceType } from '../../../../../enums/PeriodBudgetResource';
import AdAccountTabContent from './AdAccountTabContent';
import AdCampaignTabContent from './AdCampaignTabContent';
import AdGroupTabContent from './AdGroupTabContent';
import AdTabContent from './AdTabContent';
import { scope } from '../';

const propTypes = {
  formik: PropTypes.object,
};

const defaultProps = {};

const ResourcesField = ({
  formik: {
    values: { resources },
  },
}) => {
  const withBadge = ({ title, badge }) => {
    if (badge) {
      return (
        <>
          {title} <Badge>{badge}</Badge>
        </>
      );
    }
    return title;
  };

  const withTabContentPadding = (component) => (
    <div style={{ paddingTop: 12 }}>{component}</div>
  );

  const adAccountResources = resources.filter(
    ({ resourceType }) => resourceType == ResourceType.AdAccount
  );
  const adCampaignResources = resources.filter(
    ({ resourceType }) => resourceType == ResourceType.AdCampaign
  );
  const adGroupResources = resources.filter(
    ({ resourceType }) => resourceType == ResourceType.AdGroup
  );
  const adResources = resources.filter(
    ({ resourceType }) => resourceType == ResourceType.Ad
  );

  return (
    <FieldArray name='resources'>
      {({ name }) => (
        <>
          <FormGroup name={name}>
            <ControlLabel>
              {I18n.t([scope, 'resources.label'])}{' '}
              <HelpIconTooltip
                placement='top'
                message={I18n.t([scope, 'resources.tooltip'], {
                  limit: RESOURCE_COUNT_LIMIT,
                })}
              />
            </ControlLabel>
            <ErrorMessage name={name} />
          </FormGroup>
          <FormGroup>
            <Tabs
              defaultActiveKey={'adAccounts'}
              id='resources-tab'
              animation={false}
            >
              <Tab
                eventKey='adAccounts'
                title={withBadge({
                  title: I18n.t([scope, 'resources.tabs.adAccounts.title']),
                  badge: adAccountResources.length,
                })}
              >
                {withTabContentPadding(<AdAccountTabContent />)}
              </Tab>
              <Tab
                eventKey='adCampaigns'
                title={withBadge({
                  title: I18n.t([scope, 'resources.tabs.adCampaigns.title']),
                  badge: adCampaignResources.length,
                })}
              >
                {withTabContentPadding(<AdCampaignTabContent />)}
              </Tab>
              <Tab
                eventKey='adGroups'
                title={withBadge({
                  title: I18n.t([scope, 'resources.tabs.adGroups.title']),
                  badge: adGroupResources.length,
                })}
              >
                {withTabContentPadding(<AdGroupTabContent />)}
              </Tab>
              <Tab
                eventKey='ads'
                title={withBadge({
                  title: I18n.t([scope, 'resources.tabs.ads.title']),
                  badge: adResources.length,
                })}
              >
                {withTabContentPadding(<AdTabContent />)}
              </Tab>
            </Tabs>
          </FormGroup>
        </>
      )}
    </FieldArray>
  );
};

ResourcesField.propTypes = propTypes;
ResourcesField.defaultProps = defaultProps;

export default connect(ResourcesField);
