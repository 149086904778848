/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import * as BS from 'react-bootstrap';

const Body = ({ children, ...props }) => (
  <BS.Modal.Body {...props}>{children}</BS.Modal.Body>
);

Body.propTypes = BS.Modal.Body.propTypes;
Body.defaultProps = BS.Modal.Body.defaultProps;

export default Body;
