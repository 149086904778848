import { client } from '../';

const AdsQuery = `
  query($search: JSON!, $order: [String!], $page: Int!, $limit: Int!) {
    ads(
      search: $search
      order: $order
      page: $page
      limit: $limit
    ) {
      data {
        id, name, remoteId, remoteStatus, creativeType,
        thumbnailUrl, imageUrl, videoUrl,
        leadText, subText, subText2, cta, ctaLinkUrl
        adGroup { id, name, remoteId, remoteStatus }
        adCampaign { id, name, remoteId, remoteStatus, objectiveName }
        adAccount { id, name, remoteId, remoteStatus }
        channel { id, name }
      }
      paging { totalCount, totalPages }
    }
  }
`;

export const queryAds = async (variables, operationContext = {}) => {
  const { data, error } = await client
    .query(AdsQuery, variables, operationContext)
    .toPromise();
  if (error) {
    throw new Error(error);
  }

  return data.ads;
};
