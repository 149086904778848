/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import AdPerformanceReportDataSource from '../../../models/AdPerformanceReportDataSource';
import MetricFieldForm from './MetricFieldsForm/MetricFieldForm';

const scope =
  'frontend.components.AdPerformanceReportDataSource.Form.MetricFieldsForm';

const propTypes = {
  dataSource: PropTypes.exact(AdPerformanceReportDataSource.propTypes),
  onChange: PropTypes.func,
};

class MetricFieldsForm extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.handleAdd = this.handleAdd.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  handleAdd(event) {
    event.preventDefault();
    const { dataSource, onChange } = this.props;
    const metricFields = dataSource.metricFields.slice();
    metricFields.push(dataSource.buildMetricField());
    onChange({ ...dataSource, metricFields });
  }

  handleChange(index) {
    return (field, event) => {
      event.preventDefault();
      const { dataSource, onChange } = this.props;
      const metricFields = dataSource.metricFields.slice();
      metricFields[index] = field;
      onChange({ ...dataSource, metricFields });
    };
  }

  handleRemove(index) {
    return (event) => {
      event.preventDefault();
      const { dataSource, onChange } = this.props;
      const metricFields = dataSource.metricFields.slice();
      metricFields.splice(index, 1);
      onChange({ ...dataSource, metricFields });
    };
  }

  render() {
    const { dataSource } = this.props;
    const {
      metricFields,
      minMetricFieldCount: min,
      maxMetricFieldCount: max,
    } = dataSource;

    return (
      <>
        <BS.Table responsive striped bordered hover className='form-table'>
          <thead>
            <tr>
              <th className='fixed-xlarge' colSpan='3'>
                {I18n.t(`${scope}.table.heading.source`)}
              </th>
              <th className='fixed-xlarge' colSpan='2'>
                {I18n.t(`${scope}.table.heading.target`)}
              </th>
              <th className='fixed-min' />
            </tr>
          </thead>
          <tbody>
            {metricFields.map((field, index) => (
              <MetricFieldForm
                key={index}
                dataSource={dataSource}
                field={field}
                onChange={this.handleChange(index)}
                onRemove={min <= index && this.handleRemove(index)}
              />
            ))}
          </tbody>
        </BS.Table>
        {metricFields.length < max && (
          <a className='pull-right' href='' onClick={this.handleAdd}>
            <span
              dangerouslySetInnerHTML={{
                __html: I18n.t(`${scope}.action.add`),
              }}
            ></span>
          </a>
        )}
      </>
    );
  }
}

export default MetricFieldsForm;
