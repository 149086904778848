/* eslint @typescript-eslint/explicit-function-return-type: off */

import PropTypes from 'prop-types';
import ApplicationModel, { CarrierWaveResource } from './ApplicationModel';

const STATUSES = [
  'pending_preprocessing',
  'preprocessing',
  'ready',
  'pending_import',
  'importing',
  'finished',
  'canceled',
];

const propTypes = {
  ...ApplicationModel.propTypes,
  projectId: PropTypes.number,
  dataSourceId: PropTypes.number,
  sourceFile: PropTypes.exact(CarrierWaveResource.propTypes),
  originalFilename: PropTypes.string,
  encoding: PropTypes.string,
  withBom: PropTypes.bool,
  beginningCsvLine: PropTypes.number,
  sourceDate: PropTypes.string,
  headerColumns: PropTypes.arrayOf(PropTypes.string),
  preprocessedFile: PropTypes.exact(CarrierWaveResource.propTypes),
  dataCount: PropTypes.number,
  errorCount: PropTypes.number,
  excludedCount: PropTypes.number,
  skipPreview: PropTypes.bool,
  status: PropTypes.oneOf(STATUSES),
  errorType: PropTypes.string,
  errorDetails: PropTypes.string,
};

const initialProps = () => ({
  sourceFile: null,
  beginningCsvLine: 1,
  sourceDate: '',
  skipPreview: false,
});

class AdPerformanceReportImportJob extends ApplicationModel {
  static STATUSES = STATUSES;
  static propTypes = propTypes;
  static initialProps = initialProps;
}

export default AdPerformanceReportImportJob;
