/* eslint @typescript-eslint/explicit-function-return-type: off */

import * as Routes from '../routes/AdPerformanceReportDataSource';
import * as RESTAPI from '../lib/RESTAPI';

export const create = (projectId, dataSourceParams) => {
  const url = Routes.createPath(projectId);
  return RESTAPI.post(url, { adPerformanceReportDataSource: dataSourceParams });
};

export const update = (projectId, dataSourceId, dataSourceParams) => {
  const url = Routes.updatePath(projectId, dataSourceId);
  return RESTAPI.patch(url, {
    adPerformanceReportDataSource: dataSourceParams,
  });
};
