/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import { propTypes } from '../../DataTableBeLike.propTypes';
import { FlowSpinner } from '../../../atoms/Loading';

const Loading = ({ columns }) => (
  <tbody>
    <tr>
      <td colSpan={columns.length}>
        <FlowSpinner />
      </td>
    </tr>
  </tbody>
);

Loading.propTypes = {
  columns: propTypes.columns,
};

export default Loading;
