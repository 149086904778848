/* eslint @typescript-eslint/explicit-function-return-type: off */

import PropTypes from 'prop-types';

const FIELD_DEFINITIONS = {
  projectName: { type: 'string' },
  channelName: { type: 'string' },
  adAccountName: { type: 'string' },
  adCampaignName: { type: 'string' },
  impressions: { type: 'number' },
};

const STRING_EXPRS = ['include', 'exclude'];

const NUMBER_EXPRS = ['gt', 'gteq', 'eq', 'lteq', 'lt'];

const propTypes = {
  field: PropTypes.oneOf(Object.keys(FIELD_DEFINITIONS)),
  expr: PropTypes.oneOf([...STRING_EXPRS, ...NUMBER_EXPRS]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

class Matcher {
  static propTypes = propTypes;

  static STRING_EXPRS = STRING_EXPRS;
  static NUMBER_EXPRS = NUMBER_EXPRS;

  constructor(props) {
    for (const [name, value] of Object.entries(props)) {
      this[name] = value;
    }
  }

  match = (row) => {
    const { field, expr, value } = this;
    if (field == null || expr == null || value == null) return false;

    const { [field]: effectiveValue } = row;
    if (effectiveValue == null) return false;

    switch (expr) {
      case 'include':
        return effectiveValue.toLowerCase().includes(value.toLowerCase());
      case 'exclude':
        return !effectiveValue.toLowerCase().includes(value.toLowerCase());
      case 'gt':
        return effectiveValue > value;
      case 'gteq':
        return effectiveValue >= value;
      case 'eq':
        return effectiveValue == value;
      case 'lteq':
        return effectiveValue <= value;
      case 'lt':
        return effectiveValue < value;
      default:
        return false;
    }
  };
}

export default Matcher;
