import React from 'react';
import PropTypes from 'prop-types';
import { connect, Field } from 'formik';
import { ControlLabel, FormControl } from 'react-bootstrap';
import { FormGroup, ErrorMessage } from '../../../../react-bootstrap-formik';
import DateRangeField from './DateRangeField';
import CalendarDatesField from './CalendarDatesField';
import DailyAmountsField from './DailyAmountsField';
import ResourcesField from './ResourcesField';

export const scope = 'frontend.components.PeriodBudgetReport.Form.field';

const propTypes = {
  formik: PropTypes.object,
};

const defaultProps = {};

const FormFields = ({ formik: { values, setValues } }) => {
  return (
    <>
      <Field name='name'>
        {({ field }) => (
          <FormGroup name={field.name}>
            <ControlLabel>{I18n.t([scope, 'name.label'])}</ControlLabel>
            <FormControl type='text' {...field} />
            <ErrorMessage name={field.name} />
          </FormGroup>
        )}
      </Field>

      <Field name='dateSelectionMode'>
        {({ field }) => (
          <FormGroup name={field.name}>
            <ControlLabel>
              {I18n.t([scope, 'dateSelectionMode.label'])}
            </ControlLabel>
            <FormControl
              componentClass='select'
              {...field}
              onChange={(ev) => {
                setValues((prev) => ({
                  ...prev,
                  [field.name]: ev.target.value,
                  dailyAmounts: [],
                }));
              }}
            >
              {['date_range', 'date_select'].map((value, index) => (
                <option key={index} value={value}>
                  {I18n.t([scope, `dateSelectionMode.selections.${value}`])}
                </option>
              ))}
            </FormControl>
            <ErrorMessage name={field.name} />
          </FormGroup>
        )}
      </Field>

      {values.dateSelectionMode == 'date_range' && <DateRangeField />}
      {values.dateSelectionMode == 'date_select' && <CalendarDatesField />}

      <DailyAmountsField />

      <ResourcesField />
    </>
  );
};

FormFields.propTypes = propTypes;
FormFields.defaultProps = defaultProps;

export default connect(FormFields);
