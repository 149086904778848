/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import DataTableBeLike from '../molecules/DataTableBeLike';
import Project from '../../models/Project';
import * as Resource from '../../apis/CsvImportJob';
import * as Routes from '../../routes/CsvImportJob';
import { formatDateTime } from '../../lib/DateUtils';
import { formatInteger } from '../../lib/NumberUtils';

const scope = 'frontend.components.CsvImportJob.ListTable';

const propTypes = {
  project: PropTypes.exact(Project.propTypes),
  columns: PropTypes.arrayOf(PropTypes.string),
  totalPages: DataTableBeLike.propTypes.totalPages,
  currentPage: DataTableBeLike.propTypes.currentPage,
  limit: DataTableBeLike.propTypes.limit,
};

const defaultProps = {
  columns: [
    'createdAt',
    'adAccount',
    'sourceFile',
    // 'level',
    // 'breakdown',
    'reportType',
    'status',
    'dataCount',
    'validCount',
    'updatedAt',
    'actions',
  ],
  totalPages: DataTableBeLike.defaultProps.totalPages,
  currentPage: DataTableBeLike.defaultProps.currentPage,
  limit: DataTableBeLike.defaultProps.limit,
};

class ListTable extends React.Component {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  constructor(props) {
    super(props);

    this.tableColumns = this.tableColumns.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleLimitChange = this.handleLimitChange.bind(this);

    const { totalPages, currentPage, limit } = props;
    this.state = {
      columns: this.tableColumns(),
      data: [],
      fetching: false,
      totalPages,
      currentPage,
      limit,
    };

    this.limitSelections = [
      limit,
      ...DataTableBeLike.defaultProps.limitSelections,
    ]
      .sort((a, b) => a - b)
      .reduce((selections, v) => {
        if (!v || selections.includes(v)) {
          return selections;
        }

        selections.push(v);
        return selections;
      }, []);
  }

  tableColumns() {
    const { columns } = this.props;
    return columns.reduce((tableColumns, column) => {
      switch (column) {
        case 'adAccount':
          tableColumns.push({
            header: I18n.t(`${scope}.header.adAccountName`),
            data: ({ adAccount }) => adAccount.name,
          });
          break;
        case 'sourceFile':
          tableColumns.push({
            header: I18n.t(`${scope}.header.sourceFile`),
            data: (job) => (
              <a href={job.sourceFile.url}>{job.originalFilename}</a>
            ),
          });
          break;
        case 'level':
          tableColumns.push({
            header: I18n.t(`${scope}.header.level`),
            data: ({ level }) =>
              level != null ? I18n.t(`${scope}.datarow.level.${level}`) : '-',
          });
          break;
        case 'breakdown':
          tableColumns.push({
            header: I18n.t(`${scope}.header.breakdown`),
            data: ({ breakdown }) =>
              breakdown != null
                ? I18n.t(`${scope}.datarow.breakdown.${breakdown}`)
                : null,
          });
          break;
        case 'reportType':
          tableColumns.push({
            header: I18n.t(`${scope}.header.reportType`),
            data: ({ level, breakdown }) =>
              I18n.t(
                `${scope}.datarow.reportType.${
                  breakdown ? 'with_breakdown' : 'without_breakdown'
                }`,
                {
                  level: I18n.t(`${scope}.datarow.level.${level}`),
                  breakdown: I18n.t(`${scope}.datarow.breakdown.${breakdown}`),
                }
              ),
          });
          break;
        case 'dataCount':
          tableColumns.push({
            header: I18n.t(`${scope}.header.dataCount`),
            data: ({ dataCount }) =>
              dataCount != null ? formatInteger(dataCount) : '-',
          });
          break;
        case 'validCount':
          tableColumns.push({
            header: I18n.t(`${scope}.header.validCount`),
            data: ({ dataCount, errorCount }) =>
              dataCount != null ? formatInteger(dataCount - errorCount) : '-',
          });
          break;
        case 'errorCount':
          tableColumns.push({
            header: I18n.t(`${scope}.header.errorCount`),
            data: ({ errorCount }) =>
              errorCount != null ? formatInteger(errorCount) : '-',
          });
          break;
        case 'status':
          tableColumns.push({
            header: I18n.t(`${scope}.header.status`),
            data: ({ status }) => I18n.t(`${scope}.datarow.status.${status}`),
          });
          break;
        case 'createdAt':
          tableColumns.push({
            header: I18n.t(`${scope}.header.createdAt`),
            data: ({ createdAt }) => formatDateTime(createdAt),
          });
          break;
        case 'updatedAt':
          tableColumns.push({
            header: I18n.t(`${scope}.header.updatedAt`),
            data: ({ updatedAt }) => formatDateTime(updatedAt),
          });
          break;
        case 'actions':
          tableColumns.push({
            header: I18n.t(`${scope}.header.actions`),
            data: (job) => (
              <a href={Routes.detailsPath(job.projectId, job.id)}>
                {I18n.t(`${scope}.datarow.actions.details`)}
              </a>
            ),
          });
          break;
      }
      return tableColumns;
    }, []);
  }

  componentDidMount() {
    this.refreshList();
  }

  refreshList(stateChanges = {}) {
    const newState = { ...this.state, ...stateChanges, fetching: true };
    this.setState(newState);

    const { project } = this.props;
    const { currentPage: page, limit } = newState;
    Resource.list(project.id, {
      page,
      limit,
    })
      .then((response) => {
        const { data, paging } = response;
        const { total: totalPages, current: currentPage } = paging;
        this.setState({
          data,
          totalPages,
          currentPage,
          fetching: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handlePageChange(page) {
    this.refreshList({ currentPage: page });
  }

  handleLimitChange(limit) {
    this.refreshList({ limit, currentPage: 1 });
  }

  render() {
    const { columns, data, fetching, totalPages, currentPage, limit } =
      this.state;

    return (
      <DataTableBeLike
        columns={columns}
        data={data}
        loading={fetching}
        totalPages={totalPages}
        currentPage={currentPage}
        limitSelections={this.limitSelections}
        limit={limit}
        onPageChange={this.handlePageChange}
        onLimitChange={this.handleLimitChange}
        emptyDataMessage={I18n.t(`${scope}.message.emptyData`)}
      />
    );
  }
}

export default ListTable;
