export const search = ['and', 'or'].reduce((builder, condition) => {
  builder[condition] = (expressions) => ({
    [condition]: expressions.filter((exp) => !!exp),
  });
  return builder;
}, {});

export const filter = Object.entries({
  eq: 'EQUAL',
  notEq: 'NOT_EQUAL',
  match: 'MATCH',
  notMatch: 'NOT_MATCH',
  strictMatch: 'STRICT_MATCH',
  notStrictMatch: 'NOT_STRICT_MATCH',
  gt: 'GT',
  gteq: 'GTEQ',
  lt: 'LT',
  lteq: 'LTEQ',
  between: 'BETWEEN',
  in: 'IN',
  notIn: 'NOT_IN',
}).reduce((builder, [name, operator]) => {
  builder[name] = (field, value) => ({ field, operator, value });
  return builder;
}, {});
