/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ children }) => (
  <div className='box-header with-border'>{children}</div>
);

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
