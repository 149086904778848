import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, FieldArray } from 'formik';
import { ControlLabel } from 'react-bootstrap';
import { FormGroup, ErrorMessage } from '../../../../react-bootstrap-formik';
import HelpIconTooltip from '../../../atoms/HelpIconTooltip';
import DatePicker from 'react-datepicker';
import { DAYS_LIMIT_FOR_DATE_RANGE_MODE } from '../../Form';
import { today, addMonths, addDays } from '../../../../lib/DateUtils';
import strftime from 'strftime';
import { scope } from './';

const propTypes = {
  formik: PropTypes.object,
};

const defaultProps = {};

const DateRangeField = ({
  formik: {
    values: { dailyAmounts },
    setFieldValue,
  },
}) => {
  const scheduledDates = dailyAmounts.map(
    ({ scheduledDate }) => new Date(scheduledDate)
  );

  const [[startDate, endDate], setDateRange] = useState(() => {
    if (scheduledDates.length) {
      return [
        new Date(Math.min(...scheduledDates)),
        new Date(Math.max(...scheduledDates)),
      ];
    }
    return [null, null];
  });
  const maxDate =
    startDate && !endDate
      ? addDays(startDate, DAYS_LIMIT_FOR_DATE_RANGE_MODE - 1)
      : null;

  useEffect(() => {
    if (startDate && endDate) {
      const nextDailyAmounts = [];
      for (let date = startDate; date <= endDate; date = addDays(date, 1)) {
        const scheduledDate = strftime('%F', date);

        const dailyAmount = dailyAmounts.find(
          (a) => a.scheduledDate == scheduledDate
        );
        if (dailyAmount) {
          nextDailyAmounts.push({ ...dailyAmount });
        } else {
          nextDailyAmounts.push({ scheduledDate, amount: '' });
        }
      }
      setFieldValue('dailyAmounts', nextDailyAmounts);
    }
  }, [startDate, endDate]);

  return (
    <FieldArray name='dailyAmounts'>
      {({ name }) => (
        <FormGroup name={name}>
          <ControlLabel>
            {I18n.t([scope, 'dateRange.label'])}{' '}
            <HelpIconTooltip
              placement='top'
              message={I18n.t([scope, 'dateRange.tooltip'], {
                daysLimit: DAYS_LIMIT_FOR_DATE_RANGE_MODE,
              })}
            />
          </ControlLabel>
          <DatePicker
            inline
            selectsRange
            disabledKeyboardNavigation
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            maxDate={maxDate}
            onChange={setDateRange}
            monthsShown={3}
            openToDate={startDate || addMonths(today(), -1)}
            locale={I18n.locale}
          />
          <ErrorMessage name={name}>
            {(msg) =>
              !Array.isArray(msg) && (
                <ErrorMessage.Container>{msg}</ErrorMessage.Container>
              )
            }
          </ErrorMessage>
        </FormGroup>
      )}
    </FieldArray>
  );
};

DateRangeField.propTypes = propTypes;
DateRangeField.defaultProps = defaultProps;

export default connect(DateRangeField);
