/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import Box from '../atoms/Box';
import ListTable from './ListTable';

const propTypes = {
  ...ListTable.propTypes,
};

class ListBox extends React.Component {
  static propTypes = propTypes;

  render() {
    return (
      <Box>
        <Box.Body>
          <ListTable {...this.props} />
        </Box.Body>
      </Box>
    );
  }
}

export default ListBox;
