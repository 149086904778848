/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import { propTypes } from '../../DataTableBeLike.propTypes';

const Empty = ({ columns, message }) => (
  <tbody>
    <tr>
      <td className='text-center' colSpan={columns.length}>
        {message}
      </td>
    </tr>
  </tbody>
);

Empty.propTypes = {
  columns: propTypes.columns,
  message: propTypes.emptyDataMessage,
};

export default Empty;
