/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';

// @see https://github.com/tobiasahlin/SpinKit#flow
const FlowSpinner = ({ center }) => (
  <div className={`sk-flow ${center ? 'sk-center' : ''}`}>
    <div className='sk-flow-dot' />
    <div className='sk-flow-dot' />
    <div className='sk-flow-dot' />
  </div>
);

FlowSpinner.propTypes = {
  center: PropTypes.bool,
};

FlowSpinner.defaultProps = {
  center: false,
};

export { FlowSpinner };
