/* eslint @typescript-eslint/explicit-function-return-type: off */

import PropTypes from 'prop-types';
import ApplicationModel from '../ApplicationModel';

const CALC_METHODS = ['including_fee', 'excluding_fee'];

const propTypes = {
  ...ApplicationModel.propTypes,
  commissionPercentage: PropTypes.number,
  calcMethod: PropTypes.oneOf(CALC_METHODS),
  startDate: PropTypes.string,
};

class Entry extends ApplicationModel {
  static CALC_METHODS = CALC_METHODS;
  static propTypes = propTypes;

  static initialProps() {
    return {
      commissionPercentage: 0.0,
      calcMethod: CALC_METHODS[0],
      startDate: '',
    };
  }
}

export default Entry;
