import React from 'react';
import { createClient, Provider } from 'urql';

export const client = createClient({
  url: '/graphql',
  fetchOptions: () => {
    const csrfToken = document.querySelector('meta[name=csrf-token]').content;
    return {
      headers: {
        'X-CSRF-Token': csrfToken,
      },
    };
  },
});

export const withClient = (Component) => {
  const WrappedComponent = (props) => (
    <Provider value={client}>
      <Component {...props} />
    </Provider>
  );

  WrappedComponent.displayName = `withClient(${
    Component.displayName || Component.name || 'Component'
  })`;
  WrappedComponent.propTypes = Component.propTypes;
  WrappedComponent.defaultProps = Component.defaultProps;

  return WrappedComponent;
};

export { useQuery, useMutation } from 'urql';

export * from './operations';

export * as conditionBuilder from './conditionBuilder';

// レスポンスに含まれる `__typename` を再帰的に除去する
export const omitTypename = (object) => {
  if (Array.isArray(object)) {
    return object.map((obj) => omitTypename(obj));
  }

  if (object !== null && typeof object == 'object') {
    const omitted = {};
    for (const [key, value] of Object.entries(object)) {
      if (key != '__typename') {
        omitted[key] = omitTypename(value);
      }
    }
    return omitted;
  }

  return object;
};
