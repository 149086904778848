import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactTableContext } from '../../../../contexts';
import { flexRender } from '@tanstack/react-table';
import { Table as BSTable } from 'react-bootstrap';
import classNames from 'classnames';

import DraggableColumnHeader from './DraggableColumnHeader';
import { withDnDProvider } from '../../../../lib/dnd';

const propTypes = {
  responsive: BSTable.propTypes.responsive,
  tallTBodyRowHeight: PropTypes.bool,
  fetching: PropTypes.bool,
  disabled: PropTypes.bool,
};

const defaultProps = {
  responsive: BSTable.defaultProps.responsive,
  tallTBodyRowHeight: false,
  fetching: false,
  disabled: false,
};

const Table = ({ responsive, tallTBodyRowHeight, fetching, disabled }) => {
  const { getHeaderGroups, getRowModel, getAllLeafColumns } =
    useContext(ReactTableContext);

  // ライブラリに標準に提供されていない機能を拡張
  // デーブルデータの td タグのプロパティを設定できるようにする
  const getCellProps = (cell) => {
    const getCellProps = cell?.column?.columnDef?.meta?.getCellProps;
    return getCellProps ? getCellProps(cell) : {};
  };

  const tableRows = (() => {
    if (fetching) {
      return (
        <tr>
          <td className='text-center' colSpan={getAllLeafColumns().length}>
            {I18n.t('frontend.common.messages.fetching')}
          </td>
        </tr>
      );
    }

    const rows = getRowModel().rows;
    if (rows.length == 0) {
      return (
        <tr>
          <td className='text-center' colSpan={getAllLeafColumns().length}>
            {I18n.t('frontend.common.messages.emptyData')}
          </td>
        </tr>
      );
    }

    return rows.map((row, index) => (
      <tr
        key={row.id}
        className={classNames((index + 1) % 2 == 0 ? 'even' : 'odd', {
          selected: row.getIsSelected(),
        })}
      >
        {row.getVisibleCells().map((cell) => (
          <td key={cell.id} {...getCellProps(cell)}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </tr>
    ));
  })();

  return (
    <BSTable
      responsive={responsive}
      className={classNames('flexible-table', 'dataTable', {
        'tall-tbody-row-height': tallTBodyRowHeight,
      })}
    >
      <thead>
        {getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) =>
              header.isPlaceholder || header.column.columns.length ? (
                // プレースホルダや子階層のカラムを持つカラムは並び替えできない
                <th key={header.id} colSpan={header.colSpan}>
                  {!header.isPlaceholder &&
                    flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </th>
              ) : (
                // 並び替えできるのはあくまで子階層のカラムを持たないカラムのみ
                <DraggableColumnHeader
                  key={header.id}
                  header={header}
                  colSpan={header.colSpan}
                  className={classNames({
                    sorting: header.column.getCanSort(),
                    sorting_asc: header.column.getIsSorted() == 'asc',
                    sorting_desc: header.column.getIsSorted() == 'desc',
                  })}
                  onClick={
                    !disabled
                      ? header.column.getToggleSortingHandler()
                      : () => void 0
                  }
                  disabled={disabled}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </DraggableColumnHeader>
              )
            )}
          </tr>
        ))}
      </thead>
      <tbody>{tableRows}</tbody>
    </BSTable>
  );
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export default withDnDProvider(Table);
