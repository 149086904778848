/* eslint @typescript-eslint/explicit-function-return-type: off */

import PropTypes from 'prop-types';
import ApplicationModel from '../ApplicationModel';

const MATCHING_RULES = [
  'equals',
  'not_equals',
  'matches',
  'not_matches',
  'be_blank',
];

const propTypes = {
  ...ApplicationModel.propTypes,
  sourceColumnName: PropTypes.string,
  matchingRule: PropTypes.oneOf(MATCHING_RULES),
  sourceColumnValue: PropTypes.string,
};

const initialProps = () => ({
  sourceColumnName: '',
  matchingRule: MATCHING_RULES[0],
  sourceColumnValue: '',
});

class ExclusionCondition extends ApplicationModel {
  static MATCHING_RULES = MATCHING_RULES;
  static propTypes = propTypes;
  static initialProps = initialProps;

  get options() {
    switch (this.matchingRule) {
      case 'equals':
      case 'not_equals':
      case 'matches':
      case 'not_matches':
        return { sourceColumnValue: { isRequired: true } };
      default:
        return {};
    }
  }
}

export default ExclusionCondition;
