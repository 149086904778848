/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import * as BS from 'react-bootstrap';

const Table = ({ children, ...props }) => (
  <BS.Table responsive className='flexible-table' width='100%' {...props}>
    {children}
  </BS.Table>
);

Table.propTypes = BS.Table.propTypes;

export default Table;
