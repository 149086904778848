import * as Yup from 'yup';

export const stringSchema = Yup.string().trim().required();

export const numberSchema = Yup.number().required();

export const stringArraySchema = Yup.array()
  .of(stringSchema)
  .compact()
  .min(1)
  .required();

export const createValidator = (schema) => {
  return ({ value }) => {
    try {
      schema.validateSync(value);
      return { valid: true };
    } catch (err) {
      return {
        valid: false,
        reasons: err.errors,
      };
    }
  };
};

export const stringValidator = createValidator(stringSchema);
export const numberValidator = createValidator(numberSchema);
export const enumValidator = createValidator(stringArraySchema);
