import React from 'react';
import PropTypes from 'prop-types';
import { ValueEditor as Fallback } from 'react-querybuilder';
import CheckboxDropdownEditor from './CheckboxDropdownEditor';

const propTypes = {
  fieldData: PropTypes.object,
};

const defaultProps = {};

const ValueEditor = (props) => {
  const {
    fieldData: { valueEditorType },
  } = props;

  if (valueEditorType == 'checkboxdropdown') {
    return <CheckboxDropdownEditor {...props} />;
  }

  return <Fallback {...props} />;
};

ValueEditor.propTypes = propTypes;
ValueEditor.defaultProps = defaultProps;

export default ValueEditor;
