import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from '../ErrorBoundary';
import { withLoadingSuspense } from '../LoadingSuspense';
import { withClient, conditionBuilder } from '../../graphql';
const { search, filter } = conditionBuilder;
import { withCurrentProjectAccessibility } from '../../contexts';
import {
  useCurrentProjectAccessibility,
  useLocalStorage,
  useSearchParams,
} from '../../hooks';
import { Row, Col, Button } from 'react-bootstrap';
import Box from '../atoms/Box';
import {
  PeriodSelectDropdown,
  ConversionPointSelectDropdown,
  ListTable,
} from './IndexPage';
import { yesterday, beginningOfMonth, endOfMonth } from '../../lib/DateUtils';
import strftime from 'strftime';
import * as FlashMessage from '../../lib/FlashMessage';
import { registerLocale } from '../../lib/i18n';

registerLocale(I18n.locale);

const scope = 'frontend.components.PeriodBudgetReport.IndexPageContent';

const propTypes = {
  newPeriodBudgetUrl: PropTypes.string.isRequired,
};

const defaultProps = {};

const IndexPageContent = ({ newPeriodBudgetUrl }) => {
  const {
    accessibility: { project },
  } = useCurrentProjectAccessibility();

  const [searchParams, setSearchParams] = useSearchParams((url) =>
    history.replaceState(null, document.title, url)
  );

  // フラッシュメッセージの表示
  if (searchParams.notify) {
    const [level, event] = searchParams.notify.split(':', 2);
    FlashMessage.add(level, I18n.t([scope, `messages.${event}`]));
    setSearchParams(({ notify: _, ...prev }) => prev);
  }

  const [savedSelectedMonth, saveSelectedMonth] = useLocalStorage(
    '/projects/period_budget_reports/selected_month',
    strftime('%Y-%m', yesterday())
  );
  const [selectedCvData, saveSelectedCvData] = useLocalStorage(
    '/projects/period_budget_reports/selected_conversion_point_data/conversions',
    {}
  );
  const [selectedCvvData, saveSelectedCvvData] = useLocalStorage(
    '/projects/period_budget_reports/selected_conversion_point_data/conversion_value',
    {}
  );
  const [tableState, saveTableState] = useLocalStorage(
    '/projects/period_budget_reports/table_state',
    { sorting: [{ id: 'progressStatus', desc: true }] }
  );

  // 対象月だけは URL を共有しやすいように URL パラメータで指定できるようにする
  // パラメータが指定されていないときはローカルストレージから前回選択していた月を使用する
  // (パラメータ付きの直リンク URL を踏んでも、その時点ではローカルストレージのデータは更新していない)
  const [selectedMonth, setSelectedMonth] = useState(
    new Date(Date.parse(searchParams.month || savedSelectedMonth))
  );
  const startDate = strftime('%F', beginningOfMonth(selectedMonth));
  const endDate = strftime('%F', endOfMonth(selectedMonth));

  const handleMonthChange = (date) => {
    setSelectedMonth(date);

    const month = strftime('%Y-%m', date);
    saveSelectedMonth(month);
    setSearchParams((prev) => ({ ...prev, month }));
  };

  const [selectedCvIds, selectedCvvIds] = useMemo(() => {
    return [selectedCvData, selectedCvvData].map((selectedData) => {
      const selectedIds = [];
      for (const projectId in selectedData) {
        if (selectedData[projectId]) {
          selectedIds.push(...Object.keys(selectedData[projectId]));
        }
      }
      return selectedIds;
    });
  }, [selectedCvData, selectedCvvData]);

  const [setSelectedCvData, setSelectedCvvData] = useMemo(() => {
    return [saveSelectedCvData, saveSelectedCvvData].map((setData) => {
      return (selectedCvPointState) => {
        setData((prev) => {
          const next = { ...prev };

          const updating = {};
          for (const [cvPoint, checked] of selectedCvPointState) {
            updating[cvPoint.project.id] = updating[cvPoint.project.id] || {};

            if (checked) {
              updating[cvPoint.project.id][cvPoint.id] =
                cvPoint.dataBindingIndex;
            }
          }

          for (const projectId in updating) {
            if (Object.keys(updating[projectId]).length) {
              next[projectId] = updating[projectId];
            } else {
              delete next[projectId];
            }
          }

          return next;
        });
      };
    });
  }, [saveSelectedCvData, saveSelectedCvvData]);

  const [aggregateCv, aggregateCvv] = useMemo(() => {
    return [selectedCvData, selectedCvvData].map((selectedData) => {
      return (data) => {
        if (!selectedData[data.project.id]) {
          return 0;
        }

        let value = 0.0;
        for (const [_, dataBindingIndex] of Object.entries(
          selectedData[data.project.id]
        )) {
          const metric = `conversions${`0${dataBindingIndex}`.slice(-2)}`;
          value += data[metric] || 0.0;
        }
        return value;
      };
    });
  }, [selectedCvData, selectedCvvData]);

  const [handleCvPointFetch, handleCvvPointFetch] = useMemo(() => {
    return ['conversions', 'conversion_value'].map((valueType) => {
      return async (query, variables) => {
        const searchConds = search.and([
          ...(variables.search ? [variables.search] : []),
          ...(project ? [filter.eq('projectId', project.id)] : []),
          filter.eq('valueType', valueType),
        ]);

        return await query({ ...variables, search: searchConds });
      };
    });
  }, [project]);

  const handleTableStateChange = (state) => {
    const pagination = { ...state.pagination };
    pagination.pageIndex = 0; // 表示中のページ数は保存しない

    saveTableState({ ...state, pagination });
  };

  const [fetching, setFetching] = useState(false);

  const handleTableDataFetch = useCallback(
    async (query, variables) => {
      setFetching(true);
      try {
        const searchConds = search.and([
          variables.search,
          ...(project ? [filter.eq('projectId', project.id)] : []),
          search.or([
            filter.between('startDate', [startDate, endDate]),
            filter.between('endDate', [startDate, endDate]),
            search.and([
              filter.lteq('startDate', startDate),
              filter.gteq('endDate', startDate),
            ]),
          ]),
        ]);

        const order = [...variables.order, 'id']; // 最後に id 昇順で並び替え

        return await query({ ...variables, search: searchConds, order });
      } finally {
        setFetching(false);
      }
    },
    [project, startDate, endDate]
  );

  return (
    <>
      <Row className='actions-container'>
        <Col md={4} className='left-container'>
          <PeriodSelectDropdown
            selected={selectedMonth}
            onChange={handleMonthChange}
            disabled={fetching}
          />
        </Col>
        <Col md={8} className='right-container'>
          <ConversionPointSelectDropdown
            pullRight
            label={I18n.t([scope, 'action.selectCvPoints'])}
            selected={selectedCvIds}
            onChange={setSelectedCvData}
            onFetch={handleCvPointFetch}
            disabled={fetching}
          />
          <ConversionPointSelectDropdown
            pullRight
            label={I18n.t([scope, 'action.selectCvvPoints'])}
            selected={selectedCvvIds}
            onChange={setSelectedCvvData}
            onFetch={handleCvvPointFetch}
            disabled={fetching}
          />
          <Button
            bsStyle='primary'
            onClick={() => (location.href = newPeriodBudgetUrl)}
          >
            {I18n.t([scope, 'action.new'])}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Box>
            <Box.Body>
              <ListTable
                responsive
                initialState={tableState}
                onStateChange={handleTableStateChange}
                onFetch={handleTableDataFetch}
                aggregateConversions={aggregateCv}
                aggregateConversionValue={aggregateCvv}
              />
            </Box.Body>
          </Box>
        </Col>
      </Row>
    </>
  );
};

IndexPageContent.propTypes = propTypes;
IndexPageContent.defaultProps = defaultProps;

export default withErrorBoundary(
  withLoadingSuspense(
    withClient(withCurrentProjectAccessibility(IndexPageContent))
  )
);
