/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import AdPerformanceReportDataSource from '../../../models/AdPerformanceReportDataSource';
import AttributeFieldForm from './AttributeFieldsForm/AttributeFieldForm';

const scope =
  'frontend.components.AdPerformanceReportDataSource.Form.AttributeFieldsForm';

const propTypes = {
  dataSource: PropTypes.exact(AdPerformanceReportDataSource.propTypes),
  onChange: PropTypes.func,
};

class AttributeFieldsForm extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.handleAdd = this.handleAdd.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  handleAdd(event) {
    event.preventDefault();
    const { dataSource, onChange } = this.props;
    const attributeFields = dataSource.attributeFields.slice();
    attributeFields.push(dataSource.buildAttributeField());
    onChange({ ...dataSource, attributeFields });
  }

  handleChange(index) {
    return (field, event) => {
      event.preventDefault();
      const { dataSource, onChange } = this.props;
      const attributeFields = dataSource.attributeFields.slice();
      attributeFields[index] = field;
      onChange({ ...dataSource, attributeFields });
    };
  }

  handleRemove(index) {
    return (event) => {
      event.preventDefault();
      const { dataSource, onChange } = this.props;
      const attributeFields = dataSource.attributeFields.slice();
      attributeFields.splice(index, 1);
      onChange({ ...dataSource, attributeFields });
    };
  }

  render() {
    const { dataSource } = this.props;
    const {
      attributeFields,
      minAttributeFieldCount: min,
      maxAttributeFieldCount: max,
    } = dataSource;

    return (
      <>
        <BS.Table responsive striped bordered hover className='form-table'>
          <thead>
            <tr>
              <th className='fixed-xlarge' colSpan='3'>
                {I18n.t(`${scope}.table.heading.source`)}
              </th>
              <th className='fixed-xlarge' colSpan='3'>
                {I18n.t(`${scope}.table.heading.target`)}
              </th>
              <th className='fixed-xlarge'>
                {I18n.t(`${scope}.table.heading.matching`)}
              </th>
              <th className='fixed-min' />
            </tr>
          </thead>
          <tbody>
            {attributeFields.map((field, index) => (
              <AttributeFieldForm
                key={index}
                dataSource={dataSource}
                field={field}
                onChange={this.handleChange(index)}
                onRemove={min <= index && this.handleRemove(index)}
              />
            ))}
          </tbody>
        </BS.Table>
        {attributeFields.length < max && (
          <a className='pull-right' href='' onClick={this.handleAdd}>
            <span
              dangerouslySetInnerHTML={{
                __html: I18n.t(`${scope}.action.add`),
              }}
            ></span>
          </a>
        )}
      </>
    );
  }
}

export default AttributeFieldsForm;
