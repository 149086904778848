import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ReactTableContext } from '../../../../contexts';
import { QueryBuilder } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.scss';
import {
  conditionsToQuery,
  queryToConditions,
  getConditionsCount,
  searchFieldBuilder,
} from './utils';
import combinators from './combinators';
import ValueEditor from './ValueEditor';
import { getHierarchicalColumnName } from '../helpers';
import { Dropdown, Modal, Button } from 'react-bootstrap';

const scope =
  'frontend.components.molecules.ReactTableDataTableStyled.SearchBuilder';

const propTypes = {
  conditions: PropTypes.object,
  onChange: PropTypes.func,
};

const defaultProps = {};

const SearchBuilder = ({ conditions, onChange }) => {
  const conditionsCount = getConditionsCount(conditions);

  const { getAllLeafColumns } = useContext(ReactTableContext);

  const fields = getAllLeafColumns().reduce((fields, column) => {
    if (column.getCanFilter()) {
      const {
        meta: { field, search },
      } = column.columnDef;

      fields.push({
        name: field,
        label: getHierarchicalColumnName(column),
        ...(search || {}),
      });
    }
    return fields;
  }, []);

  const [show, setShow] = useState(false);
  const [query, setQuery] = useState(conditionsToQuery(conditions, fields));

  const isValid = useMemo(() => {
    const validate = (query, fields) => {
      if ('rules' in query) {
        return query.rules.every((rule) => validate(rule, fields));
      }

      const { field, value } = query;
      const { validator } = fields.find(({ name }) => name == field);

      return validator ? validator({ value }).valid : true;
    };

    return validate(query, fields);
  }, [query]);

  const handleApply = () => {
    onChange(queryToConditions(query, fields));
    setShow(false);
  };

  const translations = I18n.t('frontend.react-querybuilder.translations');

  return (
    <>
      <Dropdown.Toggle onClick={() => setShow(true)}>
        <i className='fa fa-search' />{' '}
        {conditionsCount
          ? I18n.t([scope, 'Toggle.label/withCount'], {
              count: conditionsCount,
            })
          : I18n.t([scope, 'Toggle.label'])}
      </Dropdown.Toggle>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          {I18n.t([scope, 'Modal.title'])}
        </Modal.Header>
        <Modal.Body>
          <QueryBuilder
            {...{ fields, query, combinators, translations }}
            onQueryChange={setQuery}
            controlElements={{ valueEditor: ValueEditor }}
            controlClassnames={{
              // NOTE:
              //    公式に @react-querybuilder/bootstrap が用意されているが、
              //    Roboma で使用している Bootstrap のバージョンが古くて互換性がない...
              //    なので、Roboma で使用している v3 互換のクラス名を指定している
              addGroup: 'btn btn-secondary btn-sm',
              addRule: 'btn btn-primary btn-sm',
              cloneGroup: 'btn btn-secondary btn-sm',
              cloneRule: 'btn btn-secondary btn-sm',
              lockGroup: 'btn btn-secondary btn-sm',
              lockRule: 'btn btn-secondary btn-sm',
              removeGroup: 'btn btn-danger btn-sm',
              removeRule: 'btn btn-danger btn-sm',
              combinators: 'form-control input-sm',
              fields: 'form-control input-sm',
              operators: 'form-control input-sm',
              value: 'form-control input-sm',
              valueSource: 'form-control input-sm',
            }}
            enableDragAndDrop
          />
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle='primary' onClick={handleApply} disabled={!isValid}>
            {I18n.t([scope, 'Modal.action.apply'])}
          </Button>
          <Button onClick={() => setQuery(conditionsToQuery())}>
            {I18n.t([scope, 'Modal.action.clear'])}
          </Button>
          <Button onClick={() => setShow(false)}>
            {I18n.t([scope, 'Modal.action.close'])}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

SearchBuilder.propTypes = propTypes;
SearchBuilder.defaultProps = defaultProps;

export default SearchBuilder;
export { searchFieldBuilder };
