/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import * as BSEx from '../../../BootstrapExtends';
import AdPerformanceReportDataSource, {
  AttributeField,
} from '../../../../models/AdPerformanceReportDataSource';

const { SOURCE_EFFECTIVE_PARTS, MATCHING_RULES } = AttributeField;

const scope =
  'frontend.components.AdPerformanceReportDataSource.Form.AttributeFieldsForm';

const propTypes = {
  dataSource: PropTypes.exact(AdPerformanceReportDataSource.propTypes),
  field: PropTypes.exact(AttributeField.propTypes),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

class AttributeFieldForm extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleTargetTableField = this.handleTargetTableField.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { field, onChange } = this.props;
    onChange({ ...field, [name]: value }, event);
  }

  handleTargetTableField(event) {
    const { value: targetTableField } = event.target;
    const { field: current, onChange } = this.props;

    const field = new AttributeField({ ...current, targetTableField });

    const { availableTargetEffectiveParts } = field;
    const targetEffectivePart = availableTargetEffectiveParts.includes(
      field.targetEffectivePart
    )
      ? field.targetEffectivePart
      : availableTargetEffectiveParts[0];
    onChange({ ...field, targetEffectivePart }, event);
  }

  render() {
    const { dataSource, field, onRemove } = this.props;
    const { availableTargetEffectiveParts, sourceOptions, targetOptions } =
      field;

    return (
      <tr>
        <td>
          <BSEx.FormGroup model={field} attribute='sourceColumnName'>
            <BS.FormControl
              type='text'
              placeholder={I18n.t(
                `${scope}.field.sourceColumnName.placeholder`
              )}
              name='sourceColumnName'
              value={field.sourceColumnName}
              onChange={this.handleChange}
            />
          </BSEx.FormGroup>
        </td>
        <td>
          <BSEx.FormGroup model={field} attribute='sourceEffectivePart'>
            <BS.FormControl
              componentClass='select'
              name='sourceEffectivePart'
              value={field.sourceEffectivePart}
              onChange={this.handleChange}
            >
              {SOURCE_EFFECTIVE_PARTS.map((effectivePart, index) => (
                <option key={index} value={effectivePart}>
                  {I18n.t(
                    `${scope}.field.sourceEffectivePart.selection.${effectivePart}`
                  )}
                </option>
              ))}
            </BS.FormControl>
          </BSEx.FormGroup>
        </td>
        <td>
          {'sourceUrlParamName' in sourceOptions && (
            <BSEx.FormGroup model={field} attribute='sourceUrlParamName'>
              <BS.FormControl
                type='text'
                placeholder={I18n.t(
                  `${scope}.field.sourceUrlParamName.placeholder`
                )}
                name='sourceUrlParamName'
                value={field.sourceUrlParamName}
                onChange={this.handleChange}
              />
            </BSEx.FormGroup>
          )}
        </td>

        <td>
          <BSEx.FormGroup model={field} attribute='targetTableField'>
            <BS.FormControl
              componentClass='select'
              name='targetTableField'
              value={field.targetTableField}
              onChange={this.handleTargetTableField}
            >
              {dataSource.availableAttributeFieldTargetTableFields.map(
                (tableField, index) => (
                  <option key={index} value={tableField}>
                    {I18n.t(
                      `${scope}.field.targetTableField.selection.${tableField}`
                    )}
                  </option>
                )
              )}
            </BS.FormControl>
          </BSEx.FormGroup>
        </td>
        <td>
          {availableTargetEffectiveParts.length > 1 && (
            <BSEx.FormGroup model={field} attribute='targetEffectivePart'>
              <BS.FormControl
                componentClass='select'
                name='targetEffectivePart'
                value={field.targetEffectivePart}
                onChange={this.handleChange}
              >
                {availableTargetEffectiveParts.map((effectivePart, index) => (
                  <option key={index} value={effectivePart}>
                    {I18n.t(
                      `${scope}.field.targetEffectivePart.selection.${effectivePart}`
                    )}
                  </option>
                ))}
              </BS.FormControl>
            </BSEx.FormGroup>
          )}
        </td>
        <td>
          {'targetUrlParamName' in targetOptions && (
            <BSEx.FormGroup model={field} attribute='targetUrlParamName'>
              <BS.FormControl
                type='text'
                placeholder={I18n.t(
                  `${scope}.field.targetUrlParamName.placeholder`
                )}
                name='targetUrlParamName'
                value={field.targetUrlParamName}
                onChange={this.handleChange}
              />
            </BSEx.FormGroup>
          )}
        </td>

        <td>
          <BSEx.FormGroup model={field} attribute='matchingRule'>
            <BS.FormControl
              componentClass='select'
              name='matchingRule'
              value={field.matchingRule}
              onChange={this.handleChange}
            >
              {MATCHING_RULES.map((rule, index) => (
                <option key={index} value={rule}>
                  {I18n.t(`${scope}.field.matchingRule.selection.${rule}`)}
                </option>
              ))}
            </BS.FormControl>
          </BSEx.FormGroup>
        </td>

        <td>
          {onRemove && (
            <BS.Button bsStyle='danger' onClick={onRemove}>
              <i className='fa fa-trash' />
            </BS.Button>
          )}
        </td>
      </tr>
    );
  }
}

export default AttributeFieldForm;
