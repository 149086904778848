/* eslint @typescript-eslint/explicit-function-return-type: off */

import PropTypes from 'prop-types';
import ReportRow from './ReportRow';
import Matcher from './ReportRowFilter/Matcher';

const propTypes = {
  matchers: PropTypes.arrayOf(PropTypes.exact(Matcher.propTypes)),
};

class ReportRowFilter {
  static Matcher = Matcher;

  static propTypes = propTypes;

  #groupedMatchers;

  constructor({ matchers }) {
    this.#groupedMatchers = matchers.reduce((group, matcher) => {
      const { field } = matcher;
      group[field] = group[field] || [];
      group[field].push(new Matcher(matcher));
      return group;
    }, {});
  }

  filter = (rows) => {
    if (!rows) return null;

    return rows.reduce((list, row) => {
      const children = this.filter(row.children);

      if (children?.length || this.#match(row)) {
        list.push(new ReportRow({ ...row, children }));
      }

      return list;
    }, []);
  };

  #match = (row) => {
    return Object.values(this.#groupedMatchers).every((matchers) => {
      return matchers.some((matcher) => matcher.match(row));
    });
  };
}

export default ReportRowFilter;
