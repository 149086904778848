import { useState } from 'react';
import { parse, stringify } from 'query-string';

export default (callback = () => void 0) => {
  const [storedValue, setStoredValue] = useState(
    parse(location.search, { arrayFormat: 'bracket' })
  );

  const setSearchParams = (value) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);

    const url = new URL(location.href);
    url.search = stringify(valueToStore, { arrayFormat: 'bracket' });
    callback(url.toString());
  };

  return [storedValue, setSearchParams];
};
