/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Well,
  Button,
  FormGroup,
  ControlLabel,
  Checkbox,
} from 'react-bootstrap';

const scope = 'frontend.components.BudgetReport.CopyBudgetsModal';

const propTypes = {
  show: PropTypes.bool,
  submitting: PropTypes.bool,
  onSubmit: PropTypes.func,
  onHide: PropTypes.func,
};

class CopyBudgetsModal extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.state = {
      overwrite: false,
    };
  }

  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked });
  };

  handleHide = () => {
    const { submitting, onHide } = this.props;
    if (submitting) return;
    onHide();
  };

  handleSubmit = () => {
    const { overwrite } = this.state;
    this.props.onSubmit({ overwrite });
  };

  render = () => {
    const { show, submitting } = this.props;
    const { overwrite } = this.state;

    return (
      <Modal show={show} onHide={this.handleHide}>
        <Modal.Header>
          <Modal.Title>{I18n.t(`${scope}.title`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Well
            dangerouslySetInnerHTML={{ __html: I18n.t(`${scope}.description`) }}
          />
          <FormGroup>
            <ControlLabel>
              {I18n.t(`${scope}.field.overwrite.label`)}
            </ControlLabel>
            <Checkbox
              name='overwrite'
              checked={overwrite}
              onChange={this.handleCheckboxChange}
              disabled={submitting}
            >
              {I18n.t(`${scope}.field.overwrite.placeholder`)}
            </Checkbox>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleHide} disabled={submitting}>
            {I18n.t(`${scope}.action.cancel`)}
          </Button>
          <Button
            bsStyle='primary'
            onClick={this.handleSubmit}
            disabled={submitting}
          >
            {I18n.t(`${scope}.action.${submitting ? 'submitting' : 'submit'}`)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
}

export default CopyBudgetsModal;
