import { client } from '../';

const PeriodBudgetQuery = `
  query($id: ID!) {
    periodBudget(id: $id) {
      id, projectId, name, dateSelectionMode
      dailyAmounts { scheduledDate, amount }
      resources { resourceType, resourceId, channelId, adAccountId, adCampaignId, adGroupId, adId }
    }
  }
`;

const CreatePeriodBudgetMutation = `
  mutation($input: CreatePeriodBudgetInput!) {
    createPeriodBudget(input: $input) {
      periodBudget {
        id, projectId, name, dateSelectionMode
        dailyAmounts { scheduledDate, amount }
        resources { resourceType, resourceId, channelId, adAccountId, adCampaignId, adGroupId, adId }
      }
      errors { field, messages }
    }
  }
`;

const UpdatePeriodBudgetMutation = `
  mutation($input: UpdatePeriodBudgetInput!) {
    updatePeriodBudget(input: $input) {
      periodBudget {
        id, projectId, name, dateSelectionMode
        dailyAmounts { scheduledDate, amount }
        resources { resourceType, resourceId, channelId, adAccountId, adCampaignId, adGroupId, adId }
      }
      errors { field, messages }
    }
  }
`;

const DeletePeriodBudgetMutation = `
  mutation($input: DeletePeriodBudgetInput!) {
    deletePeriodBudget(input: $input) {
      periodBudget { id }
      errors { field, messages }
    }
  }
`;

export const queryPeriodBudget = async (id, operationContext = {}) => {
  const { data, error } = await client
    .query(PeriodBudgetQuery, { id }, operationContext)
    .toPromise();
  if (error) {
    throw new Error(error);
  }

  return data.periodBudget;
};

export const mutationCreatePeriodBudget = async (
  createPeriodBudgetInput,
  operationContext = {}
) => {
  const { data, error } = await client
    .mutation(
      CreatePeriodBudgetMutation,
      { input: createPeriodBudgetInput },
      operationContext
    )
    .toPromise();
  if (error) {
    throw new Error(error);
  }

  return data.createPeriodBudget;
};

export const mutationUpdatePeriodBudget = async (
  updatePeriodBudgetInput,
  operationContext = {}
) => {
  const { data, error } = await client
    .mutation(
      UpdatePeriodBudgetMutation,
      { input: updatePeriodBudgetInput },
      operationContext
    )
    .toPromise();
  if (error) {
    throw new Error(error);
  }

  return data.updatePeriodBudget;
};

export const mutationDeletePeriodBudget = async (
  deletePeriodBudgetInput,
  operationContext = {}
) => {
  const { data, error } = await client
    .mutation(
      DeletePeriodBudgetMutation,
      { input: deletePeriodBudgetInput },
      operationContext
    )
    .toPromise();
  if (error) {
    throw new Error(error);
  }

  return data.deletePeriodBudget;
};
