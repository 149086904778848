/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import * as BSEx from '../BootstrapExtends';
import CommissionConfig from '../../models/CommissionConfig';
import EntriesForm from './Form/EntriesForm';
import DescriptionPanel from '../molecules/DescriptionPanel';

const scope = 'frontend.components.CommissionConfig.Form';

const propTypes = {
  commissionConfig: PropTypes.exact(CommissionConfig.propTypes),
  onChange: PropTypes.func,
};

class Form extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);
  }

  render() {
    const { commissionConfig, onChange } = this.props;
    const { associatedResourceType } = commissionConfig;

    return (
      <BS.Form>
        <BSEx.FormGroup
          label={I18n.t(
            `${scope}.field.associatedResource/${associatedResourceType}.label`
          )}
        >
          <BS.FormControl.Static>
            {commissionConfig.associatedResourceName}
          </BS.FormControl.Static>
        </BSEx.FormGroup>

        <BSEx.FormGroup
          label={I18n.t(`${scope}.field.entries.label`)}
          model={commissionConfig}
          attribute='entries'
        >
          <EntriesForm
            commissionConfig={commissionConfig}
            onChange={onChange}
          />
        </BSEx.FormGroup>
        <DescriptionPanel
          title={I18n.t(`${scope}.disclaimer.title`)}
          innerHTML={I18n.t(`${scope}.disclaimer.content`)}
        />
      </BS.Form>
    );
  }
}

export default Form;
