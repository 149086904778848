import React from 'react';
import PropTypes from 'prop-types';
import PleaseTryAgainLater from './PleaseTryAgainLater';

const propTypes = {
  fallback: PropTypes.element,
  children: PropTypes.element,
};

const defaultProps = {
  fallback: <PleaseTryAgainLater />,
};

class ErrorBoundary extends React.Component {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    // 次のレンダリングでフォールバックコンポーネントが表示されるように状態を更新する
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Catch error by ErrorBoundary:', error, errorInfo);
  }

  render() {
    const { fallback, children } = this.props;
    const { error } = this.state;

    return error ? fallback : children;
  }
}

export default ErrorBoundary;
