import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactTableContext } from '../../../contexts';
import { Dropdown } from 'react-bootstrap';
import { getHierarchicalColumnName } from './helpers';

const propTypes = {
  disabled: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
};

const ColumnVisibility = ({ disabled }) => {
  const { getAllLeafColumns } = useContext(ReactTableContext);

  const [open, setOpen] = useState(false);

  const handleToggle = (open, _ev, { source }) => {
    // メニューアイテムを選択したときは閉じない
    // これをしないとチェックボックスをクリックしたときにドロップダウンが閉じてしまう
    if (source == 'select') return;
    setOpen(open);
  };

  return (
    <Dropdown
      id='colVis'
      {...{ open, disabled }}
      onToggle={handleToggle}
      pullRight
    >
      <Dropdown.Toggle>
        <i className='fa fa-columns' /> {'表示項目'}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {getAllLeafColumns().reduce((selections, column) => {
          if (column.getCanHide()) {
            selections.push(
              <li key={column.id} role='presentation' className='checkbox'>
                <label className='label-dropdown-report'>
                  <input
                    type='checkbox'
                    checked={column.getIsVisible()}
                    onChange={column.getToggleVisibilityHandler()}
                    disabled={disabled}
                  />
                  {getHierarchicalColumnName(column)}
                </label>
              </li>
            );
          }

          return selections;
        }, [])}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ColumnVisibility.propTypes = propTypes;
ColumnVisibility.defaultProps = defaultProps;

export default ColumnVisibility;
