/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import * as BSEx from '../../../BootstrapExtends';
import { MetricField } from '../../../../models/AdPerformanceReportDataSource';

const scope =
  'frontend.components.AdPerformanceReportDataSource.Form.MetricFieldsForm';

const propTypes = {
  field: PropTypes.exact(MetricField.propTypes),
  shown: PropTypes.bool,
  onChange: PropTypes.func,
  onHide: PropTypes.func,
};
class AdvancedSourceOptionsFormModal extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.state = this.initialState(props.field);
  }

  initialState({
    sourceDelimiter,
    sourceSeparator,
    sourceColumnValue,
    sourceCounterValue,
  }) {
    return {
      sourceDelimiter: sourceDelimiter || '',
      sourceSeparator: sourceSeparator || '',
      sourceColumnValue: sourceColumnValue || '',
      sourceCounterValue: sourceCounterValue || '1',
    };
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleApply(event) {
    const { field, onChange, onHide } = this.props;
    onChange({ ...field, ...this.state }, event);
    onHide(event);
  }

  handleCancel(event) {
    const { field, onHide } = this.props;
    this.setState(this.initialState(field));
    onHide(event);
  }

  render() {
    const { field, shown } = this.props;
    const {
      sourceDelimiter,
      sourceSeparator,
      sourceColumnValue,
      sourceCounterValue,
    } = this.state;
    const { sourceOptions } = field;

    return (
      <BS.Modal show={shown} onHide={this.handleCancel}>
        <BS.Modal.Header closeButton>
          <BS.Modal.Title>
            {I18n.t(`${scope}.action.configureAdvancedOptions`)}
          </BS.Modal.Title>
        </BS.Modal.Header>
        <BS.Modal.Body>
          {'sourceDelimiter' in sourceOptions && (
            <BSEx.FormGroup
              label={I18n.t(`${scope}.field.sourceDelimiter.label`)}
              model={field}
              attribute='sourceDelimiter'
            >
              <BS.FormControl
                type='text'
                placeholder={I18n.t(
                  `${scope}.field.sourceDelimiter.placeholder`
                )}
                name='sourceDelimiter'
                value={sourceDelimiter}
                onChange={this.handleChange}
              />
            </BSEx.FormGroup>
          )}
          {'sourceSeparator' in sourceOptions && (
            <BSEx.FormGroup
              label={I18n.t(`${scope}.field.sourceSeparator.label`)}
              model={field}
              attribute='sourceSeparator'
            >
              <BS.FormControl
                type='text'
                placeholder={I18n.t(
                  `${scope}.field.sourceSeparator.placeholder`
                )}
                name='sourceSeparator'
                value={sourceSeparator}
                onChange={this.handleChange}
              />
            </BSEx.FormGroup>
          )}
          {'sourceColumnValue' in sourceOptions && (
            <BSEx.FormGroup
              label={I18n.t(`${scope}.field.sourceColumnValue.label`)}
              model={field}
              attribute='sourceColumnValue'
            >
              <BS.FormControl
                type='text'
                placeholder={I18n.t(
                  `${scope}.field.sourceColumnValue.placeholder`
                )}
                name='sourceColumnValue'
                value={sourceColumnValue}
                onChange={this.handleChange}
              />
              <BS.FormControl.Static>
                {I18n.t(`${scope}.field.sourceColumnValue.description`)}
              </BS.FormControl.Static>
            </BSEx.FormGroup>
          )}
          {'sourceCounterValue' in sourceOptions && (
            <BSEx.FormGroup
              label={I18n.t(`${scope}.field.sourceCounterValue.label`)}
              model={field}
              attribute='sourceCounterValue'
            >
              <BS.FormControl
                type='text'
                placeholder={I18n.t(
                  `${scope}.field.sourceCounterValue.placeholder`
                )}
                name='sourceCounterValue'
                value={sourceCounterValue}
                onChange={this.handleChange}
              />
            </BSEx.FormGroup>
          )}
        </BS.Modal.Body>
        <BS.Modal.Footer>
          <BS.Button onClick={this.handleCancel}>
            {I18n.t(`${scope}.action.cancelAdvancedOptions`)}
          </BS.Button>
          <BS.Button bsStyle='primary' onClick={this.handleApply}>
            {I18n.t(`${scope}.action.applyAdvancedOptions`)}
          </BS.Button>
        </BS.Modal.Footer>
      </BS.Modal>
    );
  }
}

export default AdvancedSourceOptionsFormModal;
