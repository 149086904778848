import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { searchFieldBuilder } from './SearchBuilder';
import { MediaThumbnail } from './Table';

const scope = `frontend.components.molecules.ReactTableDataTableStyled.helpers`;

// 階層化したカラム名 (ルート階層〜指定カラムまでのカラム名をすべて連結したカラム名) を取得
export const getHierarchicalColumnName = (column, childColumns = []) => {
  const columns = [column, ...childColumns];
  if (column.parent) {
    return getHierarchicalColumnName(column.parent, columns);
  }

  return columns
    .reduce((names, { columnDef: { header } }) => {
      if (header) {
        names.push(header);
      }
      return names;
    }, [])
    .join(' - ');
};

export const createChannelColumnDefs = ({ accessorPrefix: pre } = {}) => {
  const columnHelper = createColumnHelper();
  return [
    columnHelper.accessor(`${pre ? `${pre}.` : ''}name`, {
      id: `${pre ? `${pre}.` : ''}name`,
      header: I18n.t([scope, 'columnDefs.channel.name.header']),

      // NOTE: channels テーブルとの関連付けをしないうちはソート・検索には未対応
      // @see https://github.com/robomarketer/senri/issues/4906
      enableSorting: false,
      enableColumnFilter: false,
    }),
  ];
};

export const createAdAccountColumnDefs = ({ accessorPrefix: pre } = {}) => {
  const columnHelper = createColumnHelper();
  return [
    columnHelper.accessor(`${pre ? `${pre}.` : ''}name`, {
      id: `${pre ? `${pre}.` : ''}name`,
      header: I18n.t([scope, 'columnDefs.adAccount.name.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}name`,
        search: searchFieldBuilder.string(),
      },
    }),
    columnHelper.accessor(`${pre ? `${pre}.` : ''}remoteId`, {
      id: `${pre ? `${pre}.` : ''}remoteId`,
      header: I18n.t([scope, 'columnDefs.adAccount.remoteId.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}remoteId`,
        search: searchFieldBuilder.string(),
      },
    }),
    columnHelper.accessor(`${pre ? `${pre}.` : ''}remoteStatus`, {
      id: `${pre ? `${pre}.` : ''}remoteStatus`,
      header: I18n.t([scope, 'columnDefs.adAccount.remoteStatus.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}remoteStatus`,
        search: searchFieldBuilder.string(),
      },
    }),
  ];
};

export const createAdCampaignColumnDefs = ({ accessorPrefix: pre } = {}) => {
  const columnHelper = createColumnHelper();
  return [
    columnHelper.accessor(`${pre ? `${pre}.` : ''}name`, {
      id: `${pre ? `${pre}.` : ''}name`,
      header: I18n.t([scope, 'columnDefs.adCampaign.name.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}name`,
        search: searchFieldBuilder.string(),
      },
    }),
    columnHelper.accessor(`${pre ? `${pre}.` : ''}remoteId`, {
      id: `${pre ? `${pre}.` : ''}remoteId`,
      header: I18n.t([scope, 'columnDefs.adCampaign.remoteId.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}remoteId`,
        search: searchFieldBuilder.string(),
      },
    }),
    columnHelper.accessor(`${pre ? `${pre}.` : ''}remoteStatus`, {
      id: `${pre ? `${pre}.` : ''}remoteStatus`,
      header: I18n.t([scope, 'columnDefs.adCampaign.remoteStatus.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}remoteStatus`,
        search: searchFieldBuilder.string(),
      },
    }),
    columnHelper.accessor(`${pre ? `${pre}.` : ''}objectiveName`, {
      id: `${pre ? `${pre}.` : ''}objectiveName`,
      header: I18n.t([scope, 'columnDefs.adCampaign.objectiveName.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}objectiveName`,
        search: searchFieldBuilder.string(),
      },
    }),
  ];
};

export const createAdGroupColumnDefs = ({ accessorPrefix: pre } = {}) => {
  const columnHelper = createColumnHelper();
  return [
    columnHelper.accessor(`${pre ? `${pre}.` : ''}name`, {
      id: `${pre ? `${pre}.` : ''}name`,
      header: I18n.t([scope, 'columnDefs.adGroup.name.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}name`,
        search: searchFieldBuilder.string(),
      },
    }),
    columnHelper.accessor(`${pre ? `${pre}.` : ''}remoteId`, {
      id: `${pre ? `${pre}.` : ''}remoteId`,
      header: I18n.t([scope, 'columnDefs.adGroup.remoteId.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}remoteId`,
        search: searchFieldBuilder.string(),
      },
    }),
    columnHelper.accessor(`${pre ? `${pre}.` : ''}remoteStatus`, {
      id: `${pre ? `${pre}.` : ''}remoteStatus`,
      header: I18n.t([scope, 'columnDefs.adGroup.remoteStatus.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}remoteStatus`,
        search: searchFieldBuilder.string(),
      },
    }),
  ];
};

export const createAdColumnDefs = ({ accessorPrefix: pre, onPreview } = {}) => {
  const columnHelper = createColumnHelper();
  return [
    columnHelper.accessor(`${pre ? `${pre}.` : ''}name`, {
      id: `${pre ? `${pre}.` : ''}name`,
      header: I18n.t([scope, 'columnDefs.ad.name.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}name`,
        search: searchFieldBuilder.string(),
      },
    }),
    columnHelper.accessor(`${pre ? `${pre}.` : ''}remoteId`, {
      id: `${pre ? `${pre}.` : ''}remoteId`,
      header: I18n.t([scope, 'columnDefs.ad.remoteId.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}remoteId`,
        search: searchFieldBuilder.string(),
      },
    }),
    columnHelper.accessor(`${pre ? `${pre}.` : ''}remoteStatus`, {
      id: `${pre ? `${pre}.` : ''}remoteStatus`,
      header: I18n.t([scope, 'columnDefs.ad.remoteStatus.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}remoteStatus`,
        search: searchFieldBuilder.string(),
      },
    }),
    columnHelper.accessor(`${pre ? `${pre}.` : ''}creativeType`, {
      id: `${pre ? `${pre}.` : ''}creativeType`,
      header: I18n.t([scope, 'columnDefs.ad.creativeType.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}creativeType`,
        search: searchFieldBuilder.string(),
      },
    }),
    columnHelper.display({
      id: `${pre ? `${pre}.` : ''}media`,
      header: I18n.t([scope, 'columnDefs.ad.media.header']),
      cell: ({ row: { original: ad } }) => {
        const { thumbnailUrl, imageUrl, videoUrl } = ad;
        return (
          <MediaThumbnail
            {...{
              thumbnailUrl,
              imageUrl,
              videoUrl,
              onClick: onPreview,
            }}
          />
        );
      },
    }),
    columnHelper.accessor(`${pre ? `${pre}.` : ''}leadText`, {
      id: `${pre ? `${pre}.` : ''}leadText`,
      header: I18n.t([scope, 'columnDefs.ad.leadText.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}leadText`,
        search: searchFieldBuilder.string(),
      },
    }),
    columnHelper.accessor(`${pre ? `${pre}.` : ''}subText`, {
      id: `${pre ? `${pre}.` : ''}subText`,
      header: I18n.t([scope, 'columnDefs.ad.subText.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}subText`,
        search: searchFieldBuilder.string(),
      },
    }),
    columnHelper.accessor(`${pre ? `${pre}.` : ''}subText2`, {
      id: `${pre ? `${pre}.` : ''}subText2`,
      header: I18n.t([scope, 'columnDefs.ad.subText2.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}subText2`,
        search: searchFieldBuilder.string(),
      },
    }),
    columnHelper.accessor(`${pre ? `${pre}.` : ''}cta`, {
      id: `${pre ? `${pre}.` : ''}cta`,
      header: I18n.t([scope, 'columnDefs.ad.cta.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}cta`,
        search: searchFieldBuilder.string(),
      },
    }),
    columnHelper.accessor(`${pre ? `${pre}.` : ''}ctaLinkUrl`, {
      id: `${pre ? `${pre}.` : ''}ctaLinkUrl`,
      header: I18n.t([scope, 'columnDefs.ad.ctaLinkUrl.header']),
      meta: {
        field: `${pre ? `${pre}.` : ''}ctaLinkUrl`,
        search: searchFieldBuilder.string(),
      },
    }),
  ];
};
