/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import * as BS from 'react-bootstrap';
import Header from './Modal/Header';
import Body from './Modal/Body';
import Footer from './Modal/Footer';
import withLauncher from './Modal/withLauncher';

const Modal = ({ children, ...props }) => (
  <BS.Modal {...props}>{children}</BS.Modal>
);

Modal.propTypes = BS.Modal.propTypes;
Modal.defaultProps = BS.Modal.defaultProps;

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;

export { withLauncher };
