/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import * as BSEx from '../../../BootstrapExtends';
import DateSelect from '../../../molecules/DateSelect';
import CommissionConfig, { Entry } from '../../../../models/CommissionConfig';

const scope = 'frontend.components.CommissionConfig.Form.EntriesForm';

const propTypes = {
  commissionConfig: PropTypes.exact(CommissionConfig.propTypes),
  entry: PropTypes.exact(Entry.propTypes),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

class EntryForm extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleCommissionChange = this.handleCommissionChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleStartDateChange(startDate) {
    const { entry, onChange } = this.props;
    onChange({ ...entry, startDate });
  }

  handleCommissionChange(event) {
    event.preventDefault();
    const { entry, onChange } = this.props;
    const { value } = event.target;
    const commissionPercentage = value ? parseFloat(value) : null;
    onChange({ ...entry, commissionPercentage });
  }

  handleChange(event) {
    event.preventDefault();
    const { entry, onChange } = this.props;
    const { name, value } = event.target;
    onChange({ ...entry, [name]: value });
  }

  render() {
    const { entry, onRemove } = this.props;

    return (
      <tr>
        <td>
          <BSEx.FormGroup model={entry} attribute='startDate'>
            <DateSelect
              selected={entry.startDate}
              onChange={this.handleStartDateChange}
            />
          </BSEx.FormGroup>
        </td>
        <td>
          <BSEx.FormGroup model={entry} attribute='commissionPercentage'>
            <BS.InputGroup>
              <BS.FormControl
                type='number'
                name='commissionPercentage'
                value={
                  entry.commissionPercentage != null
                    ? entry.commissionPercentage
                    : ''
                }
                onChange={this.handleCommissionChange}
              />
              <BS.InputGroup.Addon>%</BS.InputGroup.Addon>
            </BS.InputGroup>
          </BSEx.FormGroup>
        </td>
        <td>
          <BSEx.FormGroup model={entry} attribute='calcMethod'>
            <BS.FormControl
              componentClass='select'
              name='calcMethod'
              value={entry.calcMethod}
              onChange={this.handleChange}
            >
              {Entry.CALC_METHODS.map((calcMethod, index) => (
                <option key={index} value={calcMethod}>
                  {I18n.t(`${scope}.field.calcMethod.selection.${calcMethod}`)}
                </option>
              ))}
            </BS.FormControl>
          </BSEx.FormGroup>
        </td>
        <td>
          {onRemove && (
            <BS.Button bsStyle='danger' onClick={onRemove}>
              <i className='fa fa-trash' />
            </BS.Button>
          )}
        </td>
      </tr>
    );
  }
}

export default EntryForm;
