/* eslint @typescript-eslint/explicit-function-return-type: off */
/* global $ */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';

const propTypes = {
  dtOptions: PropTypes.object,
  ...React.Component.propTypes,
};

class DataTable extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.initDataTable = this.initDataTable.bind(this);

    this.state = {
      tableId: Math.random().toString(32).substring(2),
    };
  }

  componentDidMount() {
    this.table = this.initDataTable();
  }

  initDataTable() {
    const { tableId } = this.state;
    const { dtOptions } = this.props;

    const table = $(`#${tableId}`).DataTable({
      destroy: true,
      info: false,
      language: { lengthMenu: 'Show _MENU_' },
      scrollX: true,
      pageLength: 10,
      buttons: ['copy', 'csv', 'csv-excel'],
      ...dtOptions,
    });

    table
      .buttons()
      .container()
      .appendTo($('.col-sm-5', table.table().container()));

    return table;
  }

  render() {
    const { tableId } = this.state;

    const props = { ...this.props };
    delete props.dtOptions;

    return (
      <BS.Table
        className='flexible-table'
        id={tableId}
        style={{ width: '100%' }}
        {...props}
      />
    );
  }
}

export default DataTable;
