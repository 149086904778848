import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { useLocalStorage } from '../../../../../hooks';
import AdAccountListTable from '../../../../molecules/AdAccountListTable';
import { conditionBuilder } from '../../../../../graphql';
const { search, filter } = conditionBuilder;
import { ResourceType } from '../../../../../enums/PeriodBudgetResource';
import { RESOURCE_COUNT_LIMIT } from '../../../Form';
import { omitProps } from '../../../../../lib/objectUtils';

const propTypes = {
  formik: PropTypes.object,
};

const defaultProps = {};

const AdAccountTabContent = ({
  formik: {
    values: { projectId, resources },
    setFieldValue,
  },
}) => {
  const [initialTableState, saveInitialTableState] = useLocalStorage(
    '/projects/period_budget_reports/form/ad_account_tab/table_state',
    { pagination: { pageIndex: 0, pageSize: 25 } }
  );

  const tableState = useMemo(
    () => ({
      rowSelection: resources.reduce((selection, resource) => {
        if (resource.resourceType == ResourceType.AdAccount) {
          selection[resource.resourceId] = true;
        }
        return selection;
      }, {}),
    }),
    [JSON.stringify(resources)]
  );

  const handleStateChange = ({ pagination, ...state }) =>
    saveInitialTableState({
      ...omitProps(
        state,
        'rowSelection', // リソースの選択状態は保存しない
        'globalFilter' // 検索状態は保存しない
      ),
      pagination: {
        ...pagination,
        pageIndex: 0, // 表示中のページ数は保存しない
      },
    });

  const handleFetch = useCallback(
    async (query, variables) => {
      return await query({
        ...variables,
        search: search.and([
          variables.search,
          filter.eq('projectId', projectId),
          filter.eq('active', true),
        ]),
      });
    },
    [projectId]
  );

  const handleToggleSelected = useCallback(
    (adAccount) => {
      const nextResources = [...resources];

      const index = nextResources.findIndex(
        ({ resourceType, resourceId }) =>
          resourceType == ResourceType.AdAccount && resourceId == adAccount.id
      );
      if (index < 0) {
        nextResources.push({
          resourceType: ResourceType.AdAccount,
          resourceId: adAccount.id,
          channelId: adAccount.channel.id,
          adAccountId: adAccount.id,
        });

        let childIndex = -1;
        while (
          0 <=
          (childIndex = nextResources.findIndex(
            (r) =>
              r.resourceType != ResourceType.AdAccount &&
              r.adAccountId == adAccount.id
          ))
        ) {
          nextResources.splice(childIndex, 1);
        }
      } else {
        nextResources.splice(index, 1);
      }

      setFieldValue('resources', nextResources);
    },
    [JSON.stringify(resources)]
  );

  return (
    <AdAccountListTable
      responsive
      initialState={initialTableState}
      state={tableState}
      enableNewRowSelection={resources.length < RESOURCE_COUNT_LIMIT}
      onStateChange={handleStateChange}
      onFetch={handleFetch}
      onToggleSelected={handleToggleSelected}
    />
  );
};

AdAccountTabContent.propTypes = propTypes;
AdAccountTabContent.defaultProps = defaultProps;

export default connect(AdAccountTabContent);
