import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactTableContext } from '../../contexts';
import { Checkbox } from 'react-bootstrap';
import {
  Table,
  PageLength,
  Pager,
  PagingInfo,
  ColumnVisibility,
  SearchBuilder,
  Layout,
  helpers,
} from './ReactTableDataTableStyled';
const {
  createAdGroupColumnDefs,
  createAdCampaignColumnDefs,
  createAdAccountColumnDefs,
  createChannelColumnDefs,
} = helpers;
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { queryAdGroups } from '../../graphql';
import HelpIconTooltip from '../atoms/HelpIconTooltip';

const scope = 'frontend.components.molecules.AdGroupListTable';

const propTypes = {
  responsive: Table.propTypes.responsive,
  initialState: PropTypes.object,
  state: PropTypes.object,
  isParentSelected: PropTypes.func,
  enableNewRowSelection: PropTypes.bool,
  onStateChange: PropTypes.func,
  onFetch: PropTypes.func,
  onToggleSelected: PropTypes.func,
};

const defaultProps = {
  responsive: Table.defaultProps.responsive,
  initialState: {},
  state: {},
  isParentSelected: () => false,
  enableNewRowSelection: true,
  onStateChange: () => void 0,
  onFetch: async (query, variables) => await query(variables),
  onToggleSelected: () => void 0,
};

const AdGroupListTable = ({
  responsive,
  initialState,
  state: controlledState,
  isParentSelected,
  enableNewRowSelection,
  onStateChange,
  onFetch,
  onToggleSelected,
}) => {
  const columnHelper = createColumnHelper();
  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        cell: ({
          row: { getIsSelected, getToggleSelectedHandler, original: adGroup },
        }) => (
          <div className='text-center'>
            {isParentSelected(adGroup) ? (
              <HelpIconTooltip
                placement='top'
                message={I18n.t([scope, 'message.parentSelected'])}
              >
                <Checkbox checked disabled style={{ margin: 0 }} />
              </HelpIconTooltip>
            ) : (
              <Checkbox
                disabled={!(getIsSelected() || enableNewRowSelection)}
                checked={getIsSelected()}
                onChange={(ev) => {
                  getToggleSelectedHandler()(ev);
                  onToggleSelected(adGroup);
                }}
                style={{ margin: 0 }}
              />
            )}
          </div>
        ),
        enableHiding: false,
      }),
      ...createAdGroupColumnDefs(),
      ...createAdCampaignColumnDefs({ accessorPrefix: 'adCampaign' }),
      ...createChannelColumnDefs({ accessorPrefix: 'channel' }),
      ...createAdAccountColumnDefs({ accessorPrefix: 'adAccount' }),
    ],
    [isParentSelected, enableNewRowSelection, onToggleSelected]
  );

  const [{ totalCount, pageCount }, setPaging] = useState({
    totalCount: -1,
    pageCount: -1,
  });
  const [data, setData] = useState([]);

  const table = useReactTable({
    columns,
    data,
    pageCount,
    initialState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualSorting: true,
    manualPagination: true,
    getRowId: (data, _index, _parent) => data.id,
  });

  // NOTE: `globalFilter` に GraphQL の検索条件を保存している
  //  わりと無理矢理感があるが、テーブルのステートを別で管理すると余計煩雑になる気がするのでこの手法を取っている
  //  (テーブルのステートに独自の状態を保存できれば一番シンプルなのだが、そういうのは定義されていないっぽい)
  const [state, setState] = useState(table.initialState);
  table.setOptions((prev) => ({
    ...prev,
    state: { ...state, ...controlledState },
    onStateChange: (updater) => {
      const nextState = updater(state);

      // 表示件数・ソート順・検索条件が変更になった場合はページ数をリセット
      if (
        state.pagination.pageSize != nextState.pagination.pageSize ||
        JSON.stringify(state.sorting) != JSON.stringify(nextState.sorting) ||
        JSON.stringify(state.globalFilter) !=
          JSON.stringify(nextState.globalFilter)
      ) {
        nextState.pagination.pageIndex = 0;
      }

      setState(nextState);
      onStateChange(nextState);
    },
  }));

  const [fetching, setFetching] = useState(false);
  const { sorting, pagination, globalFilter } = state;
  useEffect(() => {
    (async () => {
      setFetching(true);

      const {
        pagination: { pageIndex, pageSize: limit },
        sorting,
        globalFilter: search,
      } = state;

      const order = sorting.map(({ id, desc }) => {
        const column = table.getColumn(id).columnDef;
        return `${column.meta.field}__${desc ? 'DESC' : 'ASC'}`;
      });

      const page = pageIndex + 1;

      const {
        data,
        paging: { totalCount, totalPages: pageCount },
      } = await onFetch(queryAdGroups, {
        search,
        order,
        page,
        limit,
      });

      setData(data);
      setPaging({ totalCount, pageCount });
      setFetching(false);
    })();
  }, [onFetch, JSON.stringify([sorting, pagination, globalFilter])]);

  return (
    <ReactTableContext.Provider value={table}>
      <Layout
        table={<Table {...{ responsive, fetching }} disabled={fetching} />}
        length={<PageLength disabled={fetching} />}
        colVis={<ColumnVisibility disabled={fetching} />}
        searchBuilder={
          <SearchBuilder
            conditions={globalFilter}
            onChange={(conditions) => {
              table.setState((prev) => ({ ...prev, globalFilter: conditions }));
            }}
          />
        }
        pagination={<Pager disabled={fetching} />}
        info={<PagingInfo {...{ totalCount }} />}
      />
    </ReactTableContext.Provider>
  );
};

AdGroupListTable.propTypes = propTypes;
AdGroupListTable.defaultProps = defaultProps;

export default AdGroupListTable;
