/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';

import defaultLogo from '../../images/logo_with_text_200x50@2x.png';

const propTypes = {
  src: PropTypes.string,
  linkUrl: PropTypes.string.isRequired,
};

class HeaderLogo extends React.Component {
  static propTypes = propTypes;

  render() {
    return (
      <a href={this.props.linkUrl}>
        <img src={this.props.src || defaultLogo} />
      </a>
    );
  }
}

export default HeaderLogo;
