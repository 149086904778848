/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import MonthSelect from '../molecules/MonthSelect';

const scope = 'frontend.components.BudgetReport.MonthSelectDropdown';

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  year: PropTypes.number,
  month: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

class MonthSelectDropdown extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.state = { isOpen: false };
  }

  handleToggle = (isOpen, _event, { source }) => {
    // メニューアイテムを選択したときは閉じない
    // これをしないと月選択のカレンダーを開いたときにドロップダウンが閉じてしまう
    if (source == 'select') return;
    this.setState({ isOpen });
  };

  handleChange = (getter) => {
    return (...args) => {
      this.setState({ isOpen: false });
      const dt = getter(...args);
      this.props.onChange(dt.getFullYear(), dt.getMonth() + 1);
    };
  };

  getThisMonth = () => {
    return new Date();
  };

  getLastMonth = () => {
    const thisMonth = this.getThisMonth();
    return new Date(thisMonth.getFullYear(), thisMonth.getMonth() - 1);
  };

  getPrevMonth = () => {
    const { year, month } = this.props;
    return new Date(year, month - 2);
  };

  getNextMonth = () => {
    const { year, month } = this.props;
    return new Date(year, month);
  };

  getSelectedMonth = (yyyyMM) => {
    return new Date(Date.parse(yyyyMM));
  };

  render = () => {
    const { id, year, month, disabled } = this.props;
    const { isOpen } = this.state;

    return (
      <Dropdown
        className='btn-group'
        id={id}
        open={isOpen}
        onToggle={this.handleToggle}
        disabled={disabled}
      >
        <Dropdown.Toggle>
          <i className='fa fa-calendar' />
          <span style={{ margin: '0 5px' }}>
            {I18n.t(`${scope}.label`, { month: this.textInputRef?.value })}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <li>
            <a onClick={this.handleChange(this.getThisMonth)}>
              {I18n.t(`${scope}.selection.this_month`)}
            </a>
          </li>
          <li>
            <a onClick={this.handleChange(this.getLastMonth)}>
              {I18n.t(`${scope}.selection.last_month`)}
            </a>
          </li>
          <li>
            <a onClick={this.handleChange(this.getPrevMonth)}>
              {I18n.t(`${scope}.selection.prev_month`)}
            </a>
          </li>
          <li>
            <a onClick={this.handleChange(this.getNextMonth)}>
              {I18n.t(`${scope}.selection.next_month`)}
            </a>
          </li>
          <li className='divider' />
          <li style={{ padding: '3px 8px' }}>
            <MonthSelect
              dateFormat={I18n.t(`${scope}.format`)}
              selected={new Date(year, month - 1)}
              onChange={this.handleChange(this.getSelectedMonth)}
              inputRef={(ref) => (this.textInputRef = ref)}
            />
          </li>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
}

export default MonthSelectDropdown;
