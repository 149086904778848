/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import * as BSEx from '../BootstrapExtends';
import DateSelect from '../molecules/DateSelect';
import AdPerformanceReportDataSource from '../../models/AdPerformanceReportDataSource';
import AdPerformanceReportImportJob from '../../models/AdPerformanceReportImportJob';
import { formatInteger } from '../../lib/NumberUtils';
import { today, beginningOfYear } from '../../lib/DateUtils';

const scope = 'frontend.components.AdPerformanceReportImportJob.Form';

const propTypes = {
  dataSource: PropTypes.exact(AdPerformanceReportDataSource.propTypes),
  job: PropTypes.exact(
    Object.assign({}, AdPerformanceReportImportJob.propTypes, {
      forceEncoding: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};

class Form extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSourceDateChange = this.handleSourceDateChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleAdvancedOptionsOpen = this.handleAdvancedOptionsOpen.bind(this);

    const { job } = props;
    this.state = {
      advancedOptionsShown: job.beginningCsvLine != 1,
    };
  }

  handleChange(event) {
    const { job, onChange } = this.props;
    const { name, value } = event.target;
    onChange({ ...job, [name]: value });
  }

  handleFileChange(event) {
    const { job, onChange } = this.props;
    const { name, files } = event.target;
    onChange({ ...job, [name]: files.item(0) });
  }

  handleSourceDateChange(sourceDate) {
    const { job, onChange } = this.props;
    onChange({ ...job, sourceDate });
  }

  handleCheckboxChange(event) {
    const { job, onChange } = this.props;
    const { name, checked } = event.target;
    onChange({ ...job, [name]: checked });
  }

  handleAdvancedOptionsOpen(event) {
    event.preventDefault();
    this.setState({ advancedOptionsShown: true });
  }

  render() {
    const { dataSource, job } = this.props;
    const { advancedOptionsShown } = this.state;

    const thresholdDate = (() => {
      const date = beginningOfYear(today());
      date.setFullYear(date.getFullYear() - 4);
      return date;
    })();

    return (
      <BS.Form>
        <BSEx.FormGroup label={I18n.t(`${scope}.field.dataSource.label`)}>
          <BS.FormControl.Static>{dataSource.name}</BS.FormControl.Static>
        </BSEx.FormGroup>
        <BSEx.FormGroup
          label={I18n.t(`${scope}.field.sourceFile.label`)}
          model={job}
          attribute='sourceFile'
        >
          <BS.FormControl
            type='file'
            name='sourceFile'
            onChange={this.handleFileChange}
          />
          <BS.FormControl.Static>
            {I18n.t(`${scope}.field.sourceFile.disclaimer`, {
              maxFileLineCount: formatInteger(
                dataSource.maxUploadedFileLineCount
              ),
              thresholdDate: I18n.l('date.formats.default', thresholdDate),
            })}
          </BS.FormControl.Static>
        </BSEx.FormGroup>
        {dataSource.assignSourceDateOnUpload && (
          <BSEx.FormGroup
            label={I18n.t(`${scope}.field.sourceDate.label`)}
            model={job}
            attribute='sourceDate'
          >
            <DateSelect
              selected={job.sourceDate}
              onChange={this.handleSourceDateChange}
            />
          </BSEx.FormGroup>
        )}
        <BSEx.FormGroup
          label={I18n.t(`${scope}.field.skipPreview.label`)}
          model={job}
          attribute='skipPreview'
        >
          <BS.Checkbox
            checked={job.skipPreview}
            name='skipPreview'
            onChange={this.handleCheckboxChange}
          >
            {I18n.t(`${scope}.field.skipPreview.description`)}
          </BS.Checkbox>
        </BSEx.FormGroup>
        {!advancedOptionsShown ? (
          <BSEx.FormGroup>
            <BS.FormControl.Static>
              <a href='' onClick={this.handleAdvancedOptionsOpen}>
                {I18n.t(`${scope}.action.openAdvancedOptions`)}
              </a>
            </BS.FormControl.Static>
          </BSEx.FormGroup>
        ) : (
          <>
            <BSEx.FormGroup
              label={I18n.t(`${scope}.field.beginningCsvLine.label`)}
              model={job}
              attribute='beginningCsvLine'
            >
              <BS.FormControl
                type='number'
                name='beginningCsvLine'
                value={job.beginningCsvLine}
                onChange={this.handleChange}
              />
              <BS.FormControl.Static>
                {I18n.t(`${scope}.field.beginningCsvLine.description`)}
              </BS.FormControl.Static>
            </BSEx.FormGroup>
            <BSEx.FormGroup
              label={I18n.t(`${scope}.field.encoding.label`)}
              model={job}
              attribute='encoding'
            >
              <BS.FormControl
                componentClass='select'
                name='forceEncoding'
                value={job.encoding}
                onChange={this.handleChange}
              >
                <option value=''>
                  {I18n.t(`${scope}.field.encoding.selection.auto_detect`)}
                </option>
                <option value='Windows-31J'>
                  {I18n.t(`${scope}.field.encoding.selection.windows_31j`)}
                </option>
                <option value='UTF-8'>
                  {I18n.t(`${scope}.field.encoding.selection.utf_8`)}
                </option>
              </BS.FormControl>
            </BSEx.FormGroup>
          </>
        )}
      </BS.Form>
    );
  }
}

export default Form;
