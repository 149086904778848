import React from 'react';

import ErrorBoundary from './ErrorBoundary';
export default ErrorBoundary;

import PleaseTryAgainLater from './PleaseTryAgainLater';
export { PleaseTryAgainLater };

// @see https://ja.reactjs.org/docs/higher-order-components.html
export const withErrorBoundary = (
  Component,
  fallback = <PleaseTryAgainLater />
) => {
  const WrappedComponent = (props) => (
    <ErrorBoundary fallback={fallback}>
      <Component {...props} />
    </ErrorBoundary>
  );

  WrappedComponent.displayName = `withErrorBoundary(${
    Component.displayName || Component.name || 'Component'
  })`;
  WrappedComponent.propTypes = Component.propTypes;
  WrappedComponent.defaultProps = Component.defaultProps;

  return WrappedComponent;
};
