import { createEnums } from './helpers';

const scope = 'frontend.enums.common';

export const ResourceType = createEnums(
  {
    Project: '0',
    Channel: '1',
    AdAccount: '2',
    AdCampaign: '3',
    AdGroup: '4',
    Ad: '5',
  },
  { scope: `${scope}.ResourceType` }
);
