import React from 'react';
import PropTypes from 'prop-types';
import { connect, FieldArray } from 'formik';
import { ControlLabel } from 'react-bootstrap';
import { FormGroup, ErrorMessage } from '../../../../react-bootstrap-formik';
import HelpIconTooltip from '../../../atoms/HelpIconTooltip';
import DatePicker from 'react-datepicker';
import { DAYS_LIMIT_FOR_DATE_SELECT_MODE } from '../../Form';
import { today, addMonths } from '../../../../lib/DateUtils';
import strftime from 'strftime';
import { scope } from './';

const propTypes = {
  formik: PropTypes.object,
};

const defaultProps = {};

const CalendarDatesField = ({
  formik: {
    values: { dailyAmounts },
  },
}) => {
  const highlightDates = dailyAmounts.map(
    ({ scheduledDate }) => new Date(scheduledDate)
  );
  const startDate = highlightDates.length
    ? new Date(Math.min(...highlightDates))
    : null;

  const isAvailableDate = (date) => {
    if (highlightDates.length < DAYS_LIMIT_FOR_DATE_SELECT_MODE) {
      return true;
    }

    return !!highlightDates.find(
      (highlightDate) =>
        highlightDate.getFullYear() == date.getFullYear() &&
        highlightDate.getMonth() == date.getMonth() &&
        highlightDate.getDate() == date.getDate()
    );
  };

  return (
    <FieldArray name='dailyAmounts'>
      {({ name, push, remove }) => (
        <FormGroup name={name}>
          <ControlLabel>
            {I18n.t([scope, 'calendarDates.label'])}{' '}
            <HelpIconTooltip
              placement='top'
              message={I18n.t([scope, 'calendarDates.tooltip'], {
                daysLimit: DAYS_LIMIT_FOR_DATE_SELECT_MODE,
              })}
            />
          </ControlLabel>
          <DatePicker
            inline
            calendarClassName='multi-select-datepicker'
            selected={null}
            highlightDates={highlightDates}
            filterDate={isAvailableDate}
            onChange={(date) => {
              const scheduledDate = strftime('%F', date);
              const index = dailyAmounts.findIndex(
                (a) => a.scheduledDate == scheduledDate
              );
              index < 0 ? push({ scheduledDate, amount: '' }) : remove(index);
            }}
            monthsShown={3}
            openToDate={startDate || addMonths(today(), -1)}
            locale={I18n.locale}
          />
          <ErrorMessage name={name}>
            {(msg) =>
              !Array.isArray(msg) && (
                <ErrorMessage.Container>{msg}</ErrorMessage.Container>
              )
            }
          </ErrorMessage>
        </FormGroup>
      )}
    </FieldArray>
  );
};

CalendarDatesField.propTypes = propTypes;
CalendarDatesField.defaultProps = defaultProps;

export default connect(CalendarDatesField);
