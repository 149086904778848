/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import Header from './Box/Header';
import Body from './Box/Body';
import Footer from './Box/Footer';
import Title from './Box/Title';

const Box = ({ children }) => <div className='box'>{children}</div>;

Box.propTypes = {
  children: PropTypes.node,
};

Box.Header = Header;
Box.Body = Body;
Box.Footer = Footer;
Box.Title = Title;

export default Box;
