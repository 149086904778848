/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ children }) => <div className='box-footer'>{children}</div>;

Footer.propTypes = {
  children: PropTypes.node,
};

export default Footer;
