/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import ConversionPoint from '../../models/ConversionPoint';
import { ReportRow } from '../../models/MonthlyBudget';
import {
  Table,
  FormGroup,
  InputGroup,
  FormControl,
  Button,
} from 'react-bootstrap';
import { FlowSpinner } from '../atoms/Loading';
import * as fmt from '../../lib/NumberUtils';
import * as Resource from '../../apis/BudgetReport';

const AVAILABLE_COLUMNS = [
  'resource',
  'performance.cpm',
  'performance.cpc',
  'performance.impressions',
  'performance.ctr',
  'performance.clicks',
  'performance.cvr',
  'performance.conversions',
  'performance.cpa',
  'performance.conversionValue',
  'performance.roas',
  'performance.spend',
  'budgetPerformance.budget',
  'budgetPerformance.progress',
  'budgetPerformance.remainingBudget',
  'budgetPerformance.remainingDays',
  'forecast.forecastSpend',
  'forecast.forecastProgress',
  'forecast.forecastRemainingBudget',
  'dailyBudget.avgDailySpend',
  'dailyBudget.recommendedDailyBudget',
];

const FIXED_COLUMNS = ['resource'];

const scope = 'frontend.components.BudgetReport.ListTable';

const propTypes = {
  year: PropTypes.number,
  month: PropTypes.number,
  cvPointIds: PropTypes.arrayOf(ConversionPoint.propTypes.id),
  rows: PropTypes.arrayOf(PropTypes.exact(ReportRow.propTypes)),
  columns: PropTypes.arrayOf(PropTypes.string),
  fetching: PropTypes.bool,
  onChange: PropTypes.func,
};

const defaultProps = {
  columns: AVAILABLE_COLUMNS,
};

class ListTable extends React.Component {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  constructor(props) {
    super(props);

    this.state = { contentHeight: this.computeContentHeight() };
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
  };

  computeContentHeight = () => {
    // 画面最下部までスクロールしてもテーブルヘッダーすべて表示されるくらいのサイズ
    const { innerHeight } = window;
    return innerHeight - 160;
  };

  handleResize = () => {
    this.setState({ contentHeight: this.computeContentHeight() });
  };

  render() {
    const { columns } = this.props;
    const { contentHeight } = this.state;

    return (
      <div
        className='table-responsive table-sticky'
        style={{ maxHeight: contentHeight }}
      >
        <Table className='flexible-table'>
          <TableHeader columns={columns} />
          <TableBody {...this.props} />
        </Table>
      </div>
    );
  }
}

class TableHeader extends React.Component {
  static propTypes = { columns: propTypes.columns };

  getRowSpan = () => {
    const { columns } = this.props;
    if (columns.find((column) => column.includes('.'))) {
      return 2;
    } else {
      return 1;
    }
  };

  getRows = () => {
    const { columns } = this.props;
    const rowSpan = this.getRowSpan();

    const rows = [];
    for (let i = 0; i < rowSpan; i++) {
      rows[i] = [];
    }
    columns.forEach((column) => {
      const [name, subName] = column.split('.');
      if (subName) {
        const groupingColumn = rows[0][rows[0].length - 1];
        if (groupingColumn?.name != name) {
          const colSpan = columns.filter((c) =>
            c.startsWith(`${name}.`)
          ).length;
          rows[0].push({ name, colSpan });
        }
        rows[1].push({ name: subName });
      } else {
        rows[0].push({ name, rowSpan });
      }
    });

    return rows.map((columnDefs) =>
      columnDefs.map(({ name, ...props }, index) => (
        <th key={index} {...props}>
          {I18n.t(`${scope}.header.${name}`)}
        </th>
      ))
    );
  };

  render = () => {
    const rows = this.getRows();

    return (
      <thead>
        {rows.map((columns, index) => (
          <tr key={index}>{columns}</tr>
        ))}
      </thead>
    );
  };
}

class TableBody extends React.Component {
  static propTypes = propTypes;

  static LoadingState = ({ columns }) => (
    <tbody>
      <tr>
        <td colSpan={columns.length}>
          <FlowSpinner />
        </td>
      </tr>
    </tbody>
  );

  static EmptyState = ({ columns }) => (
    <tbody>
      <tr>
        <td className='text-center' colSpan={columns.length}>
          {I18n.t(`${scope}.message.emptyData`)}
        </td>
      </tr>
    </tbody>
  );

  handleChange = (index) => {
    return (row) => {
      this.props.onChange(index, row);
    };
  };

  render = () => {
    const { year, month, cvPointIds, rows, columns, fetching } = this.props;

    if (fetching) {
      return <TableBody.LoadingState columns={columns} />;
    }

    if (!rows || !rows.length) {
      return <TableBody.EmptyState columns={columns} />;
    }

    return (
      <tbody>
        {rows.map((row, index) => (
          <TableRow
            key={index}
            year={year}
            cvPointIds={cvPointIds}
            month={month}
            columns={columns}
            row={row}
            onChange={this.handleChange(index)}
          />
        ))}
      </tbody>
    );
  };
}

class TableRow extends React.Component {
  static propTypes = {
    year: propTypes.year,
    month: propTypes.month,
    cvPointIds: propTypes.cvPointIds,
    columns: propTypes.columns,
    row: PropTypes.exact(ReportRow.propTypes),
    onChange: propTypes.onChange,
  };

  constructor(props) {
    super(props);

    const { children } = props.row;
    this.state = {
      expanded: !!children,
      loading: false,
    };
  }

  handleToggle = () => {
    if (this.state.loading) return;

    const expanded = !this.state.expanded;
    this.setState({ expanded });

    const { year, month, cvPointIds, row } = this.props;
    if (expanded && !row.children) {
      // まだ子要素を読み込んでいない場合
      this.setState({ loading: true });

      const { projectId, channel, adAccountId } = row;
      return Resource.list(projectId, {
        channel,
        adAccountId,
        year,
        month,
        cvPointIds,
      })
        .then(({ data }) => {
          const newRow = new ReportRow(data[0]);
          this.props.onChange(newRow);
        })
        .finally(() => this.setState({ loading: false }));
    }
  };

  handleChildChange = (index) => {
    return (child) => {
      const children = this.props.row.children.slice();
      children[index] = child;
      const row = new ReportRow({ ...this.props.row, children });
      this.props.onChange(row);
    };
  };

  remainingBudgetColumnClassName = (remainingBudget) => {
    if (remainingBudget < 0) {
      return 'text-danger';
    }
    return null;
  };

  progressColumnClassName = (progress) => {
    if (1 < progress) {
      return 'bg-danger';
    } else if (0.95 <= progress && progress < 1) {
      return 'bg-success';
    } else {
      return 'bg-warning';
    }
  };

  render = () => {
    const { year, month, cvPointIds, row } = this.props;
    const { expanded, loading } = this.state;

    // 予算設定できるのは広告キャンペーンレベルまで
    const canExpand = row.resourceType < 3;

    const columns = this.props.columns.map((column) => {
      const [name, subName] = column.split('.');
      return subName || name;
    });

    return (
      <>
        <tr>
          {columns.reduce((tableCols, column, index) => {
            if (column == 'resource') {
              tableCols.push(
                <td key={index} className={`indent-level-${row.resourceType}`}>
                  {canExpand ? (
                    <span
                      onClick={this.handleToggle}
                      style={{ cursor: 'pointer' }}
                    >
                      <i
                        className={`fa ${
                          expanded ? 'fa-caret-down' : 'fa-caret-right'
                        }`}
                        style={{ marginRight: '6px' }}
                      />
                      {row.name}
                    </span>
                  ) : (
                    <>{row.name}</>
                  )}
                  {row.resourceType == 0 && (
                    <a
                      href={`/projects/${row.projectId}/start`}
                      target='_blank'
                      rel='noreferrer noopener'
                      style={{ marginLeft: '6px' }}
                    >
                      <i className='fa fa-external-link' />
                    </a>
                  )}
                </td>
              );
            } else if (
              ['cpm', 'cpc', 'cpa', 'spend', 'avgDailySpend'].includes(column)
            ) {
              tableCols.push(
                <td key={index} className='text-right text-nowrap'>
                  {fmt.formatCurrency(row[column], row.currency.toLowerCase())}
                </td>
              );
            } else if (column == 'budget') {
              tableCols.push(
                <td key={index} className='text-right text-nowrap'>
                  <BudgetInput
                    year={year}
                    month={month}
                    row={row}
                    onChange={this.props.onChange}
                  />
                </td>
              );
            } else if (['ctr', 'cvr'].includes(column)) {
              tableCols.push(
                <td key={index} className='text-right text-nowrap'>
                  {fmt.formatPercentage(row[column] * 100, { precision: 3 })}
                </td>
              );
            } else if (['roas'].includes(column)) {
              tableCols.push(
                <td key={index} className='text-right text-nowrap'>
                  {fmt.formatPercentage(row[column] * 100, { precision: 0 })}
                </td>
              );
            } else if (['progress'].includes(column)) {
              tableCols.push(
                <td
                  key={index}
                  className={`text-right text-nowrap ${
                    row.ofPastMonth && row.budget
                      ? this.progressColumnClassName(row[column])
                      : ''
                  }`}
                >
                  {row.budget
                    ? fmt.formatPercentage(row[column] * 100, { precision: 2 })
                    : '-'}
                </td>
              );
            } else if (['remainingBudget'].includes(column)) {
              tableCols.push(
                <td
                  key={index}
                  className={`text-right text-nowrap ${
                    row.budget
                      ? this.remainingBudgetColumnClassName(row[column])
                      : ''
                  }`}
                >
                  {row.budget
                    ? fmt.formatCurrency(
                        row[column],
                        row.currency.toLowerCase()
                      )
                    : '-'}
                </td>
              );
            } else if (['forecastSpend'].includes(column)) {
              tableCols.push(
                <td key={index} className='text-right text-nowrap'>
                  {row.ofThisMonth
                    ? fmt.formatCurrency(
                        row[column],
                        row.currency.toLowerCase()
                      )
                    : '-'}
                </td>
              );
            } else if (['forecastRemainingBudget'].includes(column)) {
              const renderValue = row.ofThisMonth && row.budget;
              tableCols.push(
                <td
                  key={index}
                  className={`text-right text-nowrap ${
                    renderValue
                      ? this.remainingBudgetColumnClassName(row[column])
                      : ''
                  }`}
                >
                  {renderValue
                    ? fmt.formatCurrency(
                        row[column],
                        row.currency.toLowerCase()
                      )
                    : '-'}
                </td>
              );
            } else if (['forecastProgress'].includes(column)) {
              const renderValue = row.ofThisMonth && row.budget;
              tableCols.push(
                <td
                  key={index}
                  className={`text-right text-nowrap ${
                    renderValue ? this.progressColumnClassName(row[column]) : ''
                  }`}
                >
                  {renderValue
                    ? fmt.formatPercentage(row[column] * 100, { precision: 2 })
                    : '-'}
                </td>
              );
            } else if (['recommendedDailyBudget'].includes(column)) {
              tableCols.push(
                <td key={index} className='text-right text-nowrap'>
                  {row.ofThisMonth && row.budget
                    ? fmt.formatCurrency(
                        row[column],
                        row.currency.toLowerCase()
                      )
                    : '-'}
                </td>
              );
            } else {
              tableCols.push(
                <td key={index} className='text-right text-nowrap'>
                  {fmt.formatInteger(row[column])}
                </td>
              );
            }

            return tableCols;
          }, [])}
        </tr>
        {loading ? (
          <tr>
            <td
              colSpan={columns.length}
              className={`indent-level-${row.resourceType + 1}`}
            >
              <FlowSpinner />
            </td>
          </tr>
        ) : (
          expanded &&
          row.children.map((child, index) => (
            <TableRow
              key={index}
              year={year}
              month={month}
              cvPointIds={cvPointIds}
              columns={columns}
              row={child}
              onChange={this.handleChildChange(index)}
            />
          ))
        )}
      </>
    );
  };
}

class BudgetInput extends React.Component {
  static propTypes = {
    year: propTypes.year,
    month: propTypes.month,
    row: PropTypes.exact(ReportRow.propTypes),
    onChange: propTypes.onChange,
  };

  constructor(props) {
    super(props);

    const { row } = props;
    this.state = {
      editing: false,
      submitting: false,
      amount: row.budget,
      error: null,
    };
  }

  handleEdit = () => {
    const { budget: amount } = this.props.row;
    this.setState({ editing: true, amount });
  };

  handleChange = ({ target }) => {
    const amount = parseFloat(target.value);
    this.setState({
      amount: isNaN(amount) ? target.value : amount,
    });
  };

  handleSubmit = () => {
    this.setState({ submitting: true });

    const { year, month, row } = this.props;
    const { projectId, resourceType, resourceId } = row;
    const { amount } = this.state;
    return Resource.setBudget(
      projectId,
      { year, month },
      { resourceType, resourceId, amount }
    )
      .then(({ amountMicro: budgetMicro, errors }) => {
        if (errors) throw new Error(errors);
        this.setState({ editing: false, error: null });

        const row = new ReportRow({ ...this.props.row, budgetMicro });
        this.props.onChange(row);
      })
      .catch((error) => this.setState({ error: error }))
      .finally(() => this.setState({ submitting: false }));
  };

  handleCancel = () => {
    if (this.state.submitting) return;
    this.setState({ editing: false, error: null });
  };

  handleKeyDown = ({ key }) => {
    switch (key) {
      case 'Enter':
        this.handleSubmit();
        break;
      case 'Esc':
      case 'Escape':
        this.handleCancel();
        break;
    }
  };

  render = () => {
    const { row } = this.props;

    if (!this.state.editing) {
      return (
        <a onClick={this.handleEdit} style={{ cursor: 'pointer' }}>
          {fmt.formatCurrency(row.budget, row.currency.toLowerCase())}
          <i className='fa fa-pencil' style={{ marginLeft: '6px' }} />
        </a>
      );
    }

    const { amount, submitting, error } = this.state;
    return (
      <FormGroup validationState={error ? 'error' : null}>
        <InputGroup>
          <InputGroup.Addon>
            {I18n.t('number.currency.format.unit', {
              locale: row.currency.toLowerCase(),
            })}
          </InputGroup.Addon>
          <FormControl
            type='number'
            value={amount}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            disabled={submitting}
            style={{ minWidth: '100px' }}
            autoFocus
          />
          <InputGroup.Button>
            <Button
              bsStyle='primary'
              onClick={this.handleSubmit}
              disabled={submitting}
            >
              <i className='fa fa-check-circle' />
            </Button>
            <Button
              bsStyle='danger'
              onClick={this.handleCancel}
              disabled={submitting}
            >
              <i className='fa fa-times-circle' />
            </Button>
          </InputGroup.Button>
        </InputGroup>
      </FormGroup>
    );
  };
}

export default ListTable;
export { AVAILABLE_COLUMNS, FIXED_COLUMNS, scope };
