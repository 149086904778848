/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
};

class ActionLink extends React.Component {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(handler) {
    return (event) => {
      event.preventDefault();
      if (handler) {
        handler(event);
      }
    };
  }

  render() {
    const { onClick, disabled, ...props } = this.props;
    return (
      <a
        onClick={this.handleClick(disabled ? undefined : onClick)}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        {...props}
      />
    );
  }
}

export default ActionLink;
