/* eslint @typescript-eslint/explicit-function-return-type: off */

export const addYears = (date, years) => {
  const ret = new Date(date.valueOf());
  ret.setFullYear(date.getFullYear() + years);
  return ret;
};

export const addMonths = (date, months) => {
  const ret = new Date(date.valueOf());
  ret.setMonth(date.getMonth() + months);
  return ret;
};

export const addDays = (date, days) => {
  const ret = new Date(date.valueOf());
  ret.setDate(date.getDate() + days);
  return ret;
};

export const today = () => new Date();
export const yesterday = () => addDays(new Date(), -1);

export const beginningOfYear = (date) => {
  const ret = new Date(date.valueOf());
  ret.setMonth(0);
  ret.setDate(1);
  return ret;
};

export const endOfYear = (date) => {
  const ret = new Date(date.valueOf());
  ret.setMonth(11);
  ret.setDate(31);
  return ret;
};

export const beginningOfMonth = (date) => {
  const ret = new Date(date.valueOf());
  ret.setDate(1);
  return ret;
};

export const endOfMonth = (date) => {
  return addDays(beginningOfMonth(addMonths(date, 1)), -1);
};

export const formatDate = (dateString) => {
  if (!dateString) {
    return null;
  }
  const date = new Date(Date.parse(dateString));
  return date.toLocaleDateString();
};

export const formatDateTime = (dateString) => {
  if (!dateString) {
    return null;
  }
  const date = new Date(Date.parse(dateString));
  return date.toLocaleString();
};
