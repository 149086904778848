/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import Project from '../../models/Project';
import AdAccount from '../../models/AdAccount';
import CsvImportJob from '../../models/CsvImportJob';
import FormModal from './FormModal';

const scope = 'frontend.components.CsvImportJob.FormModalButton';

const propTypes = {
  project: PropTypes.exact(Project.propTypes),
  adAccounts: PropTypes.arrayOf(PropTypes.exact(AdAccount.propTypes)),
  job: PropTypes.exact(CsvImportJob.propTypes),
};

class FormModalButton extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);

    this.state = {
      modalShown: false,
    };
  }

  toggleModal(modalShown) {
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      this.setState({ modalShown });
    };
  }

  render() {
    const { project, adAccounts, job } = this.props;
    const { modalShown } = this.state;

    return (
      <>
        <BS.Button bsStyle='primary' onClick={this.toggleModal(true)}>
          {I18n.t(`${scope}.label`)}
        </BS.Button>
        <FormModal
          project={project}
          adAccounts={adAccounts}
          job={job}
          shown={modalShown}
          onHide={this.toggleModal(false)}
        />
      </>
    );
  }
}

export default FormModalButton;
