/* eslint @typescript-eslint/explicit-function-return-type: off */

class JSONLReader {
  constructor(readableReader) {
    this.reader = readableReader;
  }

  forEach(callback) {
    const decoder = new TextDecoder();

    let buffer = '';
    let dataCount = 0;

    return new Promise((resolve, reject) => {
      const readChunk = ({ done, value }) => {
        if (done) {
          return resolve(dataCount);
        }

        buffer += decoder.decode(value);
        while (/\r\n|\n|\r/.test(buffer)) {
          const [json, ...others] = buffer.split(/\r\n|\n|\r/);
          callback(JSON.parse(json), dataCount);
          buffer = others.join('\n');
          dataCount += 1;
        }

        this.reader.read().then(readChunk).catch(reject);
      };

      this.reader.read().then(readChunk).catch(reject);
    });
  }

  read() {
    const list = [];

    return new Promise((resolve, reject) => {
      this.forEach((data) => {
        list.push(data);
      })
        .then(() => resolve(list))
        .catch(reject);
    });
  }
}

export default JSONLReader;
