import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCurrentProjectAccessibility } from '../../../../../hooks';
import { connect } from 'formik';
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Button,
} from 'react-bootstrap';
import { formatCurrency } from '../../../../../lib/NumberUtils';

const scope =
  'frontend.components.PeriodBudgetReport.Form.FormFields.DailyAmountsField.BulkChangeAmountModal';

const propTypes = {
  formik: PropTypes.object,
  children: PropTypes.func,
};

const defaultProps = {};

const BulkChangeAmountModal = ({
  formik: {
    values: { dailyAmounts },
    setFieldValue,
  },
  children,
}) => {
  const {
    accessibility: { project },
  } = useCurrentProjectAccessibility();

  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState('');

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  const isValid = !!amount;
  const totalAmount = isValid ? amount * dailyAmounts.length : null;

  const handleSubmit = () => {
    setFieldValue(
      'dailyAmounts',
      dailyAmounts.map((dailyAmount) => ({
        ...dailyAmount,
        amount,
      }))
    );
    hideModal();
  };

  return (
    <>
      {children({ showModal, hideModal })}
      <Modal {...{ show, onHide: hideModal }}>
        <Modal.Header closeButton>
          {I18n.t([scope, 'header.title'])}
        </Modal.Header>
        <Modal.Body>
          <p>{I18n.t([scope, 'body.description'])}</p>
          <FormGroup>
            <ControlLabel>
              {I18n.t([scope, 'body.form.field.amount.label'])}
            </ControlLabel>
            <FormControl
              type='number'
              value={amount}
              onChange={({ target: { value } }) =>
                setAmount(value ? Number(value) : '')
              }
            />
            {totalAmount && (
              <HelpBlock>
                {I18n.t([scope, 'body.form.field.amount.help'], {
                  totalAmount: formatCurrency(totalAmount, project.currency),
                })}
              </HelpBlock>
            )}
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle='primary' disabled={!isValid} onClick={handleSubmit}>
            {I18n.t([scope, 'footer.button.submit.label'])}
          </Button>
          <Button onClick={hideModal}>
            {I18n.t([scope, 'footer.button.close.label'])}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

BulkChangeAmountModal.propTypes = propTypes;
BulkChangeAmountModal.defaultProps = defaultProps;

export default connect(BulkChangeAmountModal);
