import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import { HelpBlock as Container } from 'react-bootstrap';

const propTypes = {
  name: PropTypes.string,
};

const defaultProps = {};

const ReactBootstrapFormikErrorMessage = ({ name, ...props }) => {
  return <ErrorMessage name={name} component={Container} {...props} />;
};

ReactBootstrapFormikErrorMessage.propTypes = propTypes;
ReactBootstrapFormikErrorMessage.defaultProps = defaultProps;

ReactBootstrapFormikErrorMessage.Container = Container;

export default ReactBootstrapFormikErrorMessage;
