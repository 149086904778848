/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import * as BSEx from '../../../BootstrapExtends';
import { SegmentField } from '../../../../models/AdPerformanceReportDataSource';

const scope =
  'frontend.components.AdPerformanceReportDataSource.Form.SegmentFieldsForm';

const propTypes = {
  field: PropTypes.exact(SegmentField.propTypes),
  shown: PropTypes.bool,
  onChange: PropTypes.func,
  onHide: PropTypes.func,
};

class AdvancedSourceOptionsFormModal extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    const { sourceDateFormat, sourceDatetimeFormat, sourceTimezone } =
      props.field;
    this.state = {
      sourceDateFormat,
      sourceDatetimeFormat,
      sourceTimezone,
    };
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleApply(event) {
    const { field, onChange, onHide } = this.props;
    onChange({ ...field, ...this.state }, event);
    onHide(event);
  }

  handleCancel(event) {
    const { field, onHide } = this.props;
    const { sourceDateFormat, sourceDatetimeFormat, sourceTimezone } = field;
    this.setState({ sourceDateFormat, sourceDatetimeFormat, sourceTimezone });
    onHide(event);
  }

  render() {
    const { field, shown } = this.props;
    const { sourceDateFormat, sourceDatetimeFormat, sourceTimezone } =
      this.state;
    const { sourceOptions } = field;

    return (
      <BS.Modal show={shown} onHide={this.handleCancel}>
        <BS.Modal.Header closeButton>
          <BS.Modal.Title>
            {I18n.t(`${scope}.action.configureAdvancedOptions`)}
          </BS.Modal.Title>
        </BS.Modal.Header>
        <BS.Modal.Body>
          {'sourceDateFormat' in sourceOptions && (
            <BSEx.FormGroup
              label={I18n.t(`${scope}.field.sourceDateFormat.label`)}
              model={field}
              attribute='sourceDateFormat'
            >
              <BS.FormControl
                type='text'
                placeholder={I18n.t(
                  `${scope}.field.sourceDateFormat.placeholder`
                )}
                name='sourceDateFormat'
                value={sourceDateFormat}
                onChange={this.handleChange}
              />
            </BSEx.FormGroup>
          )}
          {'sourceDatetimeFormat' in sourceOptions && (
            <BSEx.FormGroup
              label={I18n.t(`${scope}.field.sourceDatetimeFormat.label`)}
              model={field}
              attribute='sourceDatetimeFormat'
            >
              <BS.FormControl
                type='text'
                placeholder={I18n.t(
                  `${scope}.field.sourceDatetimeFormat.placeholder`
                )}
                name='sourceDatetimeFormat'
                value={sourceDatetimeFormat}
                onChange={this.handleChange}
              />
            </BSEx.FormGroup>
          )}
          {'sourceTimezone' in sourceOptions && (
            <BSEx.FormGroup
              label={I18n.t(`${scope}.field.sourceTimezone.label`)}
              model={field}
              attribute='sourceTimezone'
            >
              <BS.FormControl
                type='text'
                placeholder={I18n.t(
                  `${scope}.field.sourceTimezone.placeholder`
                )}
                name='sourceTimezone'
                value={sourceTimezone}
                onChange={this.handleChange}
              />
            </BSEx.FormGroup>
          )}
        </BS.Modal.Body>
        <BS.Modal.Footer>
          <BS.Button onClick={this.handleCancel}>
            {I18n.t(`${scope}.action.cancelAdvancedOptions`)}
          </BS.Button>
          <BS.Button bsStyle='primary' onClick={this.handleApply}>
            {I18n.t(`${scope}.action.applyAdvancedOptions`)}
          </BS.Button>
        </BS.Modal.Footer>
      </BS.Modal>
    );
  }
}

export default AdvancedSourceOptionsFormModal;
