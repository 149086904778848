import { client } from '../';

const AdAccountsQuery = `
  query($search: JSON!, $order: [String!], $page: Int!, $limit: Int!) {
    adAccounts(
      search: $search
      order: $order
      page: $page
      limit: $limit
    ) {
      data {
        id, remoteId, name, remoteStatus
        channel { id, name }
      }
      paging { totalCount, totalPages }
    }
  }
`;

export const queryAdAccounts = async (variables, operationContext = {}) => {
  const { data, error } = await client
    .query(AdAccountsQuery, variables, operationContext)
    .toPromise();
  if (error) {
    throw new Error(error);
  }

  return data.adAccounts;
};
