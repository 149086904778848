/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import Project from '../../models/Project';
import AdPerformanceReportDataSource from '../../models/AdPerformanceReportDataSource';
import * as Resource from '../../apis/AdPerformanceReportDataSource';
import * as Routes from '../../routes/AdPerformanceReportDataSource';
import Box from '../atoms/Box';
import Form from './Form';

const scope = 'frontend.components.AdPerformanceReportDataSource.FormBox';

const propTypes = {
  project: PropTypes.exact(Project.propTypes),
  dataSource: PropTypes.exact(AdPerformanceReportDataSource.propTypes),
};

class FormBox extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendRequest = this.sendRequest.bind(this);

    const { dataSource } = props;
    this.state = {
      dataSource: dataSource || AdPerformanceReportDataSource.initialProps(),
      submitting: false,
    };
  }

  handleChange(dataSource) {
    this.setState({ dataSource });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ submitting: true });

    return this.sendRequest()
      .then((dataSource) => {
        this.setState({ dataSource });
        if (dataSource.errors) {
          this.setState({ submitting: false });
          return;
        }

        const { project } = this.props;
        location.href = Routes.detailsPath(project.id, dataSource.id);
      })
      .catch((error) => {
        console.error(error);
        alert(I18n.t(`${scope}.message.error_occurred`));
        this.setState({ submitting: false });
      });
  }

  sendRequest() {
    const { project, dataSource } = this.props;
    const { dataSource: dataSourceParams } = this.state;

    if (dataSource?.id) {
      return Resource.update(project.id, dataSource.id, dataSourceParams);
    } else {
      return Resource.create(project.id, dataSourceParams);
    }
  }

  render() {
    const { dataSource } = this.props;
    const { dataSource: dataSourceProps, submitting } = this.state;
    const action = dataSource?.id ? 'update' : 'create';

    return (
      <Box>
        <Box.Body>
          <Form
            dataSource={new AdPerformanceReportDataSource(dataSourceProps)}
            onChange={this.handleChange}
          />
        </Box.Body>

        <Box.Footer>
          <BS.Button
            bsStyle='primary'
            onClick={this.handleSubmit}
            disabled={submitting}
          >
            {I18n.t(`${scope}.action.${action}`)}
          </BS.Button>
        </Box.Footer>
      </Box>
    );
  }
}

export default FormBox;
