import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  thumbnailUrl: PropTypes.string,
  imageUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  onClick: PropTypes.func,
};

const defaultProps = {};

const MediaThumbnail = ({ onClick, ...props }) => {
  const { thumbnailUrl, imageUrl, videoUrl } = props;

  if (thumbnailUrl || imageUrl) {
    return (
      <img
        className='img-responsive'
        src={thumbnailUrl || imageUrl}
        onClick={(ev) => onClick(props, ev)}
        style={{ minWidth: 100 }}
      />
    );
  }

  if (videoUrl) {
    return (
      <video
        src={videoUrl}
        onClick={(ev) => onClick(props, ev)}
        style={{ minWidth: 100 }}
      />
    );
  }

  return null;
};

MediaThumbnail.propTypes = propTypes;
MediaThumbnail.defaultProps = defaultProps;

export default MediaThumbnail;
