/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { responseReader } from '../../lib/HTTP';
import AdPerformanceReportDataSource from '../../models/AdPerformanceReportDataSource';
import AdPerformanceReportImportJob from '../../models/AdPerformanceReportImportJob';
import JSONLReader from '../../lib/JSONLReader';
import Box from '../atoms/Box';
import DataTable from '../DataTable';

const scope = 'frontend.components.AdPerformanceReportImportJob.DataListBox';

const propTypes = {
  dataSource: PropTypes.exact(AdPerformanceReportDataSource.propTypes),
  job: PropTypes.exact(AdPerformanceReportImportJob.propTypes),
};

class DataListBox extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.renderShowDetailsTrigger = this.renderShowDetailsTrigger.bind(this);
    this.handleShowDetailsClick = this.handleShowDetailsClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleRetry = this.handleRetry.bind(this);

    this.state = {
      detailsShown: false,
      data: [],
      fetched: false,
      fetchFailed: false,
    };
  }

  fetchData() {
    const { job } = this.props;

    this.setState({ fetched: false, fetchFailed: false });

    fetch(job.preprocessedFile.url, { mode: 'cors' })
      .then(responseReader)
      .then((reader) => new JSONLReader(reader))
      .then((reader) => {
        reader.read().then((data) => {
          this.setState({ fetched: true, data });
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ fetchFailed: true });
      });
  }

  componentDidMount() {
    this.fetchData();
  }

  handleRetry(event) {
    event.preventDefault();
    this.fetchData();
  }

  renderShowDetailsTrigger() {
    return (
      <Box>
        <Box.Body>
          <Button bsStyle='link' onClick={this.handleShowDetailsClick}>
            {I18n.t(`${scope}.message.show_details`)}
          </Button>
        </Box.Body>
      </Box>
    );
  }

  handleShowDetailsClick(event) {
    event.preventDefault();
    this.setState({ detailsShown: true });
  }

  render() {
    const { detailsShown } = this.state;
    if (!detailsShown) {
      return this.renderShowDetailsTrigger();
    }

    const { dataSource, job } = this.props;
    const { headerColumns } = job;
    const { fetched, fetchFailed, data: preprocessedData } = this.state;

    let message = null;
    let data = null;
    if (fetchFailed) {
      message = (
        <a href='' onClick={this.handleRetry}>
          {I18n.t(`${scope}.message.fetchFailed`)}
        </a>
      );
    } else if (!fetched) {
      message = I18n.t(`${scope}.message.fetching`);
    } else {
      data = preprocessedData.map((d, index) => {
        const datarow = [];
        datarow.push(index + 1);
        datarow.push(I18n.t(`${scope}.table.datarow.status.${d.status}`));

        if (job.errorCount > 0) {
          if (d.errors) {
            const details = [];
            d.errors.forEach(({ code, source }) => {
              details.push(
                I18n.t(`${scope}.table.datarow.error.${code}`, { source })
              );
            });
            datarow.push(details.join(''));
          } else {
            datarow.push('&nbsp;');
          }
        }

        d.meta.csv.forEach((value, columnIndex) => {
          if (!d.errors) {
            datarow.push(value);
            return;
          }

          const headerColumn = headerColumns[columnIndex];
          const error = d.errors.find(({ source }) => source == headerColumn);
          if (error) {
            datarow.push(
              I18n.t(`${scope}.table.datarow.data.error`, {
                value: value || '',
              })
            );
            return;
          }
          datarow.push(value);
        });

        return datarow;
      });
    }

    const highlightColumns = [
      ...dataSource.attributeFields.map(
        ({ sourceColumnName }) => sourceColumnName
      ),
    ];

    const columns = [
      null,
      null,
      ...headerColumns.map((columnName) =>
        highlightColumns.includes(columnName)
          ? { className: 'highlight' }
          : null
      ),
    ];
    if (job.errorCount > 0) {
      columns.splice(2, 0, { className: 'text-col fixed-md' });
    }

    return (
      <Box>
        <Box.Body>
          {message ? (
            message
          ) : (
            <DataTable dtOptions={{ columns, data, order: [0, 'asc'] }}>
              <thead>
                <tr>
                  <th>{I18n.t(`${scope}.table.header.rowNumber`)}</th>
                  <th>{I18n.t(`${scope}.table.header.status`)}</th>
                  {job.errorCount > 0 && (
                    <th>{I18n.t(`${scope}.table.header.error`)}</th>
                  )}
                  {headerColumns.map((columnName, index) => (
                    <th key={index}>
                      {highlightColumns.includes(columnName) && (
                        <>
                          <i className='fa fa-key'></i>&nbsp;&nbsp;
                        </>
                      )}
                      {columnName}
                    </th>
                  ))}
                </tr>
              </thead>
            </DataTable>
          )}
        </Box.Body>
      </Box>
    );
  }
}

export default DataListBox;
