import { search } from '../../../../graphql/conditionBuilder';

const scope =
  'frontend.components.molecules.ReactTableDataTableStyled.SearchBuilder.combinator';

const comninators = Object.keys(search).map((operator) => ({
  name: operator,
  label: I18n.t([scope, `${operator}.label`]),
}));

export default comninators;
