import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  title: PropTypes.node,
  thumbnailUrl: PropTypes.string,
  imageUrl: PropTypes.string,
  videoUrl: PropTypes.string,
};

const defaultProps = {};

const renderPreview = ({ thumbnailUrl, imageUrl, videoUrl }) => {
  if (videoUrl && videoUrl.startsWith('https://www.youtube.com/watch?v=')) {
    const youtubeVideoId = videoUrl.substring(32);
    return (
      <div style={{ position: 'relative', paddingBottom: '56.25%' }}>
        <iframe
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          src={`https://www.youtube.com/embed/${youtubeVideoId}`}
          frameBorder='0'
          allow='autoplay; encrypted-media'
          allowfullscreen
        />
      </div>
    );
  }

  if (videoUrl) {
    const poster = imageUrl || thumbnailUrl;
    return (
      <video
        controls
        autoPlay
        src={videoUrl}
        {...(poster ? { poster } : {})}
        style={{ width: '100%' }}
      />
    );
  }

  const mediaUrl = imageUrl || thumbnailUrl;
  if (mediaUrl) {
    <a href={mediaUrl} target='_blank' rel='noreferrer'>
      <img className='img-responsive' src={mediaUrl} />
    </a>;
  }

  return null;
};

const MediaPreviewModal = ({ show, onHide, ...props }) => {
  return (
    <Modal show={show} onHide={onHide} animation={false}>
      <Modal.Header closeButton />
      <Modal.Body>{renderPreview(props)}</Modal.Body>
    </Modal>
  );
};

MediaPreviewModal.propTypes = propTypes;
MediaPreviewModal.defaultProps = defaultProps;

export default MediaPreviewModal;
