/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import DataTableBeLike from '../molecules/DataTableBeLike';
import Project from '../../models/Project';
import CommissionConfig from '../../models/CommissionConfig';
import * as Resource from '../../apis/CommissionConfig';
import { formatNumber } from '../../lib/NumberUtils';
import Actions from './ListTable/Actions';
import * as FlashMessage from '../../lib/FlashMessage';

const scope = 'frontend.components.CommissionConfig.ListTable';

const propTypes = {
  project: PropTypes.exact(Project.propTypes),
  columns: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  columns: ['associatedResource', 'entries', 'actions'],
};

class ListTable extends React.Component {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  constructor(props) {
    super(props);

    this.tableColumns = this.tableColumns.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.refreshList = this.refreshList.bind(this);

    this.state = {
      columns: this.tableColumns(),
      data: [],
      fetching: false,
    };
  }

  tableColumns() {
    const { columns } = this.props;
    return columns.reduce((tableColumns, column) => {
      switch (column) {
        case 'associatedResource':
          tableColumns.push({
            header: I18n.t(`${scope}.header.associatedResource`),
            data: (commissionConfig) => commissionConfig.associatedResourceName,
            className: ({ associatedResourceDepth }) =>
              `indent-level-${associatedResourceDepth}`,
          });
          break;
        case 'entries':
          tableColumns.push({
            header: I18n.t(`${scope}.header.entries`),
            data: ({ entries }) => {
              return entries.map(
                ({ commissionPercentage, calcMethod, startDate }, index) => (
                  <div key={index}>
                    {I18n.t(`${scope}.datarow.entries.label/${calcMethod}`, {
                      startDate,
                      commission: formatNumber(commissionPercentage, {
                        precision: 2,
                      }),
                    })}
                  </div>
                )
              );
            },
          });
          break;
        case 'actions':
          tableColumns.push({
            header: I18n.t(`${scope}.header.action`),
            data: (commissionConfig) => (
              <Actions
                commissionConfig={commissionConfig}
                onChange={this.handleChange}
              />
            ),
          });
          break;
      }
      return tableColumns;
    }, []);
  }

  componentDidMount() {
    this.refreshList();
  }

  handleChange(commissionConfig) {
    FlashMessage.removeAll();
    if (commissionConfig.destroyed) {
      FlashMessage.add('success', I18n.t(`${scope}.message.destroyed`));
    } else {
      FlashMessage.add('success', I18n.t(`${scope}.message.saved`));
    }

    this.refreshList();
  }

  refreshList() {
    this.setState({ fetching: true });

    const { project } = this.props;
    Resource.list(project.id)
      .then((response) => {
        const { data } = response;
        this.setState({
          data: data.map((d) => new CommissionConfig(d)),
          fetching: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { columns, data, fetching } = this.state;

    return (
      <DataTableBeLike
        columns={columns}
        data={data}
        loading={fetching}
        emptyDataMessage={I18n.t(`${scope}.message.emptyData`)}
      />
    );
  }
}

export default ListTable;
