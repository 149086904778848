/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import * as BSEx from '../BootstrapExtends';
import Project from '../../models/Project';
import AdAccount from '../../models/AdAccount';
import CsvImportJob from '../../models/CsvImportJob';
import { today, beginningOfYear } from '../../lib/DateUtils';

const scope = 'frontend.components.CsvImportJob.Form';

const propTypes = {
  project: PropTypes.exact(Project.propTypes),
  adAccounts: PropTypes.arrayOf(PropTypes.exact(AdAccount.propTypes)),
  job: PropTypes.exact(CsvImportJob.propTypes),
  onChange: PropTypes.func,
};

class Form extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  handleChange(event) {
    const { job, onChange } = this.props;
    const { name, value } = event.target;
    onChange({ ...job, [name]: value });
  }

  handleNumberChange(event) {
    const { job, onChange } = this.props;
    const { name, value } = event.target;
    onChange({ ...job, [name]: parseInt(value) });
  }

  handleFileChange(event) {
    const { job, onChange } = this.props;
    const { name, files } = event.target;
    onChange({ ...job, [name]: files.item(0) });
  }

  handleCheckboxChange(event) {
    const { job, onChange } = this.props;
    const { name, checked } = event.target;
    onChange({ ...job, [name]: checked });
  }

  render() {
    const { adAccounts, job } = this.props;

    const thresholdDate = (() => {
      const date = beginningOfYear(today());
      date.setFullYear(date.getFullYear() - 4);
      return date;
    })();

    return (
      <>
        <BS.Form>
          <BSEx.FormGroup
            label={I18n.t(`${scope}.field.adAccount.label`)}
            model={job}
            attribute={['adAccountId', 'adAccount']}
          >
            <BS.FormControl
              componentClass='select'
              name='adAccountId'
              value={job.adAccountId || undefined}
              onChange={this.handleNumberChange}
            >
              <option style={{ display: 'none' }}>
                {I18n.t(`${scope}.field.adAccount.placeholder`)}
              </option>
              {adAccounts.map((adAccount, index) => (
                <option key={index} value={adAccount.id}>
                  {adAccount.name}
                </option>
              ))}
            </BS.FormControl>
          </BSEx.FormGroup>
          <BSEx.FormGroup
            label={I18n.t(`${scope}.field.sourceFile.label`)}
            model={job}
            attribute={['sourceFile', 'headerColumns']}
          >
            <BS.FormControl
              type='file'
              name='sourceFile'
              onChange={this.handleFileChange}
            />
            <BS.FormControl.Static
              dangerouslySetInnerHTML={{
                __html: I18n.t(`${scope}.field.sourceFile.disclaimer`, {
                  thresholdDate: I18n.l('date.formats.default', thresholdDate),
                }),
              }}
            />
          </BSEx.FormGroup>
          <BSEx.FormGroup
            label={I18n.t(`${scope}.field.skipPreview.label`)}
            model={job}
            attribute='skipPreview'
          >
            <BS.Checkbox
              checked={job.skipPreview}
              name='skipPreview'
              onChange={this.handleCheckboxChange}
            >
              {I18n.t(`${scope}.field.skipPreview.description`)}
            </BS.Checkbox>
          </BSEx.FormGroup>
          <BSEx.FormGroup
            label={I18n.t(`${scope}.field.beginningCsvLine.label`)}
            model={job}
            attribute='beginningCsvLine'
          >
            <BS.FormControl
              type='number'
              name='beginningCsvLine'
              value={job.beginningCsvLine}
              min={1}
              onChange={this.handleNumberChange}
            />
            <BS.FormControl.Static
              dangerouslySetInnerHTML={{
                __html: I18n.t(`${scope}.field.beginningCsvLine.description`),
              }}
            />
          </BSEx.FormGroup>
          <BSEx.FormGroup
            label={I18n.t(`${scope}.field.encoding.label`)}
            model={job}
            attribute='forceEncoding'
          >
            <BS.FormControl
              componentClass='select'
              name='forceEncoding'
              value={job.encoding}
              onChange={this.handleChange}
            >
              <option value=''>
                {I18n.t(`${scope}.field.encoding.selection.auto_detect`)}
              </option>
              <option value='Windows-31J'>
                {I18n.t(`${scope}.field.encoding.selection.windows_31j`)}
              </option>
              <option value='UTF-8'>
                {I18n.t(`${scope}.field.encoding.selection.utf_8`)}
              </option>
            </BS.FormControl>
          </BSEx.FormGroup>
        </BS.Form>
        <BS.Well
          dangerouslySetInnerHTML={{ __html: I18n.t(`${scope}.disclaimer`) }}
        />
      </>
    );
  }
}

export default Form;
