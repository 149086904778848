import React from 'react';
import { Alert, Row, Col, Button } from 'react-bootstrap';

const scope = 'frontend.components.ErrorBoundary.PleaseTryAgainLater';

const PleaseTryAgainLater = () => {
  const handleReload = (ev) => {
    ev.preventDefault();
    window.location.reload();
  };

  const handleBack = (ev) => {
    ev.preventDefault();
    window.history.back();
  };

  return (
    <Alert bsStyle='warning'>
      <h4>{I18n.t([scope, 'title'])}</h4>
      <p>{I18n.t([scope, 'message'])}</p>
      <p>
        <Row>
          <Col xs={6}>
            <Button bsStyle='default' onClick={handleReload}>
              {I18n.t([scope, 'action.reload'])}
            </Button>
          </Col>
          <Col xs={6} className='text-right'>
            <Button
              bsStyle='link'
              style={{ color: 'inherit' }}
              onClick={handleBack}
            >
              {I18n.t([scope, 'action.back'])}
            </Button>
          </Col>
        </Row>
      </p>
    </Alert>
  );
};

export default PleaseTryAgainLater;
