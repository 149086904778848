/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';

const Header = ({ title, ...props }) => (
  <BS.Modal.Header {...props}>
    <BS.Modal.Title>{title}</BS.Modal.Title>
  </BS.Modal.Header>
);

Header.propTypes = {
  ...BS.Modal.Header.propTypes,
  title: PropTypes.string,
};

Header.defaultProps = BS.Modal.Header.defaultProps;

export default Header;
