/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { FormControl } from 'react-bootstrap';
import { formatDate } from '../../lib/DateUtils';

const propTypes = {
  ...DatePicker.propTypes,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  inputRef: FormControl.propTypes.inputRef,
};

const defaultProps = {
  dateFormat: 'yyyy/MM',
};

// @see https://reactdatepicker.com/#example-month-picker
class MonthSelect extends React.Component {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  render() {
    const { selected, onChange, inputRef, ...props } = this.props;

    return (
      <DatePicker
        showMonthYearPicker
        customInput={<FormControl type='string' inputRef={inputRef} />}
        selected={selected && new Date(selected)}
        onChange={(date) => onChange(formatDate(date))}
        {...props}
      />
    );
  }
}

export default MonthSelect;
