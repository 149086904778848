/* eslint @typescript-eslint/explicit-function-return-type: off */
/* eslint react/display-name: off */

import React from 'react';
import PropTypes from 'prop-types';

const withLauncher = (WrappedContent) => {
  const Launcher = class extends React.Component {
    constructor(props) {
      super(props);

      this.showModal = this.showModal.bind(this);
      this.hideModal = this.hideModal.bind(this);

      const { defaultModalShow } = props;
      this.state = {
        show: defaultModalShow,
      };
    }

    showModal() {
      this.setState({ show: true });
    }

    hideModal() {
      this.setState({ show: false });
    }

    render() {
      const passthroughProps = { ...this.props };
      delete passthroughProps.defaultModalShow;

      return (
        <WrappedContent
          modalState={this.state.show}
          showModal={this.showModal}
          hideModal={this.hideModal}
          {...passthroughProps}
        />
      );
    }
  };

  Launcher.propTypes = {
    defaultModalShow: PropTypes.bool,
    ...WrappedContent.propTypes,
  };

  Launcher.defaultProps = {
    defaultModalShow: false,
    ...WrappedContent.defaultProps,
  };

  return Launcher;
};

withLauncher.propTypes = {
  modalState: PropTypes.bool,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
};

withLauncher.defaultProps = {};

export default withLauncher;
