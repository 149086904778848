import { client } from '../';

const CurrentUserQuery = `
  query($locale: String!) {
    me {
      id email name
      contract {
        status, expiresAt
        plan { id, name(locale: $locale) }
      }
    }
  }
`;

export const queryCurrentUser = async (operationContext = {}) => {
  const locale = I18n.locale;

  const { data, error } = await client
    .query(CurrentUserQuery, { locale }, operationContext)
    .toPromise();
  if (error) {
    throw new Error(error);
  }

  return data.me;
};
