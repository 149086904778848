/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import AdPerformanceReportDataSource from '../../../models/AdPerformanceReportDataSource';
import ExclusionConditionForm from './ExclusionConditionsForm/ExclusionConditionForm';

const scope =
  'frontend.components.AdPerformanceReportDataSource.Form.ExclusionConditionsForm';

const propTypes = {
  dataSource: PropTypes.exact(AdPerformanceReportDataSource.propTypes),
  onChange: PropTypes.func,
};

class ExclusionConditionsForm extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  handleCheckboxChange(event) {
    const { checked } = event.target;
    const { dataSource, onChange } = this.props;
    const exclusionConditions = [];

    if (checked) {
      exclusionConditions.push(dataSource.buildExclusionCondition());
    }

    onChange({ ...dataSource, exclusionConditions });
  }

  handleAdd(event) {
    event.preventDefault();
    const { dataSource, onChange } = this.props;
    const exclusionConditions = dataSource.exclusionConditions.slice();
    exclusionConditions.push(dataSource.buildExclusionCondition());
    onChange({ ...dataSource, exclusionConditions });
  }

  handleChange(index) {
    return (field, event) => {
      event.preventDefault();
      const { dataSource, onChange } = this.props;
      const exclusionConditions = dataSource.exclusionConditions.slice();
      exclusionConditions[index] = field;
      onChange({ ...dataSource, exclusionConditions });
    };
  }

  handleRemove(index) {
    return (event) => {
      event.preventDefault();
      const { dataSource, onChange } = this.props;
      const exclusionConditions = dataSource.exclusionConditions.slice();
      exclusionConditions.splice(index, 1);
      onChange({ ...dataSource, exclusionConditions });
    };
  }

  render() {
    const { dataSource } = this.props;
    const { exclusionConditions } = dataSource;

    return (
      <>
        <BS.Checkbox
          checked={exclusionConditions.length > 0}
          onChange={this.handleCheckboxChange}
        >
          {I18n.t(`${scope}.description`)}
        </BS.Checkbox>

        {exclusionConditions.length > 0 && (
          <>
            <BS.Table responsive striped bordered hover className='form-table'>
              <thead>
                <tr>
                  <th className='fixed-xlarge' colSpan='3'>
                    {I18n.t(`${scope}.table.heading.source`)}
                  </th>
                  <th className='fixed-min' />
                </tr>
              </thead>
              <tbody>
                {exclusionConditions.map((condition, index) => (
                  <ExclusionConditionForm
                    key={index}
                    dataSource={dataSource}
                    condition={condition}
                    onChange={this.handleChange(index)}
                    onRemove={this.handleRemove(index)}
                  />
                ))}
              </tbody>
            </BS.Table>
            <a className='pull-right' href='' onClick={this.handleAdd}>
              <span
                dangerouslySetInnerHTML={{
                  __html: I18n.t(`${scope}.action.add`),
                }}
              ></span>
            </a>
          </>
        )}
      </>
    );
  }
}

export default ExclusionConditionsForm;
