import { stringOperators, numberOperators, enumOperators } from './operators';
import { stringValidator, numberValidator, enumValidator } from './validators';

// GraphQL の search パラメータ値 → react-querybuilder のクエリに変換
export const conditionsToQuery = (conditions, fields) => {
  if (conditions) {
    if ('and' in conditions) {
      return {
        combinator: 'and',
        rules: conditions.and.map((cond) => conditionsToQuery(cond, fields)),
      };
    }

    if ('or' in conditions) {
      return {
        combinator: 'or',
        rules: conditions.or.map((cond) => conditionsToQuery(cond, fields)),
      };
    }

    if (Object.keys(conditions).length) {
      const { field, operator, value } = conditions;
      const { toQueryValue } = fields.find(({ name }) => name == field);

      return {
        field,
        operator,
        value: toQueryValue ? toQueryValue(value) : value,
      };
    }
  }

  return { combinator: 'and', rules: [] };
};

// react-querybuilder のクエリ → GraphQL の search パラメータ値に変換
export const queryToConditions = (query, fields) => {
  if ('combinator' in query) {
    return {
      [query.combinator]: query.rules.map((r) => queryToConditions(r, fields)),
    };
  }

  const { field, operator, value } = query;
  const { toConditionValue } = fields.find(({ name }) => name == field);

  return {
    field,
    operator,
    value: toConditionValue ? toConditionValue(value) : value,
  };
};

// GraphQL の検索条件の条件の件数を返す
export const getConditionsCount = (conditions) => {
  if (conditions) {
    if ('and' in conditions) {
      return conditions.and.reduce((count, cond) => {
        return count + getConditionsCount(cond);
      }, 0);
    }

    if ('or' in conditions) {
      return conditions.or.reduce((count, cond) => {
        return count + getConditionsCount(cond);
      }, 0);
    }

    return 1;
  }

  return 0;
};

export const searchFieldBuilder = {
  string: (extras = {}) => ({
    operators: stringOperators,
    valueEditorType: 'text',
    inputType: 'text',
    validator: stringValidator,
    ...extras,
  }),

  number: (extras = {}) => ({
    operators: numberOperators,
    valueEditorType: 'text',
    inputType: 'number',
    validator: numberValidator,
    ...extras,
  }),

  // マイクロ数値だが、数値として表示するフィールド
  microNumber: (extras = {}) => ({
    operators: numberOperators,
    valueEditorType: 'text',
    inputType: 'number',
    validator: numberValidator,
    toQueryValue: (value) => (value !== '' ? Number(value) / 1000000 : ''),
    toConditionValue: (value) => (value ? value * 1000000 : value),
    ...extras,
  }),

  // 割合の値だが、パーセンテージとして表示するフィールド
  rateAsPercentage: (extras = {}) => ({
    operators: numberOperators,
    valueEditorType: 'text',
    inputType: 'number',
    validator: numberValidator,
    toQueryValue: (value) => (value !== '' ? Number(value) * 100 : ''),
    toConditionValue: (value) => (value ? value / 100 : value),
    ...extras,
  }),

  enum: ({ enums, ...extras } = {}) => ({
    operators: enumOperators,
    valueEditorType: 'checkboxdropdown', // @see ./ValueEditor/CheckboxDropdownEditor.jsx
    values: Object.values(enums).map(({ value, label }) => ({
      name: value,
      label,
    })),
    defaultValue: [],
    validator: enumValidator,
    ...extras,
  }),
};
