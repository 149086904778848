/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import Project from '../../models/Project';
import AdPerformanceReportDataSource from '../../models/AdPerformanceReportDataSource';
import AdPerformanceReportImportJob from '../../models/AdPerformanceReportImportJob';
import * as Resource from '../../apis/AdPerformanceReportImportJob';
import * as Routes from '../../routes/AdPerformanceReportImportJob';
import Form from './Form';

const scope = 'frontend.components.AdPerformanceReportImportJob.FormModal';

const propTypes = {
  project: PropTypes.exact(Project.propTypes),
  dataSource: PropTypes.exact(AdPerformanceReportDataSource.propTypes),
  job: PropTypes.exact(AdPerformanceReportImportJob.propTypes),
  shown: PropTypes.bool,
  onHide: PropTypes.func,
};

class FormModal extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendRequest = this.sendRequest.bind(this);

    const { job } = props;
    this.state = {
      job: job || AdPerformanceReportImportJob.initialProps(),
      submitting: false,
    };
  }

  handleChange(job) {
    this.setState({ job });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ submitting: true });

    return this.sendRequest()
      .then((job) => {
        const { sourceFile } = this.state.job;
        Object.assign(job, { sourceFile });

        this.setState({ job });
        if (job.errors) {
          this.setState({ submitting: false });
          return;
        }

        const { project } = this.props;
        location.href = Routes.detailsPath(project.id, job.id);
      })
      .catch((error) => {
        console.error(error);
        alert(I18n.t(`${scope}.message.error_occurred`));
        this.setState({ submitting: false });
      });
  }

  sendRequest() {
    const { project, dataSource } = this.props;
    const { job: jobParams } = this.state;
    return Resource.create(project.id, {
      ...jobParams,
      dataSourceId: dataSource.id,
    });
  }

  render() {
    const { dataSource, shown, onHide } = this.props;
    const { job: jobProps, submitting } = this.state;

    return (
      <BS.Modal show={shown} onHide={onHide}>
        <BS.Modal.Header closeButton>
          <BS.Modal.Title>{I18n.t(`${scope}.title`)}</BS.Modal.Title>
        </BS.Modal.Header>
        <BS.Modal.Body>
          <Form
            dataSource={dataSource}
            job={new AdPerformanceReportImportJob(jobProps)}
            onChange={this.handleChange}
          />
        </BS.Modal.Body>
        <BS.Modal.Footer>
          <BS.Button onClick={onHide}>
            {I18n.t(`${scope}.action.close`)}
          </BS.Button>
          <BS.Button
            bsStyle='primary'
            onClick={this.handleSubmit}
            disabled={submitting}
          >
            {I18n.t(`${scope}.action.create`)}
          </BS.Button>
        </BS.Modal.Footer>
      </BS.Modal>
    );
  }
}

export default FormModal;
