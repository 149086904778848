/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import * as BSEx from '../../../BootstrapExtends';
import AdPerformanceReportDataSource, {
  ExclusionCondition,
} from '../../../../models/AdPerformanceReportDataSource';

const { MATCHING_RULES } = ExclusionCondition;

const scope =
  'frontend.components.AdPerformanceReportDataSource.Form.ExclusionConditionsForm';

const propTypes = {
  dataSource: PropTypes.exact(AdPerformanceReportDataSource.propTypes),
  condition: PropTypes.exact(ExclusionCondition.propTypes),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

class ExclusionConditionForm extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleMatchingRuleChange = this.handleMatchingRuleChange.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { condition, onChange } = this.props;
    onChange({ ...condition, [name]: value }, event);
  }

  handleMatchingRuleChange(event) {
    const { value: matchingRule } = event.target;
    const { condition: current, onChange } = this.props;

    const condition = new ExclusionCondition({ ...current, matchingRule });
    if (!('sourceColumnValue' in condition.options)) {
      condition.sourceColumnValue = '';
    }

    onChange(condition, event);
  }

  render() {
    const { condition, onRemove } = this.props;
    const { options } = condition;

    return (
      <tr>
        <td>
          <BSEx.FormGroup model={condition} attribute='sourceColumnName'>
            <BS.FormControl
              type='text'
              placeholder={I18n.t(
                `${scope}.field.sourceColumnName.placeholder`
              )}
              name='sourceColumnName'
              value={condition.sourceColumnName}
              onChange={this.handleChange}
            />
          </BSEx.FormGroup>
        </td>
        <td>
          <BSEx.FormGroup model={condition} attribute='matchingRule'>
            <BS.FormControl
              componentClass='select'
              name='matchingRule'
              value={condition.matchingRule}
              onChange={this.handleMatchingRuleChange}
            >
              {MATCHING_RULES.map((matchingRule, index) => (
                <option key={index} value={matchingRule}>
                  {I18n.t(
                    `${scope}.field.matchingRule.selection.${matchingRule}`
                  )}
                </option>
              ))}
            </BS.FormControl>
          </BSEx.FormGroup>
        </td>
        <td>
          {'sourceColumnValue' in options && (
            <BSEx.FormGroup model={condition} attribute='sourceColumnValue'>
              <BS.FormControl
                type='text'
                placeholder={I18n.t(
                  `${scope}.field.sourceColumnValue.placeholder`
                )}
                name='sourceColumnValue'
                value={condition.sourceColumnValue}
                onChange={this.handleChange}
              />
            </BSEx.FormGroup>
          )}
        </td>

        <td>
          {onRemove && (
            <BS.Button bsStyle='danger' onClick={onRemove}>
              <i className='fa fa-trash' />
            </BS.Button>
          )}
        </td>
      </tr>
    );
  }
}

export default ExclusionConditionForm;
