/* eslint @typescript-eslint/explicit-function-return-type: off */

import PropTypes from 'prop-types';
import ApplicationModel from '../ApplicationModel';

const SOURCE_EFFECTIVE_PARTS = ['full', 'url_param_value'];

const TARGET_TABLE_FIELDS = [
  'ad_accounts#remote_id',
  'ad_accounts#name',
  'ad_campaigns#remote_id',
  'ad_campaigns#name',
  'ad_groups#remote_id',
  'ad_groups#name',
  'ads#remote_id',
  'ads#name',
  'ads#cta_link_url',
];

const TARGET_EFFECTIVE_PARTS = ['full', 'url_param_value'];

const MATCHING_RULES = [
  'equals',
  'matches',
  'forward_matches',
  'backward_matches',
];

const propTypes = {
  ...ApplicationModel.propTypes,
  sourceColumnName: PropTypes.string,
  sourceEffectivePart: PropTypes.oneOf(SOURCE_EFFECTIVE_PARTS),
  sourceUrlParamName: PropTypes.string,
  targetTableField: PropTypes.oneOf(TARGET_TABLE_FIELDS),
  targetEffectivePart: PropTypes.oneOf(TARGET_EFFECTIVE_PARTS),
  targetUrlParamName: PropTypes.string,
  matchingRule: PropTypes.oneOf(MATCHING_RULES),
};

const availableTargetTableFieldsFor = ({ breakdown }) => {
  switch (breakdown) {
    case 'ad_account':
      return TARGET_TABLE_FIELDS.filter((f) => /^(ad_accounts)#/.test(f));
    case 'ad_campaign':
      return TARGET_TABLE_FIELDS.filter((f) =>
        /^(ad_accounts|ad_campaigns)#/.test(f)
      );
    case 'ad_group':
      return TARGET_TABLE_FIELDS.filter((f) =>
        /^(ad_accounts|ad_campaigns|ad_groups)#/.test(f)
      );
    case 'ad':
      return TARGET_TABLE_FIELDS;
    default:
      return [];
  }
};

const availableTargetEffectivePartsFor = ({ targetTableField }) => {
  if (!TARGET_TABLE_FIELDS.includes(targetTableField)) {
    return [];
  }

  switch (targetTableField) {
    case 'ads#cta_link_url':
      return TARGET_EFFECTIVE_PARTS;
    default:
      return ['full'];
  }
};

const initialProps = (dataSource) => {
  const targetTableField = availableTargetTableFieldsFor(dataSource)[0];
  const targetEffectivePart = availableTargetEffectivePartsFor({
    targetTableField,
  })[0];

  return {
    sourceColumnName: '',
    sourceEffectivePart: SOURCE_EFFECTIVE_PARTS[0],
    sourceUrlParamName: '',
    targetTableField,
    targetEffectivePart,
    targetUrlParamName: '',
    matchingRule: MATCHING_RULES[0],
  };
};

class AttributeField extends ApplicationModel {
  static SOURCE_EFFECTIVE_PARTS = SOURCE_EFFECTIVE_PARTS;
  static TARGET_TABLE_FIELDS = TARGET_TABLE_FIELDS;
  static TARGET_EFFECTIVE_PARTS = TARGET_EFFECTIVE_PARTS;
  static MATCHING_RULES = MATCHING_RULES;
  static propTypes = propTypes;
  static initialProps = initialProps;
  static availableTargetTableFieldsFor = availableTargetTableFieldsFor;
  static availableTargetEffectivePartsFor = availableTargetEffectivePartsFor;

  get availableTargetEffectiveParts() {
    return availableTargetEffectivePartsFor(this);
  }

  get sourceOptions() {
    switch (this.sourceEffectivePart) {
      case 'url_param_value':
        return { sourceUrlParamName: { isRequired: true } };
      default:
        return {};
    }
  }

  get targetOptions() {
    switch (this.targetEffectivePart) {
      case 'url_param_value':
        return { targetUrlParamName: { isRequired: true } };
      default:
        return {};
    }
  }
}

export default AttributeField;
