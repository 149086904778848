import { client } from '../';

const ConversionPointsQuery = `
  query($search: JSON!, $order: [String!], $page: Int!, $limit: Int!) {
    conversionPoints(
      search: $search
      order: $order
      page: $page
      limit: $limit
    ) {
      data {
        id, name, valueType, dataBindingIndex
        project { id, name }
      }
      paging { nextPage }
    }
  }
`;

export const queryConversionPoints = async (
  variables,
  operationContext = {}
) => {
  const { data, error } = await client
    .query(ConversionPointsQuery, variables, operationContext)
    .toPromise();
  if (error) {
    throw new Error(error);
  }

  return data.conversionPoints;
};
