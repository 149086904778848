import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from '../ErrorBoundary';
import { withLoadingSuspense } from '../LoadingSuspense';
import { withCurrentProjectAccessibility } from '../../contexts';
import {
  withClient,
  queryPeriodBudget,
  mutationUpdatePeriodBudget,
} from '../../graphql';
import { FlowSpinner } from '../atoms/Loading';
import Box from '../atoms/Box';
import { Button, ButtonToolbar } from 'react-bootstrap';
import Form from './Form';
import { omitProps } from '../../lib/objectUtils';
import { registerLocale } from '../../lib/i18n';

registerLocale(I18n.locale);

const scope = 'frontend.components.PeriodBudgetReport.EditFormBox';

const propTypes = {
  callbackUrl: PropTypes.string.isRequired,
  backwardUrl: PropTypes.string.isRequired,
};

const defaultProps = {};

const EditFormBox = ({ callbackUrl, backwardUrl }) => {
  const [error, setError] = useState(null);
  const [periodBudget, setPeriodBudget] = useState(null);
  const [formData, setFormData] = useState(null);

  const matchResult = location.pathname.match(
    /\/projects\/\d+\/period_budget_reports\/(\d+)/
  );
  if (!matchResult) {
    throw new Error('Can not extract PeriodBudget ID: ${matchResult}');
  }

  const [, id] = matchResult;
  useEffect(() => {
    (async () => {
      try {
        const periodBudget = await queryPeriodBudget(id);
        setPeriodBudget(periodBudget);
        setFormData(periodBudget);
      } catch (error) {
        setError(error);
      }
    })();
  }, [id]);

  if (error) {
    throw error;
  }

  if (!periodBudget || !formData) {
    return <FlowSpinner />;
  }

  const handleSubmit = async (formData, actions) => {
    console.log('formData:', formData);

    const { errors } = await mutationUpdatePeriodBudget({
      ...omitProps(formData, 'projectId'),
      id: periodBudget.id,
    });
    if (errors) {
      for (const { field, messages } of errors) {
        actions.setFieldError(field, messages.join('\n'));
      }
      actions.setSubmitting(false);
      return;
    }

    const url = new URL(callbackUrl);
    url.searchParams.set('notify', 'info:updated');
    location.href = url;
  };

  return (
    <Form initialValues={formData} onSubmit={handleSubmit}>
      {({ formik: { isSubmitting }, formFields }) => (
        <Box>
          <Box.Body>{formFields}</Box.Body>
          <Box.Footer>
            <ButtonToolbar>
              <Button type='submit' bsStyle='primary' disabled={isSubmitting}>
                {I18n.t([scope, 'action.update'])}
              </Button>
              <Button
                bsStyle='default'
                onClick={() => (location.href = backwardUrl)}
              >
                {I18n.t([scope, 'action.cancel'])}
              </Button>
            </ButtonToolbar>
          </Box.Footer>
        </Box>
      )}
    </Form>
  );
};

EditFormBox.propTypes = propTypes;
EditFormBox.defaultProps = defaultProps;

export default withErrorBoundary(
  withLoadingSuspense(withClient(withCurrentProjectAccessibility(EditFormBox)))
);
