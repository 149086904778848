import PropTypes from 'prop-types';
import ApplicationModel from './ApplicationModel';

const propTypes = {
  ...ApplicationModel.propTypes,
  projectId: PropTypes.number,
  channel: PropTypes.string,
  remoteId: PropTypes.string,
  internalId: PropTypes.string,
  active: PropTypes.bool,
  isAutoFetchCampaigns: PropTypes.bool,
  importStatus: PropTypes.string,
  name: PropTypes.string,
  businessName: PropTypes.string,
  country: PropTypes.string,
  timezone: PropTypes.string,
  fundingSource: PropTypes.string,
  totalSpent: PropTypes.number,
  currency: PropTypes.string,
  remoteStatus: PropTypes.string,
  // token: PropTypes.string,
  // tokenSecret: PropTypes.string,
  // encryptedCredential: PropTypes.string,
  // credentialSalt: PropTypes.string,
  tokenErrorType: PropTypes.string,
  tokenErrorDetails: PropTypes.string,
  startFrom: PropTypes.string,
};

class AdAccount extends ApplicationModel {
  static propTypes = propTypes;
}

export default AdAccount;
