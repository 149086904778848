import React, { createRef, useEffect } from 'react';
import {
  withClient,
  queryProjectAccessibilities,
  conditionBuilder,
} from '../graphql';
const { search, filter } = conditionBuilder;
import { withCurrentProjectAccessibility } from '../contexts';
import { useCurrentProjectAccessibility } from '../hooks';
import $ from 'window-jquery';
import I18n from 'window-i18n';

const propTypes = {};
const defaultProps = {};

const select2Options = {
  dropdownAutoWidth: true,
  placeholder: I18n.t('frontend.components.ProjectSelectDropdown.placeholder'),
  ajax: {
    delay: 500,
    transport: async (params, success, failure) => {
      const { page, term } = params.data;

      const searchConds = [filter.eq('project.deletedAt', null)];
      if (term) {
        for (const word of term.split(/\s/)) {
          if (word.length == 0) continue;
          searchConds.push(filter.match('project.name', word));
        }
      }

      try {
        const {
          data,
          paging: { nextPage },
        } = await queryProjectAccessibilities({
          page: page || 1,
          search: search.and(searchConds),
        });

        success({
          results: data.map((projectAccessibility) => {
            const { id, name: text } = projectAccessibility.project;
            return { id, text, projectAccessibility };
          }),
          pagination: { more: !!nextPage },
        });
      } catch (error) {
        failure(error);
      }
    },
  },
};

const ProjectSelectDropdown = (_) => {
  const selectRef = createRef(null);
  const { accessibility } = useCurrentProjectAccessibility();
  const project = accessibility?.project;

  const handleSelect = (ev) => {
    const { projectAccessibility } = ev.params.data;
    location.href = projectAccessibility.project.url;
  };

  useEffect(() => {
    const select2 = $(selectRef.current).select2(select2Options);
    select2.on('select2:select', handleSelect);
  }, []);

  return (
    <select className='form-control' ref={selectRef}>
      {project && <option>{project.name}</option>}
    </select>
  );
};

ProjectSelectDropdown.propTypes = propTypes;
ProjectSelectDropdown.defaultProps = defaultProps;

export default withClient(
  withCurrentProjectAccessibility(ProjectSelectDropdown)
);
