/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';

const DescriptionPanel = ({ title, innerHTML }) => (
  <div className='description-panel'>
    {title && <p className='description-panel-title'>{title}</p>}
    {innerHTML && <div dangerouslySetInnerHTML={{ __html: innerHTML }} />}
  </div>
);

DescriptionPanel.propTypes = {
  title: PropTypes.string,
  innerHTML: PropTypes.string,
};

export default DescriptionPanel;
