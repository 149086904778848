import React, { useId, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, MenuItem } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { addMonths } from '../../../lib/DateUtils';
import I18n from 'window-i18n';

const scope =
  'frontend.components.PeriodBudgetReport.IndexPageContent.PeriodSelectDropdown';

const propTypes = {
  selected: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  pullRight: PropTypes.bool,
  disabled: PropTypes.bool,
};

const defaultProps = {
  pullRight: false,
  disabled: false,
};

const PeriodSelectDropdown = ({ selected, onChange, pullRight, disabled }) => {
  const id = useId();
  const [open, setOpen] = useState(false);

  const handleChange = (date) => {
    onChange && onChange(date);
    setOpen(false); // 選択したらドロップダウンを閉じる
  };

  return (
    <Dropdown {...{ id, open, pullRight, disabled }} onToggle={setOpen}>
      <Dropdown.Toggle>
        <i className='fa fa-calendar' />{' '}
        {I18n.t([scope, 'label'], {
          month: I18n.l('date.formats.month.long', selected),
        })}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <li>
          <a onClick={() => handleChange(new Date())}>
            {I18n.t([scope, 'selection.thisMonth'])}
          </a>
        </li>
        <li>
          <a onClick={() => handleChange(addMonths(new Date(), -1))}>
            {I18n.t([scope, 'selection.lastMonth'])}
          </a>
        </li>
        <li>
          <a onClick={() => handleChange(addMonths(selected, -1))}>
            {I18n.t([scope, 'selection.prevMonth'])}
          </a>
        </li>
        <li>
          <a onClick={() => handleChange(addMonths(selected, 1))}>
            {I18n.t([scope, 'selection.nextMonth'])}
          </a>
        </li>
        <MenuItem divider />
        <li style={{ padding: '0 8px' }}>
          <DatePicker
            showMonthYearPicker
            inline
            selected={selected}
            onChange={handleChange}
            locale={I18n.locale}
          />
        </li>
      </Dropdown.Menu>
    </Dropdown>
  );
};

PeriodSelectDropdown.propTypes = propTypes;
PeriodSelectDropdown.defaultProps = defaultProps;

export default PeriodSelectDropdown;
