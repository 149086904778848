/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import * as BS from 'react-bootstrap';

const Footer = ({ children, ...props }) => (
  <BS.Modal.Footer {...props}>{children}</BS.Modal.Footer>
);

Footer.propTypes = BS.Modal.Footer.propTypes;
Footer.defaultProps = BS.Modal.Footer.defaultProps;

export default Footer;
