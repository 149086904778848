/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import Box from '../atoms/Box';
import ListTable from './ListTable';

const scope = 'frontend.components.AdPerformanceReportImportJob.ListBox';

const propTypes = {
  ...ListTable.propTypes,
};

class ListBox extends React.Component {
  static propTypes = propTypes;

  render() {
    return (
      <Box>
        <Box.Header>
          <Box.Title>{I18n.t(`${scope}.title`)}</Box.Title>
        </Box.Header>
        <Box.Body>
          <ListTable {...this.props} />
        </Box.Body>
      </Box>
    );
  }
}

export default ListBox;
