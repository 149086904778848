import PropTypes from 'prop-types';
import CarrierWaveResource from './ApplicationModel/CarrierWaveResource';

const propTypes = {
  id: PropTypes.number,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  validated: PropTypes.bool,
  destroyed: PropTypes.bool,
  errors: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.exact({
        error: PropTypes.string,
        message: PropTypes.string,
      })
    )
  ),
};

class ApplicationModel {
  static propTypes = propTypes;

  constructor(props) {
    Object.keys(props).forEach((propName) => {
      this[propName] = props[propName];
    });
  }
}

export default ApplicationModel;
export { CarrierWaveResource };
