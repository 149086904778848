import PropTypes from 'prop-types';

const propTypes = {
  url: PropTypes.string,
};

class CarrierWaveResource {
  static propTypes = propTypes;

  constructor(props) {
    Object.keys(props).forEach((propName) => {
      this[propName] = props[propName];
    });
  }
}

export default CarrierWaveResource;
