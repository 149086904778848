/* eslint @typescript-eslint/explicit-function-return-type: off */

import PropTypes from 'prop-types';

const propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.exact({
      header: PropTypes.string.isRequired,
      headerClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      data: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    })
  ),
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  limitSelections: PropTypes.arrayOf(PropTypes.number),
  limit: PropTypes.number,
  pagingLeft: PropTypes.number,
  pagingRight: PropTypes.number,
  pagingWindow: PropTypes.number,
  onPageChange: PropTypes.func,
  onLimitChange: PropTypes.func,
  emptyDataMessage: PropTypes.string,
};

const defaultProps = {
  columns: [],
  data: [],
  loading: false,
  totalPages: 0,
  currentPage: 1,
  limitSelections: [10, 25, 50, 100],
  limit: 10,
  pagingLeft: 1,
  pagingRight: 1,
  pagingWindow: 2,
  emptyDataMessage: 'No data available in table',
};

export { propTypes, defaultProps };
