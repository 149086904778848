import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactTableContext } from '../../../../contexts';
import { useDrag, useDrop } from 'react-dnd';

const propTypes = {
  header: PropTypes.any,
  disabled: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
};

const reorderColumn = (draggedColumnId, targetColumnId, columnOrder) => {
  console.log('reorderColumn:', draggedColumnId, targetColumnId, columnOrder);
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0]
  );
  return [...columnOrder];
};

const DraggableColumnHeader = ({ header, disabled, ...otherProps }) => {
  const { getState, setState, getAllLeafColumns } =
    useContext(ReactTableContext);

  const { column } = header;

  const currentColumnOrder = (() => {
    const columnOrder = getState().columnOrder?.filter((v) => !!v);
    return columnOrder?.length
      ? columnOrder
      : getAllLeafColumns().map(({ id }) => id);
  })();

  const [, dropRef] = useDrop({
    accept: 'column',
    drop: (draggedColumn) => {
      const newColumnOrder = reorderColumn(
        draggedColumn.id,
        column.id,
        currentColumnOrder
      );
      setState((prev) => ({ ...prev, columnOrder: newColumnOrder }));
    },
    canDrop: (_item, _monitor) => !disabled,
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: 'column',
    canDrag: (_monitor) => !disabled,
  });

  const { children, ...props } = otherProps;
  return (
    <th
      ref={dropRef}
      colSpan={header.colSpan}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      {...props}
    >
      <div ref={previewRef}>
        <i ref={dragRef} className='table-column-dnd-handle fa fa-bars' />
        {children}
      </div>
    </th>
  );
};

DraggableColumnHeader.propTypes = propTypes;
DraggableColumnHeader.defaultProps = defaultProps;

export default DraggableColumnHeader;
