/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../atoms/Table';
import EntryForm from './EntriesForm/EntryForm';
import ActionLink from '../../atoms/ActionLink';
import CommissionConfig, { Entry } from '../../../models/CommissionConfig';

const scope = 'frontend.components.CommissionConfig.Form.EntriesForm';

const propTypes = {
  commissionConfig: PropTypes.exact(CommissionConfig.propTypes),
  onChange: PropTypes.func,
};

class EntriesForm extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  handleChange(index) {
    return (entry) => {
      const { commissionConfig, onChange } = this.props;
      const entries = commissionConfig.entries.slice();
      entries[index] = entry;
      onChange({ ...commissionConfig, entries });
    };
  }

  handleAdd() {
    const { commissionConfig, onChange } = this.props;
    const entries = commissionConfig.entries.slice();
    entries.push(Entry.initialProps());
    onChange({ ...commissionConfig, entries });
  }

  handleRemove(index) {
    return () => {
      const { commissionConfig, onChange } = this.props;
      const entries = commissionConfig.entries.slice();
      entries.splice(index, 1);
      onChange({ ...commissionConfig, entries });
    };
  }

  render() {
    const { commissionConfig } = this.props;
    const { entries } = commissionConfig;

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>{I18n.t(`${scope}.field.startDate.label`)}</th>
              <th>{I18n.t(`${scope}.field.commissionPercentage.label`)}</th>
              <th>{I18n.t(`${scope}.field.calcMethod.label`)}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {entries.map((entry, index) => (
              <EntryForm
                key={index}
                commissionConfig={commissionConfig}
                entry={entry}
                onChange={this.handleChange(index)}
                onRemove={this.handleRemove(index)}
              />
            ))}
          </tbody>
        </Table>
        <div className='text-right'>
          <ActionLink
            onClick={this.handleAdd}
            dangerouslySetInnerHTML={{
              __html: I18n.t(`${scope}.action.add`),
            }}
          />
        </div>
      </>
    );
  }
}

export default EntriesForm;
