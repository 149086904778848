/* eslint @typescript-eslint/explicit-function-return-type: off */

import PropTypes from 'prop-types';
import ApplicationModel from './ApplicationModel';
import AttributeField from './AdPerformanceReportDataSource/AttributeField';
import SegmentField from './AdPerformanceReportDataSource/SegmentField';
import MetricField from './AdPerformanceReportDataSource/MetricField';
import ExclusionCondition from './AdPerformanceReportDataSource/ExclusionCondition';

const DATA_SOURCE_TYPES = ['converted_events', 'aggregated_conversions'];

const BREAKDOWNS = [
  // 'project',
  // 'channel',
  'ad_account',
  'ad_campaign',
  'ad_group',
  'ad',
];

const LEVELS = [
  // 'hourly',
  'daily',
  // 'weekly',
  // 'monthly',
  // 'seven_days_average',
  // 'fourteen_days_average',
  // 'thirty_days_average',
];

// 処理可能なアップロードされたファイルの最大行数
const MAX_UPLOADED_CONVERTED_EVENTS_FILE_LINE_COUNT = 40_000; // 成果一覧データ
const MAX_UPLOADED_AGGREGATED_CONVERSIONS_FILE_LINE_COUNT = 20_000; // 集計データ

const propTypes = {
  ...ApplicationModel.propTypes,
  projectId: PropTypes.number,
  name: PropTypes.string,
  dataSourceType: PropTypes.oneOf(DATA_SOURCE_TYPES),
  breakdown: PropTypes.oneOf(BREAKDOWNS),
  level: PropTypes.oneOf(LEVELS),
  assignSourceDateOnUpload: PropTypes.bool,
  attributeFields: PropTypes.arrayOf(PropTypes.exact(AttributeField.propTypes)),
  segmentFields: PropTypes.arrayOf(PropTypes.exact(SegmentField.propTypes)),
  metricFields: PropTypes.arrayOf(PropTypes.exact(MetricField.propTypes)),
  exclusionConditions: PropTypes.arrayOf(
    PropTypes.exact(ExclusionCondition.propTypes)
  ),
  deletedAt: PropTypes.string,
};

const initialProps = () => {
  const dataSource = {
    name: '',
    dataSourceType: DATA_SOURCE_TYPES[0],
    breakdown: BREAKDOWNS[0],
    level: LEVELS[0],
    assignSourceDateOnUpload: false,
  };

  return {
    ...dataSource,
    attributeFields: [AttributeField.initialProps(dataSource)],
    segmentFields: [SegmentField.initialProps(dataSource)],
    metricFields: [MetricField.initialProps(dataSource)],
    exclusionConditions: [],
  };
};

class AdPerformanceReportDataSource extends ApplicationModel {
  static DATA_SOURCE_TYPES = DATA_SOURCE_TYPES;
  static BREAKDOWNS = BREAKDOWNS;
  static LEVELS = LEVELS;
  static propTypes = propTypes;
  static initialProps = initialProps;

  constructor(props) {
    super(props);

    if (this.attributeFields) {
      this.attributeFields = this.attributeFields.map(
        (f) => new AttributeField(f)
      );
    }

    if (this.segmentFields) {
      this.segmentFields = this.segmentFields.map((f) => new SegmentField(f));
    }

    if (this.metricFields) {
      this.metricFields = this.metricFields.map((f) => new MetricField(f));
    }

    if (this.exclusionConditions) {
      this.exclusionConditions = this.exclusionConditions.map(
        (f) => new ExclusionCondition(f)
      );
    }
  }

  get options() {
    switch (this.dataSourceType) {
      case 'aggregated_conversions':
        return { assignSourceDateOnUpload: { isRequired: false } };
      default:
        return {};
    }
  }

  get availableAttributeFieldTargetTableFields() {
    return AttributeField.availableTargetTableFieldsFor(this);
  }

  get availableSegmentFieldSourceDataTypes() {
    return SegmentField.availableSourceDataTypesFor(this);
  }

  get availableMetricFieldSourceDataTypes() {
    return MetricField.availableSourceDataTypesFor(this);
  }

  get minAttributeFieldCount() {
    return 1;
  }

  get maxAttributeFieldCount() {
    return 10;
  }

  get minSegmentFieldCount() {
    return 1;
  }

  get maxSegmentFieldCount() {
    return 1;
  }

  get minMetricFieldCount() {
    return 1;
  }

  get maxMetricFieldCount() {
    return 20;
  }

  get maxUploadedFileLineCount() {
    switch (this.dataSourceType) {
      case 'converted_events':
        return MAX_UPLOADED_CONVERTED_EVENTS_FILE_LINE_COUNT;
      case 'aggregated_conversions':
        return MAX_UPLOADED_AGGREGATED_CONVERSIONS_FILE_LINE_COUNT;
      default:
        return 0;
    }
  }

  buildAttributeField() {
    return new AttributeField(AttributeField.initialProps(this));
  }

  buildSegmentField() {
    return new SegmentField(SegmentField.initialProps(this));
  }

  buildMetricField() {
    return new MetricField(MetricField.initialProps(this));
  }

  buildExclusionCondition() {
    return new ExclusionCondition(ExclusionCondition.initialProps());
  }
}

export default AdPerformanceReportDataSource;
export { AttributeField, SegmentField, MetricField, ExclusionCondition };
