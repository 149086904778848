/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import ActionLink from '../../atoms/ActionLink';
import Modal, { withLauncher } from '../../atoms/Modal';
import CommissionConfig, { Entry } from '../../../models/CommissionConfig';
import * as Resource from '../../../apis/CommissionConfig';
import Form from '../Form';

const scope = 'frontend.components.CommissionConfig.ListTable';

const propTypes = {
  commissionConfig: PropTypes.exact(CommissionConfig.propTypes),
  onChange: PropTypes.func,
  ...withLauncher.propTypes,
};

class Actions extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.initConfigParams = this.initConfigParams.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDestroy = this.handleDestroy.bind(this);
    this.createConfig = this.createConfig.bind(this);
    this.updateConfig = this.updateConfig.bind(this);
    this.destroyConfig = this.destroyConfig.bind(this);

    this.state = {
      configParams: null, // モーダルを開くとたびに初期化する
      submitting: false,
    };
  }

  initConfigParams() {
    const { commissionConfig } = this.props;

    const entries = commissionConfig.entries.slice();
    if (entries.length == 0) {
      entries.push(Entry.initialProps());
    }

    return { ...commissionConfig, entries };
  }

  showModal() {
    const { showModal } = this.props;

    // モーダルを開く前にステートを初期化
    this.setState({ configParams: this.initConfigParams() });

    showModal();
  }

  handleChange(configParams) {
    const entries = configParams.entries.slice();
    if (entries.length == 0) {
      entries.push(Entry.initialProps());
    }

    this.setState({ configParams: { ...configParams, entries } });
  }

  handleSubmit(callApi) {
    const { onChange, hideModal } = this.props;

    return (event) => {
      if (this.state.submitting) {
        return false;
      }

      event.preventDefault();
      this.setState({ submitting: true });

      return callApi()
        .then((commissionConfig) => {
          this.setState({ configParams: commissionConfig, submitting: false });
          if (commissionConfig.errors) {
            return;
          }

          hideModal();
          onChange(commissionConfig);
        })
        .catch((error) => {
          console.error(error);
          alert(I18n.t(`${scope}.message.errorOccurred`));
          this.setState({ submitting: false });
        });
    };
  }

  handleDestroy(event) {
    if (!confirm(I18n.t(`${scope}.message.confirmDestroy`))) {
      return false;
    }

    return this.handleSubmit(this.destroyConfig)(event);
  }

  createConfig() {
    const { projectId } = this.props.commissionConfig;
    const { configParams } = this.state;
    return Resource.create(projectId, configParams);
  }

  updateConfig() {
    const { projectId, id: configId } = this.props.commissionConfig;
    const { configParams } = this.state;
    return Resource.update(projectId, configId, configParams);
  }

  destroyConfig() {
    const { projectId, id: configId } = this.props.commissionConfig;
    return Resource.destroy(projectId, configId);
  }

  render() {
    const { commissionConfig, modalState, hideModal } = this.props;
    const { configParams, submitting } = this.state;

    return (
      <>
        <ActionLink onClick={this.showModal} disabled={submitting}>
          {I18n.t(`${scope}.datarow.action.edit`)}
        </ActionLink>

        <Modal show={modalState} onHide={hideModal}>
          <Modal.Header title={I18n.t(`${scope}.modal.title`)} />
          <Modal.Body>
            <Form
              commissionConfig={configParams}
              onChange={this.handleChange}
            />
          </Modal.Body>
          <Modal.Footer>
            {commissionConfig.id && (
              <BS.Button
                bsStyle='danger'
                onClick={this.handleDestroy}
                disabled={submitting}
              >
                {I18n.t(`${scope}.modal.action.destroy`)}
              </BS.Button>
            )}
            <BS.Button onClick={hideModal}>
              {I18n.t(`${scope}.modal.action.close`)}
            </BS.Button>
            <BS.Button
              bsStyle='primary'
              onClick={this.handleSubmit(
                commissionConfig.id ? this.updateConfig : this.createConfig
              )}
              disabled={submitting}
            >
              {I18n.t(`${scope}.modal.action.save`)}
            </BS.Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withLauncher(Actions);
