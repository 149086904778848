/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import * as BSEx from '../../../BootstrapExtends';
import AdPerformanceReportDataSource, {
  SegmentField,
} from '../../../../models/AdPerformanceReportDataSource';
import AdvancedSourceOptionsFormModal from './AdvancedSourceOptionsFormModal';

const { TARGET_TABLE_FIELDS } = SegmentField;

const scope =
  'frontend.components.AdPerformanceReportDataSource.Form.SegmentFieldsForm';

const propTypes = {
  dataSource: PropTypes.exact(AdPerformanceReportDataSource.propTypes),
  field: PropTypes.exact(SegmentField.propTypes),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

class SegmentFieldForm extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.advancedOptions = this.advancedOptions.bind(this);

    this.state = {
      modalShown: false,
    };
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { field, onChange } = this.props;
    onChange({ ...field, [name]: value }, event);
  }

  toggleModal(modalShown) {
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      this.setState({ modalShown });
    };
  }

  advancedOptions() {
    const { field } = this.props;
    const { sourceOptions } = field;

    return Object.keys(sourceOptions).reduce((advancedOptions, key) => {
      const sourceOption = sourceOptions[key];
      if (!sourceOption.isRequired) {
        advancedOptions[key] = sourceOption;
      }

      return advancedOptions;
    }, {});
  }

  render() {
    const { dataSource, field, onChange, onRemove } = this.props;
    const { modalShown } = this.state;

    const sourceDataTypes = dataSource.availableSegmentFieldSourceDataTypes;
    const advancedOptions = this.advancedOptions();

    return (
      <tr>
        <td>
          <BSEx.FormGroup model={field} attribute='sourceColumnName'>
            <BS.FormControl
              type='text'
              placeholder={I18n.t(
                `${scope}.field.sourceColumnName.placeholder`
              )}
              name='sourceColumnName'
              value={field.sourceColumnName}
              onChange={this.handleChange}
            />
          </BSEx.FormGroup>
        </td>
        <td>
          <BSEx.FormGroup model={field} attribute='sourceDataType'>
            <BS.FormControl
              componentClass='select'
              name='sourceDataType'
              value={field.sourceDataType}
              onChange={this.handleChange}
            >
              {sourceDataTypes.map((dataTtpe, index) => (
                <option key={index} value={dataTtpe}>
                  {I18n.t(
                    `${scope}.field.sourceDataType.selection.${dataTtpe}`
                  )}
                </option>
              ))}
            </BS.FormControl>
          </BSEx.FormGroup>
        </td>
        <td>
          {Object.keys(advancedOptions).length > 0 && (
            <BSEx.FormGroup>
              <BS.FormControl.Static>
                <a href='' onClick={this.toggleModal(true)}>
                  {I18n.t(`${scope}.action.configureAdvancedOptions`)}
                </a>
                <AdvancedSourceOptionsFormModal
                  field={field}
                  shown={modalShown}
                  onChange={onChange}
                  onHide={this.toggleModal(false)}
                />
              </BS.FormControl.Static>
            </BSEx.FormGroup>
          )}
        </td>

        <td>
          <BSEx.FormGroup model={field} attribute='targetTableField'>
            <BS.FormControl
              componentClass='select'
              name='targetTableField'
              value={field.targetTableField}
              onChange={this.handleChange}
            >
              {TARGET_TABLE_FIELDS.map((tableField, index) => (
                <option key={index} value={tableField}>
                  {I18n.t(
                    `${scope}.field.targetTableField.selection.${tableField}`
                  )}
                </option>
              ))}
            </BS.FormControl>
          </BSEx.FormGroup>
        </td>

        <td>
          {onRemove && (
            <BS.Button bsStyle='danger' onClick={onRemove}>
              <i className='fa fa-trash' />
            </BS.Button>
          )}
        </td>
      </tr>
    );
  }
}

export default SegmentFieldForm;
