import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCurrentProjectAccessibility } from '../../../../../hooks';
import { connect } from 'formik';
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Button,
} from 'react-bootstrap';
import {
  floorForCurrency,
  formatCurrency,
} from '../../../../../lib/NumberUtils';

const scope =
  'frontend.components.PeriodBudgetReport.Form.FormFields.DailyAmountsField.EqualAllocateAmountModal';

const propTypes = {
  formik: PropTypes.object,
  children: PropTypes.func,
};

const defaultProps = {};

const EqualAllocateAmountModal = ({
  formik: {
    values: { dailyAmounts },
    setFieldValue,
  },
  children,
}) => {
  const {
    accessibility: { project },
  } = useCurrentProjectAccessibility();

  const [show, setShow] = useState(false);
  const [totalAmount, setTotalAmount] = useState('');

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  const isValid = !!totalAmount;
  const amount = isValid
    ? floorForCurrency(totalAmount / dailyAmounts.length, project.currency)
    : '';

  const handleSubmit = () => {
    setFieldValue(
      'dailyAmounts',
      dailyAmounts.map((dailyAmount) => ({
        ...dailyAmount,
        amount,
      }))
    );
    hideModal();
  };

  return (
    <>
      {children({ showModal, hideModal })}
      <Modal {...{ show, onHide: hideModal }}>
        <Modal.Header closeButton>
          {I18n.t([scope, 'header.title'])}
        </Modal.Header>
        <Modal.Body>
          <p>{I18n.t([scope, 'body.description'])}</p>
          <FormGroup>
            <ControlLabel>
              {I18n.t([scope, 'body.form.field.totalAmount.label'])}
            </ControlLabel>
            <FormControl
              type='number'
              value={totalAmount}
              onChange={({ target: { value } }) =>
                setTotalAmount(value ? Number(value) : '')
              }
            />
            {totalAmount && (
              <HelpBlock>
                {I18n.t([scope, 'body.form.field.totalAmount.help'], {
                  amount: formatCurrency(amount, project.currency),
                })}
              </HelpBlock>
            )}
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle='primary' disabled={!isValid} onClick={handleSubmit}>
            {I18n.t([scope, 'footer.button.submit.label'])}
          </Button>
          <Button onClick={hideModal}>
            {I18n.t([scope, 'footer.button.close.label'])}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

EqualAllocateAmountModal.propTypes = propTypes;
EqualAllocateAmountModal.defaultProps = defaultProps;

export default connect(EqualAllocateAmountModal);
