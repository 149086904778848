import React from 'react';
import PropTypes from 'prop-types';
import { connect, getIn } from 'formik';
import { FormGroup } from 'react-bootstrap';

const propTypes = {
  formik: PropTypes.object,
  name: PropTypes.string,
};

const defaultProps = {};

const ReactBootstrapFormikFormGroup = ({ formik, name, ...props }) => {
  const touched = name && getIn(formik.touched, name);
  const error = name && getIn(formik.errors, name);

  const validationState = touched ? (error ? 'error' : 'success') : null;

  return <FormGroup validationState={validationState} {...props} />;
};

ReactBootstrapFormikFormGroup.propTypes = propTypes;
ReactBootstrapFormikFormGroup.defaultProps = defaultProps;

export default connect(ReactBootstrapFormikFormGroup);
