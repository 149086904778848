import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactTableContext } from '../../../contexts';
import { FormControl } from 'react-bootstrap';

const pageSizeList = [10, 25, 50, 100];

const propTypes = {
  pageSizeList: PropTypes.arrayOf(PropTypes.number),
  disabled: PropTypes.bool,
};

const defaultProps = {
  pageSizeList,
  disabled: false,
};

const PageLength = ({ pageSizeList, disabled }) => {
  const { getState, setPageSize } = useContext(ReactTableContext);

  return (
    <label>
      表示件数:{' '}
      <FormControl
        componentClass='select'
        bsSize='sm'
        defaultValue={getState().pagination.pageSize}
        onChange={(ev) => setPageSize(Number(ev.target.value))}
        disabled={disabled}
      >
        {pageSizeList.map((len, index) => (
          <option value={len} key={index}>
            {len}
          </option>
        ))}
      </FormControl>
    </label>
  );
};

PageLength.propTypes = propTypes;
PageLength.defaultProps = defaultProps;

export default PageLength;
