/* eslint @typescript-eslint/explicit-function-return-type: off */

export const indexPath = (projectId) =>
  projectId
    ? `/projects/${projectId}/budget_reports`
    : '/projects/budget_reports';

export const setBudgetPath = (projectId) =>
  `/projects/${projectId}/budget_reports/budget`;

export const copyBudgetsPath = (projectId) =>
  `/projects/${projectId}/budget_reports/budgets`;
