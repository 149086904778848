import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import FormFields from './FormFields';
import {
  RESOURCE_COUNT_LIMIT,
  DAYS_LIMIT_FOR_DATE_RANGE_MODE,
  DAYS_LIMIT_FOR_DATE_SELECT_MODE,
} from '../Form';
import { omitTypename } from '../../../graphql';

export const scope = 'frontend.components.PeriodBudgetReport.Form';

const propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  children: PropTypes.func,
};

const defaultProps = {
  initialValues: {
    projectId: '',
    name: '',
    dateSelectionMode: 'date_range',
    dailyAmounts: [],
    resources: [],
  },
};

const FormComponent = ({ initialValues, onSubmit, children }) => {
  const validationSchema = Yup.object({
    name: Yup.string().required(),
    dateSelectionMode: Yup.mixed().oneOf(['date_range', 'date_select']),
    dailyAmounts: Yup.array()
      .of(
        Yup.object({
          scheduledDate: Yup.string()
            .matches(/\d{4}-\d{2}-\d{2}/)
            .required(),
          amount: Yup.number().required().positive(),
        })
      )
      .compact()
      .min(1, I18n.t([scope, 'field.dailyAmounts.errors.min']))
      .when('dateSelectionMode', (dateSelectionMode, schema) =>
        dateSelectionMode == 'date_range'
          ? schema.max(DAYS_LIMIT_FOR_DATE_RANGE_MODE)
          : schema.max(DAYS_LIMIT_FOR_DATE_SELECT_MODE)
      )
      .required(),
    resources: Yup.array()
      .of(
        Yup.object({
          resourceType: Yup.string().required(),
          resourceId: Yup.string().required(),
          channelId: Yup.string().nullable(),
          adAccountId: Yup.string().nullable(),
          adCampaignId: Yup.string().nullable(),
          adGroupId: Yup.string().nullable(),
          adId: Yup.string().nullable(),
        })
      )
      .compact()
      .min(1, I18n.t([scope, 'field.resources.errors.min']))
      .max(RESOURCE_COUNT_LIMIT)
      .required(),
  });

  const normalizedInitialValues = omitTypename(initialValues);
  for (const [key, value] of Object.entries(defaultProps.initialValues)) {
    normalizedInitialValues[key] = normalizedInitialValues[key] || value;
  }

  return (
    <Formik
      initialValues={normalizedInitialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          {children({
            formik,
            formFields: <FormFields />,
          })}
        </Form>
      )}
    </Formik>
  );
};

FormComponent.propTypes = propTypes;
FormComponent.defaultProps = defaultProps;

export default FormComponent;
