import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  placement: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.element,
};

const defaultProps = {
  placement: 'top',
  children: <i className='fa fa-question-circle' />,
};

// react-bootstrap には OverlayTrigger や Tooltip が用意されているが、
// React 18 だと以下の Warning が発生するので利用しない形で実装している
//
// Warning:
//    ReactDOM.unstable_renderSubtreeIntoContainer() is no longer supported in React 18.
//    Consider using a portal instead.
//    Until you switch to the createRoot API, your app will behave as if it's running React 17.
//    Learn more: https://reactjs.org/link/switch-to-createroot
const HelpIconTooltip = ({ placement, message, children }) => {
  return (
    <a data-toggle='tooltip' data-placement={placement} title={message}>
      {children}
    </a>
  );
};

HelpIconTooltip.propTypes = propTypes;
HelpIconTooltip.defaultProps = defaultProps;

export default HelpIconTooltip;
