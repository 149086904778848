import React from 'react';

import LoadingSuspense from './LoadingSuspense';
export default LoadingSuspense;

// @see https://ja.reactjs.org/docs/higher-order-components.html
export const withLoadingSuspense = (Component) => {
  const WrappedComponent = (props) => (
    <LoadingSuspense>
      <Component {...props} />
    </LoadingSuspense>
  );

  WrappedComponent.displayName = `withLoadingSuspense(${
    Component.displayName || Component.name || 'Component'
  })`;
  WrappedComponent.propTypes = Component.propTypes;
  WrappedComponent.defaultProps = Component.defaultProps;

  return WrappedComponent;
};
