/* eslint @typescript-eslint/explicit-function-return-type: off */

export const normalizeNumber = (number) => {
  if (isNaN(number) || !isFinite(number)) {
    return 0;
  }
  return number;
};

// 通貨フォーマットの precision に指定された桁で丸める
// @see https://johobase.com/javascript-floor-ceil-round/#:~:text=%E6%95%B0%E5%80%A4%E3%82%92%E6%95%B4%E6%95%B0%E3%81%AB%E5%9B%9B%E6%8D%A8%E4%BA%94%E5%85%A5,()%E3%83%A1%E3%82%BD%E3%83%83%E3%83%89%E3%82%92%E4%BD%BF%E3%81%84%E3%81%BE%E3%81%99%E3%80%82&text=Math.round()%E3%83%A1%E3%82%BD%E3%83%83%E3%83%89%E3%81%AF,%E3%81%AE%E6%95%B4%E6%95%B0%E3%82%92%E8%BF%94%E3%81%97%E3%81%BE%E3%81%99%E3%80%82&text=Math.round()%E3%83%A1%E3%82%BD%E3%83%83%E3%83%89%E3%81%AE,%E3%81%AF%E6%B3%A8%E6%84%8F%E3%81%97%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%E3%80%82
const mathForCurrency = (mathFunc, number, currency) => {
  const { precision } = I18n.t('number.currency.format', { locale: currency });
  return mathFunc(number * Math.pow(10, precision)) / Math.pow(10, precision);
};
export const floorForCurrency = (number, currency) =>
  mathForCurrency(Math.floor, number, currency);
export const ceilForCurrency = (number, currency) =>
  mathForCurrency(Math.ceil, number, currency);
export const roundForCurrency = (number, currency) =>
  mathForCurrency(Math.round, number, currency);

// DEPRECATED: Use `I18n.toNumber` instead.
export const formatNumber = (number, options = {}) =>
  I18n.toNumber(number, options);

// DEPRECATED: Use `I18n.toNumber` instead.
export const formatInteger = (number, options = {}) =>
  formatNumber(number, { ...options, precision: 0 });

// DEPRECATED: Use `I18n.toPercentage` instead.
export const formatPercentage = (number, options = {}) =>
  I18n.toPercentage(number, options);

export const formatCurrency = (number, currency, options = {}) =>
  I18n.toCurrency(number, { ...options, locale: currency });
