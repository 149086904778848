/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import * as DataTableBeLike from '../DataTableBeLike.propTypes';
import * as BS from 'react-bootstrap';

const propTypes = {
  selections: DataTableBeLike.propTypes.limitSelections,
  selected: DataTableBeLike.propTypes.limit,
  onChange: DataTableBeLike.propTypes.onLimitChange,
};

class LimitSelect extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { onChange } = this.props;
    const { value } = event.target;
    onChange(parseInt(value), event);
  }

  render() {
    const { selections, selected } = this.props;

    return (
      <div className='limit-select'>
        <label>
          {'Show '}
          <BS.FormControl
            componentClass='select'
            bsSize='sm'
            defaultValue={selected}
            onChange={this.handleChange}
          >
            {selections.map((limit, index) => (
              <option key={index} value={limit}>
                {limit}
              </option>
            ))}
          </BS.FormControl>
        </label>
      </div>
    );
  }
}

export default LimitSelect;
