import { client } from '../';

const PeriodBudgetViewQuery = `
  query($search: JSON!, $order: [String!], $page: Int!, $limit: Int!) {
    periodBudgetView(
      search: $search
      order: $order
      page: $page
      limit: $limit
    ) {
      data {
        id, name, startDate, endDate, days, amount
        progressStatus, progressDays, cost, impressions, clicks
        avgDailyCost, cpm, cpc, ctr
        progressRate, remainingAmount, remainingDays
        forecastCost, forecastProgressRate, forecastRemainingAmount
        recommendedDailyAmount
        conversions00, conversions01, conversions02, conversions03, conversions04, conversions05, conversions06, conversions07, conversions08, conversions09
        conversions10, conversions11, conversions12, conversions13, conversions14, conversions15, conversions16, conversions17, conversions18, conversions19
        conversions20, conversions21, conversions22, conversions23, conversions24, conversions25, conversions26, conversions27, conversions28, conversions29
        notStarted, inProgress, ended
        project { id, name, currency }
      }
      paging { totalCount, totalPages }
    }
  }
`;

export const queryPeriodBudgetView = async (
  variables,
  operationContext = {}
) => {
  const { data, error } = await client
    .query(PeriodBudgetViewQuery, variables, operationContext)
    .toPromise();
  if (error) {
    throw new Error(error);
  }

  return data.periodBudgetView;
};
