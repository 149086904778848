/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ elementType = 'h3', children }) =>
  React.createElement(elementType, { className: 'box-title' }, children);

Title.propTypes = {
  elementType: PropTypes.string,
  children: PropTypes.node,
};

export default Title;
