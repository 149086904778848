import { client } from '../';

const ProjectAccessibilitiesQuery = `
  query($page: Int!, $search: JSON!) {
    projectAccessibilities(
      order: ["project.id"]
      page: $page
      search: $search
      limit: 50
    ) {
      data {
        project { id, name, url }
      }
      paging { nextPage }
    }
  }
`;

const ProjectAccessibilitiyQuery = `
  query($projectId: ID!) {
    projectAccessibility(projectId: $projectId) {
      isOwner
      project {
        id, name, currency
        owner { id, email, name }
      }
    }
  }
`;

export const queryProjectAccessibilities = async (
  variables,
  operationContext = {}
) => {
  const { data, error } = await client
    .query(ProjectAccessibilitiesQuery, variables, operationContext)
    .toPromise();
  if (error) {
    throw new Error(error);
  }

  return data.projectAccessibilities;
};

export const queryProjectAccessibility = async (
  projectId,
  operationContext = {}
) => {
  const { data, error } = await client
    .query(ProjectAccessibilitiyQuery, { projectId }, operationContext)
    .toPromise();
  if (error) {
    throw new Error(error);
  }

  return data.projectAccessibility;
};
