import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from '../ErrorBoundary';
import { withLoadingSuspense } from '../LoadingSuspense';
import { withCurrentProjectAccessibility } from '../../contexts';
import { useCurrentProjectAccessibility } from '../../hooks';
import {
  withClient,
  queryPeriodBudget,
  mutationCreatePeriodBudget,
} from '../../graphql';
import { FlowSpinner } from '../atoms/Loading';
import Box from '../atoms/Box';
import { Button, ButtonToolbar } from 'react-bootstrap';
import Form from './Form';
import { omitProps } from '../../lib/objectUtils';
import { registerLocale } from '../../lib/i18n';

registerLocale(I18n.locale);

const scope = 'frontend.components.PeriodBudgetReport.CreateFormBox';

const propTypes = {
  callbackUrl: PropTypes.string.isRequired,
  backwardUrl: PropTypes.string.isRequired,
};

const defaultProps = {};

const CreateFormBox = ({ callbackUrl, backwardUrl }) => {
  const [error, setError] = useState(null);
  const {
    accessibility: { project },
  } = useCurrentProjectAccessibility();
  const [formData, setFormData] = useState(null);

  const url = new URL(location.href);
  const baseId = url.searchParams.get('duplicate');

  useEffect(() => {
    if (baseId) {
      (async () => {
        try {
          const periodBudget = await queryPeriodBudget(baseId);
          setFormData({
            ...omitProps(periodBudget, 'id'),
            name: `${periodBudget.name} - Copy`,
          });
        } catch (error) {
          setError(error);
        }
      })();
    } else {
      setFormData({ projectId: project.id });
    }
  }, [baseId]);

  if (error) {
    throw error;
  }

  if (!formData) {
    return <FlowSpinner />;
  }

  const handleSubmit = async (formData, actions) => {
    const { errors } = await mutationCreatePeriodBudget(formData);
    if (errors) {
      for (const { field, messages } of errors) {
        actions.setFieldError(field, messages.join('\n'));
      }
      actions.setSubmitting(false);
      return;
    }

    const url = new URL(callbackUrl);
    url.searchParams.set('notify', 'info:created');
    location.href = url;
  };

  return (
    <Form initialValues={formData} onSubmit={handleSubmit}>
      {({ formik: { isSubmitting }, formFields }) => (
        <Box>
          <Box.Body>{formFields}</Box.Body>
          <Box.Footer>
            <ButtonToolbar>
              <Button type='submit' bsStyle='primary' disabled={isSubmitting}>
                {I18n.t([scope, 'action.create'])}
              </Button>
              <Button
                bsStyle='default'
                onClick={() => (location.href = backwardUrl)}
              >
                {I18n.t([scope, 'action.back'])}
              </Button>
            </ButtonToolbar>
          </Box.Footer>
        </Box>
      )}
    </Form>
  );
};

CreateFormBox.propTypes = propTypes;
CreateFormBox.defaultProps = defaultProps;

export default withErrorBoundary(
  withLoadingSuspense(
    withClient(withCurrentProjectAccessibility(CreateFormBox))
  )
);
