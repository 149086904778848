/* eslint @typescript-eslint/explicit-function-return-type: off */

import * as Routes from '../routes/AdPerformanceReportImportJob';
import * as RESTAPI from '../lib/RESTAPI';

export const list = (projectId, listParams) => {
  const url = Routes.indexPath(projectId);
  return RESTAPI.get(url, listParams);
};

export const create = (projectId, jobParams) => {
  const url = Routes.createPath(projectId);
  return RESTAPI.post(
    url,
    { adPerformanceReportImportJob: jobParams },
    { multipart: true }
  );
};
