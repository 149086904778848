/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import Project from '../../models/Project';
import ConversionPoint from '../../models/ConversionPoint';
import { ReportRow } from '../../models/MonthlyBudget';
import { Row, Col } from 'react-bootstrap';
import Box from '../atoms/Box';
import MonthSelectDropdown from './MonthSelectDropdown';
import ConversionPointSelectDropdown from './ConversionPointSelectDropdown';
import ColumnSelectDropdown from './ColumnSelectDropdown';
import ActionSelectDropdown from './ActionSelectDropdown';
import ListTable, { AVAILABLE_COLUMNS, FIXED_COLUMNS } from './ListTable';
import * as Resource from '../../apis/BudgetReport';

const propTypes = {
  projects: PropTypes.arrayOf(PropTypes.exact(Project.propTypes)),
  conversionPoints: PropTypes.arrayOf(
    PropTypes.exact(ConversionPoint.propTypes)
  ),
};

const defaultProps = {};

const getSelectedMonth = () => {
  const params = new URLSearchParams(location.search);
  const year = params.has('year') ? parseInt(params.get('year')) : null;
  const month = params.has('month') ? parseInt(params.get('month')) : null;

  return { year, month };
};

const getSelectedConversionPointIds = () => {
  const params = new URLSearchParams(location.search);
  if (params.has('cv_point_ids[]')) {
    return params.getAll('cv_point_ids[]').map((id) => parseInt(id));
  }

  const cvPointIds = [];
  const regex = new RegExp(
    '/projects/[0-9]+/budget_reports/selected_conversion_point_ids'
  );
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (!key.match(regex)) continue;

    const ids = localStorage.getItem(key);
    if (ids) cvPointIds.push(...ids.split(',').map((id) => parseInt(id)));
  }

  return cvPointIds;
};

const getSekectedColumns = () => {
  const selected = localStorage.getItem(
    '/projects/budget_reports/selected_columns'
  );
  if (!selected) return AVAILABLE_COLUMNS;

  const selectedColumns = selected.split(',');
  return AVAILABLE_COLUMNS.filter(
    (column) =>
      FIXED_COLUMNS.includes(column) || selectedColumns.includes(column)
  );
};

class IndexPageContent extends React.Component {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  constructor(props) {
    super(props);

    const availableCvPointIds = props.conversionPoints.map(({ id }) => id);

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const { year, month } = getSelectedMonth();
    const cvPointIds = getSelectedConversionPointIds().filter((id) =>
      availableCvPointIds.includes(id)
    );
    const columns = getSekectedColumns();
    this.state = {
      year: year || yesterday.getFullYear(),
      month: month || yesterday.getMonth() + 1,
      cvPointIds,
      columns,
      dataRows: [],
      fetching: false,
    };
  }

  componentDidMount = () => {
    return this.fetchData();
  };

  fetchData = (updating = {}) => {
    const newState = { ...this.state, ...updating, fetching: true };
    this.setState(newState);

    const canonicalUrl = this.canonicalUrl(newState);
    history.replaceState(null, document.title, canonicalUrl);

    const { projects } = this.props;
    const projectId = projects.length == 1 ? projects[0].id : null;
    const { year, month, cvPointIds } = newState;
    return Resource.list(projectId, { year, month, cvPointIds })
      .then(({ data }) =>
        this.setState({
          dataRows: data.map((d) => new ReportRow(d)),
        })
      )
      .catch((error) => console.error(error))
      .finally(() => this.setState({ fetching: false }));
  };

  canonicalUrl = (newState = {}) => {
    const { year, month, cvPointIds } = newState;

    const url = new URL(location.href);
    url.searchParams.set('year', year);
    url.searchParams.set('month', month);
    url.searchParams.delete('cv_point_ids[]');
    cvPointIds.forEach((id) => url.searchParams.append('cv_point_ids[]', id));

    return url.toString();
  };

  handleMonthChange = (year, month) => {
    return this.fetchData({ year, month });
  };

  handleConversionPointChange = (conversionPoint, checked) => {
    const cvPointIds = this.state.cvPointIds.slice();
    if (checked) {
      cvPointIds.push(conversionPoint.id);
    } else {
      cvPointIds.splice(cvPointIds.indexOf(conversionPoint.id), 1);
    }

    const { projects, conversionPoints } = this.props;

    const selectedCvPoints = cvPointIds.reduce((cvPoints, targetId) => {
      const cvPoint = conversionPoints.find(({ id }) => id == targetId);
      if (cvPoint) cvPoints.push(cvPoint);
      return cvPoints;
    }, []);

    projects.forEach(({ id }) => {
      const key = `/projects/${id}/budget_reports/selected_conversion_point_ids`;
      const cvPoints = selectedCvPoints.filter(
        ({ projectId }) => projectId == id
      );
      if (cvPoints.length) {
        localStorage.setItem(key, cvPoints.map(({ id }) => id).join(','));
      } else {
        localStorage.removeItem(key);
      }
    });

    this.fetchData({ cvPointIds });
  };

  handleColumnsChange = (columns) => {
    if (columns.length > FIXED_COLUMNS.length) {
      localStorage.setItem(
        '/projects/budget_reports/selected_columns',
        columns.join(',')
      );
      this.setState({ columns: columns.slice() });
    } else {
      localStorage.removeItem('/projects/budget_reports/selected_columns');
      this.setState({ columns: AVAILABLE_COLUMNS });
    }
  };

  handleRowChange = (index, row) => {
    const dataRows = this.state.dataRows.slice();
    dataRows[index] = row;
    this.setState({ dataRows });
  };

  handleBudgetsCopy = ({ overwrite }) => {
    const { projects } = this.props;
    const { year, month } = this.state;

    const prevMonth = new Date(year, month - 2);
    const srcYear = prevMonth.getFullYear();
    const srcMonth = prevMonth.getMonth() + 1;

    const promise = projects.reduce((promise, project) => {
      return promise.then(() => {
        return Resource.copyBudgets(project.id, {
          year,
          month,
          srcYear,
          srcMonth,
          overwrite,
        });
      });
    }, Promise.resolve());

    return promise.then(() => this.fetchData({}));
  };

  render = () => {
    const { projects, conversionPoints } = this.props;
    const { year, month, cvPointIds, columns, dataRows, fetching } = this.state;

    return (
      <>
        <Row className='actions-container'>
          <Col md={6} className='left-container'>
            <MonthSelectDropdown
              id='month-select'
              year={year}
              month={month}
              onChange={this.handleMonthChange}
              disabled={fetching}
            />
          </Col>
          <Col md={6} className='right-container'>
            <ConversionPointSelectDropdown
              id='cvs-point-select'
              projects={projects}
              conversionPoints={conversionPoints.filter(
                ({ valueType }) => valueType == 'conversions'
              )}
              valueType='conversions'
              selected={cvPointIds}
              onChange={this.handleConversionPointChange}
              disabled={fetching}
            />
            <ConversionPointSelectDropdown
              id='cvv-point-select'
              projects={projects}
              conversionPoints={conversionPoints.filter(
                ({ valueType }) => valueType == 'conversion_value'
              )}
              valueType='conversion_value'
              selected={cvPointIds}
              onChange={this.handleConversionPointChange}
              disabled={fetching}
            />
            <ColumnSelectDropdown
              id='column-select'
              selected={columns}
              onChange={this.handleColumnsChange}
            />
            <ActionSelectDropdown
              id='action-select'
              onBudgetsCopy={this.handleBudgetsCopy}
              disabled={fetching}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Box>
              <Box.Body>
                <ListTable
                  year={year}
                  month={month}
                  cvPointIds={cvPointIds}
                  rows={dataRows}
                  columns={columns}
                  fetching={fetching}
                  onChange={this.handleRowChange}
                />
              </Box.Body>
            </Box>
          </Col>
        </Row>
      </>
    );
  };
}

export default IndexPageContent;
