import React, { useEffect, useMemo, useState, useId } from 'react';
import PropTypes from 'prop-types';
import { ReactTableContext } from '../../../contexts';
import { ProgressStatus } from '../../../enums/PeriodBudgetView';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import {
  Table,
  PageLength,
  Pager,
  PagingInfo,
  ColumnVisibility,
  Layout,
} from '../../molecules/ReactTableDataTableStyled';
import SearchBuilder, {
  searchFieldBuilder,
} from '../../molecules/ReactTableDataTableStyled/SearchBuilder';
import { Label, Dropdown, MenuItem } from 'react-bootstrap';
import {
  queryPeriodBudgetView,
  mutationDeletePeriodBudget,
} from '../../../graphql';
import {
  formatCurrency,
  normalizeNumber as normalize,
} from '../../../lib/NumberUtils';
import classNames from 'classnames';
import * as FlashMessage from '../../../lib/FlashMessage';

const scope = 'frontend.components.PeriodBudgetReport.IndexPage.ListTable';

const propTypes = {
  responsive: PropTypes.bool,
  initialState: PropTypes.object,
  aggregateConversions: PropTypes.func,
  aggregateConversionValue: PropTypes.func,
  onFetch: PropTypes.func,
  onStateChange: PropTypes.func,
};

const defaultProps = {
  onFetch: async (query, variables) => await query(variables),
};

const ListTable = ({
  responsive,
  initialState,
  aggregateConversions,
  aggregateConversionValue,
  onFetch,
  onStateChange,
}) => {
  const handleDelete = async ({ id, name }) => {
    const result = window.confirm(
      I18n.t([scope, 'message.confirmDelete'], { name })
    );
    if (!result) return;

    const { errors } = await mutationDeletePeriodBudget({ id });
    if (errors) {
      FlashMessage.add('warning', I18n.t('frontend.common.errors.unexpected'));
      return;
    }

    const url = new URL(location.href);
    url.searchParams.set('notify', 'info:deleted');
    location.href = url;
  };

  const columnHelper = createColumnHelper();
  const columns = useMemo(
    () => [
      // 名前
      columnHelper.accessor('name', {
        id: 'name',
        header: I18n.t([scope, 'header.name']),
        meta: { field: 'name', search: searchFieldBuilder.string() },
      }),

      // ステータス
      columnHelper.accessor('progressStatus', {
        id: 'progressStatus',
        header: I18n.t([scope, 'header.progressStatus']),
        cell: ({ getValue, row: { original: data } }) => {
          const progressStatus = Object.values(ProgressStatus).find(
            (progressStatus) => progressStatus == getValue()
          );
          return (
            <Label
              bsStyle={(() => {
                if (data.inProgress) return 'primary';
                if (data.ended) return 'default';
                return 'warning';
              })()}
            >
              {progressStatus.label}
            </Label>
          );
        },
        meta: {
          field: 'progressStatus',
          search: searchFieldBuilder.enum({ enums: ProgressStatus }),
        },
      }),

      // 操作
      columnHelper.display({
        id: 'actions',
        header: I18n.t([scope, 'header.actions']),
        cell: ({ row: { original: data } }) => {
          const id = useId();
          return (
            <Dropdown {...{ id }} dropup>
              <Dropdown.Toggle bsSize='xsmall'>
                {I18n.t([scope, 'header.actions'])}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <MenuItem
                  onClick={() => {
                    location.href = `/projects/${data.project.id}/period_budget_reports/${data.id}/edit`;
                  }}
                >
                  {I18n.t([scope, 'row.actions.edit'])}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    location.href = `/projects/${data.project.id}/period_budget_reports/new?duplicate=${data.id}`;
                  }}
                >
                  {I18n.t([scope, 'row.actions.copy'])}
                </MenuItem>
                <MenuItem
                  onClick={(ev) => {
                    ev.preventDefault();
                    handleDelete(data);
                  }}
                >
                  {I18n.t([scope, 'row.actions.delete'])}
                </MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
        meta: {
          getCellProps: () => ({ className: 'text-nowrap' }),
        },
      }),

      // 実績
      columnHelper.group({
        header: I18n.t([scope, 'header.performance']),
        columns: [
          // 実績 -> CPM
          columnHelper.accessor('cpm', {
            id: 'cpm',
            header: I18n.t([scope, 'header.performance/cpm']),
            cell: ({ getValue, row: { original: data } }) =>
              formatCurrency(getValue(), data.project.currency),
            meta: {
              field: 'cpmMicro',
              search: searchFieldBuilder.microNumber(),
              getCellProps: () => ({ className: 'text-right text-nowrap' }),
            },
          }),

          // 実績 -> CPC
          columnHelper.accessor('cpc', {
            id: 'cpc',
            header: I18n.t([scope, 'header.performance/cpc']),
            cell: ({ getValue, row: { original: data } }) =>
              formatCurrency(getValue(), data.project.currency),
            meta: {
              field: 'cpcMicro',
              search: searchFieldBuilder.microNumber(),
              getCellProps: () => ({ className: 'text-right text-nowrap' }),
            },
          }),

          // 実績 -> Imps
          columnHelper.accessor('impressions', {
            id: 'impressions',
            header: I18n.t([scope, 'header.performance/impressions']),
            cell: ({ getValue }) => I18n.toNumber(getValue(), { precision: 0 }),
            meta: {
              field: 'impressions',
              search: searchFieldBuilder.number(),
              getCellProps: () => ({ className: 'text-right text-nowrap' }),
            },
          }),

          // 実績 -> CTR
          columnHelper.accessor('ctr', {
            id: 'ctr',
            header: I18n.t([scope, 'header.performance/ctr']),
            cell: ({ getValue }) => I18n.toPercentage(getValue() * 100),
            meta: {
              field: 'ctr',
              search: searchFieldBuilder.rateAsPercentage(),
              getCellProps: () => ({ className: 'text-right text-nowrap' }),
            },
          }),

          // 実績 -> Clicks
          columnHelper.accessor('clicks', {
            id: 'clicks',
            header: I18n.t([scope, 'header.performance/clicks']),
            cell: ({ getValue }) => I18n.toNumber(getValue(), { precision: 0 }),
            meta: {
              field: 'clicks',
              search: searchFieldBuilder.number(),
              getCellProps: () => ({ className: 'text-right text-nowrap' }),
            },
          }),

          // 実績 -> CVR
          columnHelper.display({
            id: 'cvr',
            header: I18n.t([scope, 'header.performance/cvr']),
            cell: ({ row: { original: data } }) =>
              I18n.toPercentage(
                normalize((aggregateConversions(data) / data.clicks) * 100)
              ),
            meta: {
              getCellProps: () => ({ className: 'text-right text-nowrap' }),
            },
          }),

          // 実績 -> CV 数
          columnHelper.display({
            id: 'conversions',
            header: I18n.t([scope, 'header.performance/conversions']),
            cell: ({ row: { original: data } }) =>
              I18n.toNumber(normalize(aggregateConversions(data)), {
                precision: 0,
              }),
            meta: {
              getCellProps: () => ({ className: 'text-right text-nowrap' }),
            },
          }),

          // 実績 -> CPA
          columnHelper.display({
            id: 'cpa',
            header: I18n.t([scope, 'header.performance/cpa']),
            cell: ({ row: { original: data } }) =>
              formatCurrency(
                normalize(data.cost / aggregateConversions(data)),
                data.project.currency
              ),
            meta: {
              getCellProps: () => ({ className: 'text-right text-nowrap' }),
            },
          }),

          // 実績 -> CV 値
          columnHelper.display({
            id: 'conversionValue',
            header: I18n.t([scope, 'header.performance/conversionValue']),
            cell: ({ row: { original: data } }) =>
              I18n.toNumber(normalize(aggregateConversionValue(data)), {
                precision: 0,
              }),
            meta: {
              getCellProps: () => ({ className: 'text-right text-nowrap' }),
            },
          }),

          // 実績 -> ROAS
          columnHelper.display({
            id: 'roas',
            header: I18n.t([scope, 'header.performance/roas']),
            cell: ({ row: { original: data } }) =>
              I18n.toPercentage(
                normalize((aggregateConversionValue(data) / data.cost) * 100),
                { precision: 0 }
              ),
            meta: {
              getCellProps: () => ({ className: 'text-right text-nowrap' }),
            },
          }),

          // 実績 -> 費用
          columnHelper.accessor('cost', {
            id: 'cost',
            header: I18n.t([scope, 'header.performance/cost']),
            cell: ({ getValue, row: { original: data } }) =>
              formatCurrency(getValue(), data.project.currency),
            meta: {
              field: 'costMicro',
              search: searchFieldBuilder.number(),
              getCellProps: () => ({ className: 'text-right text-nowrap' }),
            },
          }),
        ],
      }),

      // 予算
      columnHelper.group({
        header: I18n.t([scope, 'header.budgetProgress']),
        columns: [
          // 予算 -> 期間予算
          columnHelper.accessor('amount', {
            id: 'amount',
            header: I18n.t([scope, 'header.budgetProgress/amount']),
            cell: ({ getValue, row: { original: data } }) =>
              formatCurrency(getValue(), data.project.currency),
            meta: {
              field: 'amountMicro',
              search: searchFieldBuilder.microNumber(),
              getCellProps: () => ({ className: 'text-right text-nowrap' }),
            },
          }),

          // 予算 -> 設定期間
          columnHelper.accessor(
            (data) => {
              const startDate = I18n.l('date.formats.default', data.startDate);
              const endDate = I18n.l('date.formats.default', data.endDate);
              return I18n.t([scope, 'row.period'], { startDate, endDate });
            },
            {
              id: 'period',
              header: I18n.t([scope, 'header.budgetProgress/period']),
              enableColumnFilter: false,
              meta: {
                field: 'startDate',
                getCellProps: () => ({ className: 'text-nowrap' }),
              },
            }
          ),

          // 予算 -> 期間日数
          columnHelper.accessor('days', {
            id: 'days',
            header: I18n.t([scope, 'header.budgetProgress/days']),
            cell: ({ getValue }) => I18n.toNumber(getValue(), { precision: 0 }),
            meta: {
              field: 'days',
              search: searchFieldBuilder.number(),
              getCellProps: () => ({ className: 'text-right text-nowrap' }),
            },
          }),

          // 予算 -> 進捗率
          columnHelper.accessor('progressRate', {
            id: 'progressRate',
            header: I18n.t([scope, 'header.budgetProgress/progressRate']),
            cell: ({ getValue }) => I18n.toPercentage(getValue() * 100),
            meta: {
              field: 'progressRate',
              search: searchFieldBuilder.rateAsPercentage(),
              getCellProps: ({ getValue }) => ({
                className: classNames('text-right', 'text-nowrap', {
                  'bg-danger': 1 < getValue(),
                  'bg-success': 0.95 <= getValue() && getValue() < 1,
                  'bg-warning': getValue() < 0.95,
                }),
              }),
            },
          }),

          // 予算 -> 残予算
          columnHelper.accessor('remainingAmount', {
            id: 'remainingAmount',
            header: I18n.t([scope, 'header.budgetProgress/remainingAmount']),
            cell: ({ getValue, row: { original: data } }) =>
              formatCurrency(getValue(), data.project.currency),
            meta: {
              field: 'remainingAmountMicro',
              search: searchFieldBuilder.microNumber(),
              getCellProps: ({ getValue }) => ({
                className: classNames('text-right', 'text-nowrap', {
                  'text-danger': getValue() < 0,
                }),
              }),
            },
          }),

          // 予算 -> 残日数
          columnHelper.accessor('remainingDays', {
            id: 'remainingDays',
            header: I18n.t([scope, 'header.budgetProgress/remainingDays']),
            cell: ({ getValue }) => I18n.toNumber(getValue(), { precision: 0 }),
            meta: {
              field: 'remainingDays',
              search: searchFieldBuilder.number(),
              getCellProps: () => ({ className: 'text-right text-nowrap' }),
            },
          }),
        ],
      }),

      // 着地予測
      columnHelper.group({
        header: I18n.t([scope, 'header.forecast']),
        columns: [
          // 着地予測 -> 着地費用
          columnHelper.accessor('forecastCost', {
            id: 'forecastCost',
            header: I18n.t([scope, 'header.forecast/forecastCost']),
            cell: ({ getValue, row: { original: data } }) =>
              data.inProgress
                ? formatCurrency(getValue(), data.project.currency)
                : '-',
            meta: {
              field: 'forecastCostMicro',
              search: searchFieldBuilder.microNumber(),
              getCellProps: () => ({ className: 'text-right text-nowrap' }),
            },
          }),

          // 着地予測 -> 進捗率
          columnHelper.accessor('forecastProgressRate', {
            id: 'forecastProgressRate',
            header: I18n.t([scope, 'header.forecast/forecastProgressRate']),
            cell: ({ getValue, row: { original: data } }) =>
              data.inProgress ? I18n.toPercentage(getValue() * 100) : '-',
            meta: {
              field: 'forecastProgressRate',
              search: searchFieldBuilder.rateAsPercentage(),
              getCellProps: ({ getValue, row: { original: data } }) => ({
                className: classNames('text-right', 'text-nowrap', {
                  'bg-danger': data.inProgress && 1 < getValue(),
                  'bg-success':
                    data.inProgress && 0.95 <= getValue() && getValue() < 1,
                  'bg-warning': data.inProgress && getValue() < 0.95,
                }),
              }),
            },
          }),

          // 着地予測 -> 残予算
          columnHelper.accessor('forecastRemainingAmount', {
            id: 'forecastRemainingAmount',
            header: I18n.t([scope, 'header.forecast/forecastRemainingAmount']),
            cell: ({ getValue, row: { original: data } }) =>
              data.inProgress
                ? formatCurrency(getValue(), data.project.currency)
                : '-',
            meta: {
              field: 'forecastRemainingAmountMicro',
              search: searchFieldBuilder.microNumber(),
              getCellProps: ({ getValue, row: { original: data } }) => ({
                className: classNames('text-right', 'text-nowrap', {
                  'text-danger': data.inProgress && getValue() < 0,
                }),
              }),
            },
          }),
        ],
      }),

      // 日予算
      columnHelper.group({
        header: I18n.t([scope, 'header.dailyBudget']),
        columns: [
          // 日予算 -> 平均日予算
          columnHelper.accessor('avgDailyCost', {
            id: 'avgDailyCost',
            header: I18n.t([scope, 'header.dailyBudget/avgDailyCost']),
            cell: ({ getValue, row: { original: data } }) =>
              formatCurrency(getValue(), data.project.currency),
            meta: {
              field: 'avgDailyCostMicro',
              search: searchFieldBuilder.microNumber(),
              getCellProps: () => ({ className: 'text-right text-nowrap' }),
            },
          }),

          // 日予算 -> 推奨日予算
          columnHelper.accessor('recommendedDailyAmount', {
            id: 'recommendedDailyAmount',
            header: I18n.t([
              scope,
              'header.dailyBudget/recommendedDailyAmount',
            ]),
            cell: ({ getValue, row: { original: data } }) =>
              data.inProgress
                ? formatCurrency(
                    getValue() < 0 ? 0 : getValue(),
                    data.project.currency
                  )
                : '-',
            meta: {
              field: 'recommendedDailyAmountMicro',
              search: searchFieldBuilder.microNumber(),
              getCellProps: ({ getValue, row: { original: data } }) => ({
                className: classNames('text-right', 'text-nowrap', {
                  'text-danger': data.inProgress && getValue() < 0,
                }),
              }),
            },
          }),
        ],
      }),
    ],
    [aggregateConversions, aggregateConversionValue]
  );

  const [{ totalCount, pageCount }, setPaging] = useState({
    totalCount: -1,
    pageCount: -1,
  });
  const [data, setData] = useState([]);

  const table = useReactTable({
    columns,
    data,
    pageCount,
    initialState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualSorting: true,
    manualPagination: true,
    getRowId: (data, _index, _parent) => data.id,
  });

  // NOTE: `globalFilter` に GraphQL の検索条件を保存している
  //  わりと無理矢理感があるが、テーブルのステートを別で管理すると余計煩雑になる気がするのでこの手法を取っている
  //  (テーブルのステートに独自の状態を保存できれば一番シンプルなのだが、そういうのは定義されていないっぽい)
  const [state, setState] = useState(table.initialState);
  table.setOptions((prev) => ({
    ...prev,
    state,
    onStateChange: (updater) => {
      const nextState = updater(state);

      // 表示件数・ソート順・検索条件が変更になった場合はページ数をリセット
      if (
        state.pagination.pageSize !== nextState.pagination.pageSize ||
        JSON.stringify(state.sorting) !== JSON.stringify(nextState.sorting) ||
        JSON.stringify(state.globalFilter) !==
          JSON.stringify(nextState.globalFilter)
      ) {
        nextState.pagination.pageIndex = 0;
      }

      setState(nextState);
      onStateChange(nextState);
    },
  }));

  const [fetching, setFetching] = useState(false);
  const { sorting, pagination, globalFilter } = state;
  useEffect(() => {
    (async () => {
      setFetching(true);

      const {
        pagination: { pageIndex, pageSize: limit },
        sorting,
        globalFilter: search,
      } = state;

      const order = sorting.map(({ id, desc }) => {
        const column = table.getColumn(id).columnDef;
        return `${column.meta.field}__${desc ? 'DESC' : 'ASC'}`;
      });

      const page = pageIndex + 1;

      const {
        data,
        paging: { totalCount, totalPages: pageCount },
      } = await onFetch(queryPeriodBudgetView, {
        search,
        order,
        page,
        limit,
      });

      setData(data);
      setPaging({ totalCount, pageCount });
      setFetching(false);
    })();
  }, [onFetch, JSON.stringify([sorting, pagination, globalFilter])]);

  return (
    <ReactTableContext.Provider value={table}>
      <Layout
        table={
          <Table
            {...{ responsive, fetching }}
            tallTBodyRowHeight
            disabled={fetching}
          />
        }
        length={<PageLength disabled={fetching} />}
        colVis={<ColumnVisibility disabled={fetching} />}
        searchBuilder={
          <SearchBuilder
            conditions={globalFilter}
            onChange={(conditions) => {
              table.setState((prev) => ({ ...prev, globalFilter: conditions }));
            }}
          />
        }
        pagination={<Pager disabled={fetching} />}
        info={<PagingInfo {...{ totalCount }} />}
      />
    </ReactTableContext.Provider>
  );
};

ListTable.propTypes = propTypes;
ListTable.defaultProps = defaultProps;

export default ListTable;
