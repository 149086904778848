/* eslint @typescript-eslint/explicit-function-return-type: off */

import PropTypes from 'prop-types';
import ApplicationModel from './ApplicationModel';
import Entry from './CommissionConfig/Entry';

const ASSOCIATED_RESOURCE_TYPES = [
  'project',
  'channel',
  'ad_account',
  'ad_campaign',
  'ad_group',
  'ad',
];

const propTypes = {
  ...ApplicationModel.propTypes,
  projectId: PropTypes.number,
  associatedResourceType: PropTypes.oneOf(ASSOCIATED_RESOURCE_TYPES),
  associatedResourceId: PropTypes.number,
  channel: PropTypes.string,
  adAccountId: PropTypes.number,
  adCampaignId: PropTypes.number,
  adGroupId: PropTypes.number,
  adId: PropTypes.number,
  entries: PropTypes.arrayOf(PropTypes.exact(Entry.propTypes)),
  associatedResourceDepth: PropTypes.number,
  associatedResource: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      remoteId: PropTypes.string,
      channel: PropTypes.string,
      name: PropTypes.string,
    }),
    PropTypes.string,
  ]),
};

class CommissionConfig extends ApplicationModel {
  static ASSOCIATED_RESOURCE_TYPES = ASSOCIATED_RESOURCE_TYPES;
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    if (this.entries) this.entries = this.entries.map((e) => new Entry(e));
  }

  get associatedResourceName() {
    switch (this.associatedResourceType) {
      case 'channel':
        return I18n.t(`channel.${this.associatedResource}`);
      default:
        return this.associatedResource.name;
    }
  }
}

export default CommissionConfig;
export { Entry };
