/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import {
  AVAILABLE_COLUMNS,
  FIXED_COLUMNS,
  scope as i18nScope,
} from './ListTable';

const scope = 'frontend.components.BudgetReport.FieldSelectDropdown';

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selected: PropTypes.arrayOf(PropTypes.oneOf(AVAILABLE_COLUMNS)),
  onChange: PropTypes.func,
};

class ColumnSelectDropdown extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.state = { isOpen: false };
  }

  handleToggle = (isOpen, _event, { source }) => {
    // メニューアイテムを選択したときは閉じない
    // これをしないと月選択のカレンダーを開いたときにドロップダウンが閉じてしまう
    if (source == 'select') return;
    this.setState({ isOpen });
  };

  handleChange = (event) => {
    const { checked, value } = event.target;

    const selected = this.props.selected.slice();
    if (checked) {
      selected.push(value);
    } else {
      selected.splice(selected.indexOf(value), 1);
    }

    this.props.onChange(AVAILABLE_COLUMNS.filter((c) => selected.includes(c)));
  };

  render = () => {
    const { id, selected } = this.props;
    const { isOpen } = this.state;

    const columnChunks = AVAILABLE_COLUMNS.reduce((chunks, column) => {
      if (FIXED_COLUMNS.includes(column)) return chunks;

      const previousChunk = chunks[chunks.length - 1];

      const [name, subName] = column.split('.');
      if (subName) {
        if (!previousChunk || previousChunk?.name != name) {
          chunks.push({ name, columns: [] });
        }
      } else {
        if (!previousChunk || previousChunk?.name) {
          chunks.push({ columns: [] });
        }
      }

      chunks[chunks.length - 1].columns.push(column);
      return chunks;
    }, []);

    return (
      <Dropdown
        id={id}
        open={isOpen}
        onToggle={this.handleToggle}
        className='btn-group'
        pullRight
      >
        <Dropdown.Toggle>
          <i className='fa fa-columns' />
          <span style={{ margin: '0 5px' }}>{I18n.t(`${scope}.label`)}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {columnChunks.map((chunk, index) => (
            <ColumnSelectCheckboxGroup
              key={index}
              header={chunk.name}
              columns={chunk.columns}
              selected={selected}
              onChange={this.handleChange}
              withDivider={index != 0}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };
}

class ColumnSelectCheckboxGroup extends React.Component {
  static propTypes = {
    header: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.oneOf(AVAILABLE_COLUMNS)),
    selected: PropTypes.arrayOf(PropTypes.oneOf(AVAILABLE_COLUMNS)),
    onChange: PropTypes.func,
    withDivider: PropTypes.bool,
  };

  render = () => {
    const { header, columns, selected, withDivider } = this.props;

    return (
      <>
        {withDivider && <li className='divider' />}
        {header && (
          <li className='dropdown-header'>
            {I18n.t(`${i18nScope}.header.${header}`)}
          </li>
        )}
        {columns.map((column, index) => (
          <li key={index} className='checkbox'>
            <label className='label-dropdown-report'>
              <input
                type='checkbox'
                value={column}
                checked={selected.includes(column)}
                onChange={this.props.onChange}
              />
              {I18n.t(`${i18nScope}.header.${column.split('.').pop()}`)}
            </label>
          </li>
        ))}
      </>
    );
  };
}

export default ColumnSelectDropdown;
