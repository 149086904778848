/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import { propTypes, defaultProps } from './DataTableBeLike.propTypes';
import * as BS from 'react-bootstrap';
import Table from '../atoms/Table';
import LimitSelect from './DataTableBeLike/LimitSelect';
import Pagination from './DataTableBeLike/Pagination';
import TableHeader from './DataTableBeLike/TableHeader';
import TableBody from './DataTableBeLike/TableBody';

class DataTableBeLike extends React.Component {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  constructor(props) {
    super(props);

    this.renderLimitSelectBox = this.renderLimitSelectBox.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
  }

  renderLimitSelectBox() {
    const { limitSelections, limit, onLimitChange } = this.props;

    return (
      <LimitSelect
        selections={limitSelections}
        selected={limit}
        onChange={onLimitChange}
      />
    );
  }

  renderTable() {
    const { columns, data, loading, emptyDataMessage } = this.props;

    return (
      <Table>
        <TableHeader columns={columns} />
        <TableBody
          columns={columns}
          data={data}
          loading={loading}
          emptyDataMessage={emptyDataMessage}
        />
      </Table>
    );
  }

  renderPagination() {
    const {
      totalPages,
      currentPage,
      pagingLeft,
      pagingRight,
      pagingWindow,
      onPageChange,
    } = this.props;

    return (
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        left={pagingLeft}
        right={pagingRight}
        window={pagingWindow}
        onChange={onPageChange}
      />
    );
  }

  render() {
    const { onLimitChange, onPageChange } = this.props;
    const withHeaderRow = !!onLimitChange;
    const withFooterRow = !!onPageChange;

    const containerClassNames = ['data-table-be-like'];
    containerClassNames.push(
      withHeaderRow ? 'with-header-row' : 'without-header-row'
    );
    containerClassNames.push(
      withFooterRow ? 'with-footer-row' : 'without-footer-row'
    );

    return (
      <div className={containerClassNames.join(' ')}>
        {withHeaderRow && (
          <BS.Row className='header-row'>
            <BS.Col sm={6}>{this.renderLimitSelectBox()}</BS.Col>
            <BS.Col sm={6}></BS.Col>
          </BS.Row>
        )}

        <BS.Row className='table-row'>
          <BS.Col sm={12}>{this.renderTable()}</BS.Col>
        </BS.Row>

        {withFooterRow && (
          <BS.Row className='footer-row'>
            <BS.Col sm={5}></BS.Col>
            <BS.Col sm={7}>{this.renderPagination()}</BS.Col>
          </BS.Row>
        )}
      </div>
    );
  }
}

export default DataTableBeLike;
