/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import * as BS from 'react-bootstrap';
import { formatDate } from '../../lib/DateUtils';

const propTypes = {
  ...DatePicker.propTypes,
  selected: PropTypes.string,
  onChange: PropTypes.func,
};

const defaultProps = {
  dateFormat: 'yyyy/MM/dd',
};

class DateSelect extends React.Component {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  render() {
    const { selected, onChange, ...props } = this.props;

    return (
      <DatePicker
        customInput={<BS.FormControl type='string' />}
        selected={selected && new Date(selected)}
        onChange={(date) => onChange(formatDate(date))}
        {...props}
      />
    );
  }
}

export default DateSelect;
