/* eslint @typescript-eslint/explicit-function-return-type: off */

import PropTypes from 'prop-types';
import ApplicationModel, { CarrierWaveResource } from './ApplicationModel';
import AdAccount from './AdAccount';

const LEVELS = ['ad_account', 'ad_campaign', 'ad_group', 'ad', 'keyword'];

const BREAKDOWNS = [
  'hourly',
  'gender',
  'age',
  'placement',
  'prefecture',
  'device',
];

const STATUSES = [
  'pending_preprocessing',
  'preprocessing',
  'ready',
  'pending_import',
  'importing',
  'finished',
  'canceled',
];

const propTypes = {
  projectId: PropTypes.number,
  adAccountId: PropTypes.number,
  adAccount: PropTypes.exact(AdAccount.propTypes),
  sourceFile: PropTypes.exact(CarrierWaveResource.propTypes),
  originalFilename: PropTypes.string,
  encoding: PropTypes.string,
  withBom: PropTypes.bool,
  beginningCsvLine: PropTypes.number,
  headerColumns: PropTypes.arrayOf(PropTypes.string),
  level: PropTypes.oneOf(LEVELS),
  breakdown: PropTypes.oneOf(BREAKDOWNS),
  preprocessedFile: PropTypes.exact(CarrierWaveResource.propTypes),
  dataCount: PropTypes.number,
  errorCount: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  skipPreview: PropTypes.bool,
  status: PropTypes.oneOf(STATUSES),
  errorType: PropTypes.string,
  errorDetails: PropTypes.string,
  ...ApplicationModel.propTypes,
};

const initialProps = () => ({
  adAccountId: null,
  sourceFile: null,
  beginningCsvLine: 1,
  skipPreview: false,
});

class CsvImportJob extends ApplicationModel {
  static LEVELS = LEVELS;
  static BREAKDOWNS = BREAKDOWNS;
  static STATUSES = STATUSES;
  static propTypes = propTypes;
  static initialProps = initialProps;
}

export default CsvImportJob;
