/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import ApplicationModel from '../../models/ApplicationModel';

const propTypes = {
  ...BS.FormGroup.propTypes,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  model: PropTypes.shape(ApplicationModel.propTypes),
  attribute: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

class FormGroup extends BS.FormGroup {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.validationContext = this.validationContext.bind(this);
  }

  validationContext(model, attributes) {
    if (!model || !attributes || !model.validated) {
      return [null, []];
    }

    const errors = [];
    attributes.forEach((attribute) => {
      errors.push(...(model.errors?.[attribute] || []));
    });
    if (errors.length == 0) {
      return ['success', []];
    }

    const errorMessages = errors.map((error) => error.message);
    return ['error', errorMessages];
  }

  render() {
    const { label, model, attribute, ...baseProps } = this.props;
    const [validationState, errorMessages] = this.validationContext(
      model,
      Array.isArray(attribute) ? attribute : [attribute]
    );

    return (
      <BS.FormGroup {...baseProps} validationState={validationState}>
        {label && <BS.ControlLabel>{label}</BS.ControlLabel>}
        {this.props.children}
        {errorMessages &&
          errorMessages.map((message, index) => (
            <BS.HelpBlock key={index}>{message}</BS.HelpBlock>
          ))}
      </BS.FormGroup>
    );
  }
}

export default FormGroup;
