/* eslint @typescript-eslint/explicit-function-return-type: off */

import { getAuthenticityToken } from '../lib/HTMLDocument';

// @see https://qiita.com/ryo2132/items/bb6b5ef995dbd6656ff2
// @see https://medium.com/javascript-in-plain-english/convert-string-to-different-case-styles-snake-kebab-camel-and-pascal-case-in-javascript-da724b7220d7
export const convertSnakeCaseKeys = (object) => {
  if (Object.prototype.toString.call(object) == '[object Array]') {
    return object.map((entity) => convertSnakeCaseKeys(entity));
  }

  if (Object.prototype.toString.call(object) == '[object Object]') {
    return Object.keys(object).reduce((convertedObject, key) => {
      const snakeCaseKey = key
        .match(/[A-Z]*[a-z]+|[0-9]+/g)
        .join('_')
        .toLowerCase();
      convertedObject[snakeCaseKey] = convertSnakeCaseKeys(object[key]);
      return convertedObject;
    }, {});
  }

  return object;
};

const buildFormData = (object, formData = new FormData(), parent = null) => {
  if (Object.prototype.toString.call(object) == '[object Array]') {
    object.forEach((data, index) =>
      buildFormData(data, formData, `${parent}[${index}]`)
    );
    return formData;
  }

  if (Object.prototype.toString.call(object) == '[object Object]') {
    Object.keys(object).forEach((key) => {
      buildFormData(object[key], formData, parent ? `${parent}[${key}]` : key);
    });
    return formData;
  }

  formData.append(parent, object);
  return formData;
};

const requestHeader = (options) => {
  const { headers, multipart } = options;

  if (multipart) {
    return { ...headers };
  }

  return {
    'Content-Type': 'application/json',
    ...headers,
  };
};

const requestBody = (payload, options) => {
  const { multipart } = options;

  const csrfToken = getAuthenticityToken();
  const body = { ...csrfToken, ...convertSnakeCaseKeys(payload) };

  if (multipart) {
    return buildFormData(body);
  }

  return JSON.stringify(body);
};

const responseJson = (response) => response.json();

const convertObjectToParamArray = (
  params,
  parentKey = null,
  paramsArray = []
) => {
  if (params == null) return paramsArray;

  if (Array.isArray(params)) {
    params.forEach((value) => {
      convertObjectToParamArray(value, `${parentKey}[]`, paramsArray);
    });
  } else if (typeof params == 'object') {
    for (const [key, value] of Object.entries(params)) {
      convertObjectToParamArray(
        value,
        parentKey ? `${parentKey}[${key}]` : key,
        paramsArray
      );
    }
  } else {
    paramsArray.push([parentKey, params]);
  }

  return paramsArray;
};

export const get = (url, params, options = {}) => {
  const query = new URLSearchParams(
    convertObjectToParamArray(convertSnakeCaseKeys(params))
  );
  return fetch(`${url}?${query}`, {
    method: 'GET',
    headers: requestHeader(options),
  }).then(responseJson);
};

export const post = (url, payload, options = {}) => {
  return fetch(url, {
    method: 'POST',
    headers: requestHeader(options),
    body: requestBody(payload, options),
  }).then(responseJson);
};

export const patch = (url, payload, options = {}) => {
  return fetch(url, {
    method: 'PATCH',
    headers: requestHeader(options),
    body: requestBody(payload, options),
  }).then(responseJson);
};

export const destroy = (url, payload = {}, options = {}) => {
  return fetch(url, {
    method: 'DELETE',
    headers: requestHeader(options),
    body: requestBody(payload, options),
  }).then(responseJson);
};
