import React, { useId, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { queryConversionPoints } from '../../../graphql';
import { Dropdown, MenuItem } from 'react-bootstrap';

const scope =
  'frontend.components.PeriodBudgetReport.IndexPageContent.ConversionPointSelectDropdown';

const propTypes = {
  label: PropTypes.string,
  selected: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onFetch: PropTypes.func,
  pullRight: PropTypes.bool,
  disabled: PropTypes.bool,
};

const defaultProps = {
  onFetch: async (query, variables) => await query(variables),
  pullRight: false,
  disabled: false,
};

const ConversionPointSelectDropdown = ({
  label,
  selected,
  onChange,
  onFetch,
  pullRight,
  disabled,
}) => {
  const id = useId();
  const [selectedState, setSelectedState] = useState([]);

  useEffect(() => {
    const fetchAllConversionPoints = async () => {
      const selectedState = [];

      const order = ['projectId__ASC', 'id__ASC'];
      const limit = 100;
      let page = 1;
      while (page) {
        const { data, paging } = await onFetch(queryConversionPoints, {
          order,
          page,
          limit,
        });

        for (const cvPoint of data) {
          selectedState.push([cvPoint, selected.includes(cvPoint.id)]);
        }

        page = paging.nextPage;
      }

      setSelectedState(selectedState);
    };

    fetchAllConversionPoints();
  }, [onFetch]);

  useEffect(() => {
    const newState = [];
    for (const [cvPoint] of selectedState) {
      newState.push([cvPoint, selected.includes(cvPoint.id)]);
    }
    setSelectedState(newState);
  }, [selected]);

  const handleToggle = (index) => {
    const nextState = [...selectedState];
    nextState[index][1] = !nextState[index][1];

    setSelectedState(nextState);
    onChange(nextState);
  };

  const menuItems = selectedState.reduce((list, [cvPoint, checked], i) => {
    if (i == 0 || selectedState[i - 1][0].project.id != cvPoint.project.id) {
      if (list.length) {
        list.push(<MenuItem divider key={`${cvPoint.project.id}--divider`} />);
      }
      list.push(
        <MenuItem header key={`${cvPoint.project.id}--header`}>
          {cvPoint.project.name}
        </MenuItem>
      );
    }

    list.push(
      <li role='presentation' className='checkbox' key={cvPoint.id}>
        <label className='label-dropdown-report'>
          <input
            type='checkbox'
            checked={checked}
            onChange={() => handleToggle(i)}
          />
          {cvPoint.name}
        </label>
      </li>
    );
    return list;
  }, []);

  return (
    <Dropdown {...{ id, pullRight, disabled }}>
      <Dropdown.Toggle>
        <i className='fa fa-flag' /> {label}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {menuItems.length ? (
          menuItems
        ) : (
          <MenuItem header>{I18n.t([scope, 'noItem'])}</MenuItem>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ConversionPointSelectDropdown.propTypes = propTypes;
ConversionPointSelectDropdown.defaultProps = defaultProps;

export default ConversionPointSelectDropdown;
