import React, { createContext } from 'react';
import { queryCurrentUser } from '../graphql';
import retry from 'async-retry';

const Context = createContext();

export default Context;
export const { Provider, Consumer } = Context;

export const withCurrentUser = (Component) => {
  let currentUser;
  let error;

  const WrappedComponent = (props) => {
    if (error) {
      throw error;
    }

    if (!currentUser) {
      throw retry(
        async () => {
          currentUser = await queryCurrentUser();
        },
        { retries: 2 }
      ).catch((err) => (error = err));
    }

    return (
      <Provider value={{ user: currentUser }}>
        <Component {...props} />
      </Provider>
    );
  };

  WrappedComponent.displayName = `withCurrentUser(${
    Component.displayName || Component.name || 'Component'
  })`;
  WrappedComponent.propTypes = Component.propTypes;
  WrappedComponent.defaultProps = Component.defaultProps;

  return WrappedComponent;
};
