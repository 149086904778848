// NOTE: String との == での比較が可能
//  ```js
//    new EnumValue({ value: 'hoge' }) == 'hoge' #=> true
//    new EnumValue({ value: 'hoge' }).toString() #=> "hoge"
//  ```
class EnumValue extends String {
  constructor({ key, value, label }) {
    super(value);

    this.key = key;
    this.value = value;
    this.label = label;
  }

  valueOf = () => this.value;
}

export const createEnums = (keyValueObject, { scope }) => {
  return Object.entries(keyValueObject).reduce((enums, [key, value]) => {
    enums[key] = new EnumValue({ key, value, label: I18n.t([scope, key]) });
    return enums;
  }, {});
};
