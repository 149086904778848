/* eslint @typescript-eslint/explicit-function-return-type: off */

import * as Routes from '../routes/BudgetReport';
import * as RESTAPI from '../lib/RESTAPI';

export const list = (projectId, params) => {
  const url = Routes.indexPath(projectId);
  return RESTAPI.get(url, params);
};

export const setBudget = (projectId, { year, month }, budgetParams) => {
  const url = Routes.setBudgetPath(projectId);
  return RESTAPI.post(url, { year, month, monthlyBudget: budgetParams });
};

export const copyBudgets = (
  projectId,
  { year, month, srcYear, srcMonth, overwrite }
) => {
  const url = Routes.copyBudgetsPath(projectId);
  return RESTAPI.post(url, { year, month, srcYear, srcMonth, overwrite });
};
