import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { useLocalStorage } from '../../../../../hooks';
import AdListTable from '../../../../molecules/AdListTable';
import { conditionBuilder } from '../../../../../graphql';
const { search, filter } = conditionBuilder;
import { ResourceType } from '../../../../../enums/PeriodBudgetResource';
import { RESOURCE_COUNT_LIMIT } from '../../../Form';
import { omitProps } from '../../../../../lib/objectUtils';

const propTypes = {
  formik: PropTypes.object,
};

const defaultProps = {};

const AdTabContent = ({
  formik: {
    values: { projectId, resources },
    setFieldValue,
  },
}) => {
  const [initialTableState, saveInitialTableState] = useLocalStorage(
    '/projects/period_budget_reports/form/ad_tab/table_state',
    { pagination: { pageIndex: 0, pageSize: 25 } }
  );

  const tableState = useMemo(
    () => ({
      rowSelection: resources.reduce((selection, resource) => {
        if (resource.resourceType == ResourceType.Ad) {
          selection[resource.resourceId] = true;
        }
        return selection;
      }, {}),
    }),
    [JSON.stringify(resources)]
  );

  const handleStateChange = ({ pagination, ...state }) =>
    saveInitialTableState({
      ...omitProps(
        state,
        'rowSelection', // リソースの選択状態は保存しない
        'globalFilter' // 検索状態は保存しない
      ),
      pagination: {
        ...pagination,
        pageIndex: 0, // 表示中のページ数は保存しない
      },
    });

  const handleFetch = useCallback(
    async (query, variables) => {
      return await query({
        ...variables,
        search: search.and([
          variables.search,
          filter.eq('projectId', projectId),
          filter.eq('adAccount.active', true),
          filter.eq('adCampaign.active', true),
        ]),
      });
    },
    [projectId]
  );

  const handleToggleSelected = (ad) => {
    const nextResources = [...resources];

    const index = nextResources.findIndex(
      ({ resourceType, resourceId }) =>
        resourceType == ResourceType.Ad && resourceId == ad.id
    );
    if (index < 0) {
      nextResources.push({
        resourceType: ResourceType.Ad,
        resourceId: ad.id,
        channelId: ad.channel.id,
        adAccountId: ad.adAccount.id,
        adCampaignId: ad.adCampaign.id,
        adGroupId: ad.adGroup.id,
        adId: ad.id,
      });
    } else {
      nextResources.splice(index, 1);
    }

    setFieldValue('resources', nextResources);
  };

  const isParentSelected = useCallback(
    (ad) => {
      const parentIndex = resources.findIndex(
        (r) =>
          (r.resourceType == ResourceType.AdAccount &&
            r.resourceId == ad.adAccount.id) ||
          (r.resourceType == ResourceType.AdCampaign &&
            r.resourceId == ad.adCampaign.id) ||
          (r.resourceType == ResourceType.AdGroup &&
            r.resourceId == ad.adGroup.id)
      );
      return 0 <= parentIndex;
    },
    [JSON.stringify(resources)]
  );

  return (
    <AdListTable
      responsive
      initialState={initialTableState}
      state={tableState}
      isParentSelected={isParentSelected}
      enableNewRowSelection={resources.length < RESOURCE_COUNT_LIMIT}
      onStateChange={handleStateChange}
      onFetch={handleFetch}
      onToggleSelected={handleToggleSelected}
    />
  );
};

AdTabContent.propTypes = propTypes;
AdTabContent.defaultProps = defaultProps;

export default connect(AdTabContent);
