const scope =
  'frontend.components.molecules.ReactTableDataTableStyled.SearchBuilder';

export const stringOperators = ['MATCH', 'NOT_MATCH', 'EQUAL', 'NOT_EQUAL'].map(
  (operator) => ({
    name: operator,
    label: I18n.t([scope, `stringOperator.${operator}.label`]),
  })
);

export const numberOperators = [
  'GT',
  'GTEQ',
  'LT',
  'LTEQ',
  'EQUAL',
  'NOT_EQUAL',
].map((operator) => ({
  name: operator,
  label: I18n.t([scope, `numberOperator.${operator}.label`]),
}));

export const enumOperators = ['IN', 'NOT_IN'].map((operator) => ({
  name: operator,
  label: I18n.t([scope, `enumOperator.${operator}.label`]),
}));
