/* eslint @typescript-eslint/explicit-function-return-type: off */

import PropTypes from 'prop-types';
import ApplicationModel from './ApplicationModel';

const AGGREGATION_STRATEGIES = ['sum'];

const VALUE_TYPES = ['conversions', 'conversion_value'];

const propTypes = {
  ...ApplicationModel.propTypes,
  projectId: PropTypes.number,
  dataBindingIndex: PropTypes.number,
  name: PropTypes.string,
  aggregationStrategy: PropTypes.oneOf(AGGREGATION_STRATEGIES),
  valueType: PropTypes.oneOf(VALUE_TYPES),
  precision: PropTypes.number,
};

class ConversionPoint extends ApplicationModel {
  static propTypes = propTypes;
}

export default ConversionPoint;
export { AGGREGATION_STRATEGIES, VALUE_TYPES };
