/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, MenuItem } from 'react-bootstrap';
import Project from '../../models/Project';
import ConversionPoint, { VALUE_TYPES } from '../../models/ConversionPoint';

const scope = 'frontend.components.BudgetReport.ConversionPointSelectDropdown';

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  projects: PropTypes.arrayOf(PropTypes.exact(Project.propTypes)),
  conversionPoints: PropTypes.arrayOf(
    PropTypes.exact(ConversionPoint.propTypes)
  ),
  valueType: PropTypes.oneOf(VALUE_TYPES),
  selected: PropTypes.arrayOf(ConversionPoint.propTypes.id),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

class ConversionPointSelectDropdown extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.state = { isOpen: false };
  }

  handleToggle = (isOpen, _event, { source }) => {
    // メニューアイテムを選択したときは閉じない
    // これをしないと月選択のカレンダーを開いたときにドロップダウンが閉じてしまう
    if (source == 'select') return;
    this.setState({ isOpen });
  };

  conversionPointsFor = (project) => {
    const { conversionPoints } = this.props;
    return conversionPoints.filter(({ projectId }) => projectId == project.id);
  };

  handleCheckboxChange = (conversionPoint, event) => {
    this.props.onChange(conversionPoint, event.target.checked);
  };

  render() {
    const { id, projects, valueType, selected, disabled } = this.props;
    const { isOpen } = this.state;

    const groupedCvPoints = projects.reduce((list, project) => {
      const cvPoints = this.conversionPointsFor(project);
      if (cvPoints.length) {
        list.push([project, cvPoints]);
      }
      return list;
    }, []);

    // if (groupedCvPoints.length == 0) {
    //   groupedCvPoints.push(
    //     <MenuItem header>{I18n.t([scope, 'noItem'])}</MenuItem>
    //   );
    // }

    return (
      <Dropdown
        id={id}
        open={isOpen}
        onToggle={this.handleToggle}
        className='btn-group'
        pullRight
      >
        <Dropdown.Toggle>
          <i className='fa fa-flag' />
          <span style={{ margin: '0 5px' }}>
            {I18n.t(`${scope}.label/${valueType}`)}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {groupedCvPoints.length ? (
            groupedCvPoints.map(([project, cvPoints], index) => (
              <ConversionPointCheckboxGroup
                key={index}
                project={project}
                conversionPoints={cvPoints}
                selected={selected}
                onChange={this.handleCheckboxChange}
                disabled={disabled}
                withDivider={index != 0}
              />
            ))
          ) : (
            <MenuItem header>{I18n.t([scope, 'noItem'])}</MenuItem>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

class ConversionPointCheckboxGroup extends React.Component {
  static propTypes = {
    project: PropTypes.exact(Project.propTypes),
    conversionPoints: propTypes.conversionPoints,
    selected: propTypes.selected,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    withHeader: PropTypes.bool,
    withDivider: PropTypes.bool,
  };
  static defaultProps = {
    withHeader: true,
    withDivider: true,
  };

  handleChange = (conversionPoint) => {
    return (event) => this.props.onChange(conversionPoint, event);
  };

  render = () => {
    const {
      project,
      conversionPoints,
      selected,
      disabled,
      withHeader,
      withDivider,
    } = this.props;

    return (
      <>
        {withDivider && <li className='divider' />}
        {withHeader && <li className='dropdown-header'>{project.name}</li>}
        {conversionPoints.map((cvPoint, index) => (
          <li key={index} className='checkbox'>
            <label className='label-dropdown-report'>
              <input
                type='checkbox'
                value={cvPoint.id}
                checked={selected.includes(cvPoint.id)}
                onChange={this.handleChange(cvPoint)}
                disabled={disabled}
              />
              {cvPoint.name}
            </label>
          </li>
        ))}
      </>
    );
  };
}

export default ConversionPointSelectDropdown;
