/* eslint @typescript-eslint/explicit-function-return-type: off */

import PropTypes from 'prop-types';

const propTypes = {
  projectId: PropTypes.number,
  resourceType: PropTypes.number,
  resourceId: PropTypes.number,
  name: PropTypes.string,
  channel: PropTypes.number,
  adAccountId: PropTypes.number,
  adCampaignId: PropTypes.number,
  spendMicro: PropTypes.number,
  impressions: PropTypes.number,
  clicks: PropTypes.number,
  conversions: PropTypes.number,
  conversionValue: PropTypes.number,
  budgetMicro: PropTypes.number,
  currency: PropTypes.string,
  year: PropTypes.number,
  month: PropTypes.number,
};
propTypes.parent = PropTypes.exact(propTypes);
propTypes.children = PropTypes.arrayOf(PropTypes.exact(propTypes));

class ReportRow {
  static propTypes = propTypes;

  #metadata;

  constructor({ children, ...props }) {
    Object.keys(props).forEach((propName) => {
      this[propName] = props[propName];
    });

    if (children) {
      // "Maximum call stack size exceeded" 対策のため、`parent` に `this` を渡せない
      const parent = new ReportRow(props);

      this.children = children
        .map((child) => new ReportRow({ ...child, parent }))
        .sort((a, b) => b.spend - a.spend); // 費用の降順
    }

    this.#metadata = this.#loadMetadata();
  }

  get projectName() {
    if (this.resourceType == 0) return this.name;
    return this.resourceType < 0 ? null : this.parent?.projectName;
  }

  get channelName() {
    if (this.resourceType == 1) return this.name;
    return this.resourceType < 1 ? null : this.parent?.channelName;
  }

  get adAccountName() {
    if (this.resourceType == 2) return this.name;
    return this.resourceType < 2 ? null : this.parent?.adAccountName;
  }

  get adCampaignName() {
    if (this.resourceType == 3) return this.name;
    return this.resourceType < 3 ? null : this.parent?.adCampaignName;
  }

  get spend() {
    return this.#normalize(this.spendMicro / 1000000);
  }

  get avgDailySpend() {
    const { elapsedDays } = this.#metadata;
    return this.#normalize(this.spend / elapsedDays);
  }

  get budget() {
    return this.#normalize(this.budgetMicro / 1000000);
  }

  get cpm() {
    return this.#normalize((this.spend / this.impressions) * 1000);
  }

  get cpc() {
    return this.#normalize(this.spend / this.clicks);
  }

  get ctr() {
    return this.#normalize(this.clicks / this.impressions);
  }

  get cvr() {
    return this.#normalize(this.conversions / this.clicks);
  }

  get cpa() {
    return this.#normalize(this.spend / this.conversions);
  }

  get roas() {
    return this.#normalize(this.conversionValue / this.spend);
  }

  get progress() {
    return this.#normalize(this.spend / this.budget);
  }

  get remainingBudget() {
    return this.budget - this.spend;
  }

  get remainingDays() {
    return this.#metadata.remainingDays;
  }

  get forecastSpend() {
    if (!this.ofThisMonth) return null;

    const { allDays } = this.#metadata;
    return this.avgDailySpend * allDays;
  }

  get forecastProgress() {
    if (!this.ofThisMonth) return null;
    return this.#normalize(this.forecastSpend / this.budget);
  }

  get forecastRemainingBudget() {
    if (!this.ofThisMonth) return null;
    return this.budget - this.forecastSpend;
  }

  get recommendedDailyBudget() {
    if (!this.ofThisMonth) return null;

    const { remainingDays } = this.#metadata;
    return this.#normalize(this.remainingBudget / remainingDays);
  }

  get ofPastMonth() {
    return this.#metadata.ofPastMonth;
  }

  get ofThisMonth() {
    return this.#metadata.ofThisMonth;
  }

  get ofFutureMonth() {
    return this.#metadata.ofFutureMonth;
  }

  #normalize = (value) => {
    if (isNaN(value) || !isFinite(value)) {
      return 0;
    }
    return value;
  };

  #loadMetadata = () => {
    const { year, month } = this;
    const allDays = new Date(year, month, 0).getDate();

    const target = new Date(year, month - 1, 1);
    const targetPosition = target.getFullYear() * 12 + target.getMonth();

    const current = new Date();
    const currentPosition = current.getFullYear() * 12 + current.getMonth();

    const monthPosition = targetPosition - currentPosition;
    if (monthPosition < 0) {
      // Past month
      return {
        monthPosition,
        ofPastMonth: true,
        ofThisMonth: false,
        ofFutureMonth: false,
        allDays,
        elapsedDays: allDays,
        remainingDays: 0,
      };
    } else if (monthPosition == 0) {
      // This month
      const elapsedDays = current.getDate() - 1; // 前日までの実績
      return {
        monthPosition,
        ofPastMonth: false,
        ofThisMonth: true,
        ofFutureMonth: false,
        allDays,
        elapsedDays,
        remainingDays: allDays - elapsedDays,
      };
    } else {
      // Future month
      return {
        monthPosition,
        ofPastMonth: false,
        ofThisMonth: false,
        ofFutureMonth: true,
        allDays,
        elapsedDays: 0,
        remainingDays: allDays,
      };
    }
  };
}

export default ReportRow;
