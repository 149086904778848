import { createEnums } from './helpers';

const scope = 'frontend.enums.PeriodBudgetView';

export const ProgressStatus = createEnums(
  {
    NotStarted: '0',
    InProgress: '1',
    Ended: '2',
  },
  { scope: `${scope}.ProgressStatus` }
);
