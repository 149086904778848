import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactTableContext } from '../../../contexts';
import { Pagination } from 'react-bootstrap';

const propTypes = {
  disabled: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
};

const toPageList = (startPage, endPage) => {
  return Array(endPage - startPage + 1)
    .fill(startPage)
    .map((page, i) => page + i);
};

const Pager = ({ disabled }) => {
  const { getPageCount, getState, setPageIndex } =
    useContext(ReactTableContext);

  const totalPages = getPageCount();
  const currentPage = getState().pagination.pageIndex + 1;
  const handlePageChange = (page) => setPageIndex(page - 1);

  const left = currentPage < 5 ? toPageList(1, 5) : [1];
  const innder = toPageList(currentPage - 1, currentPage + 1);
  const right =
    totalPages - currentPage + 1 < 5
      ? toPageList(totalPages - 4, totalPages)
      : [totalPages];

  const pages = left
    .concat(innder, right)
    .sort((a, b) => a - b)
    .reduce((pages, page) => {
      if (1 <= page && page <= totalPages && !pages.includes(page)) {
        const lastPage = pages[pages.length - 1];
        if (lastPage && lastPage + 1 != page) {
          pages.push(null); // Insert gap (ellipsis)
        }
        pages.push(page);
      }
      return pages;
    }, []);

  return (
    <Pagination>
      <Pagination.Prev
        disabled={disabled || currentPage == 1}
        onClick={() => 1 < currentPage && handlePageChange(currentPage - 1)}
      >
        Previous
      </Pagination.Prev>

      {pages.map((page, index) =>
        page ? (
          <Pagination.Item
            key={index}
            active={page == currentPage}
            disabled={disabled}
            onClick={() => page != currentPage && handlePageChange(page)}
          >
            {page}
          </Pagination.Item>
        ) : (
          <Pagination.Ellipsis disabled key={index} />
        )
      )}

      <Pagination.Next
        disabled={disabled || currentPage == totalPages}
        onClick={() =>
          currentPage < totalPages && handlePageChange(currentPage + 1)
        }
      >
        Next
      </Pagination.Next>
    </Pagination>
  );
};

Pager.propTypes = propTypes;
Pager.defaultProps = defaultProps;

export default Pager;
