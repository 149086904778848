import React, { useId } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

const scope =
  'frontend.components.molecules.ReactTableDataTableStyled.SearchBuilder.ValueEditor.CheckboxDropdownEditor';

const propTypes = {
  fieldData: PropTypes.object,
  value: PropTypes.arrayOf(PropTypes.string),
  handleOnChange: PropTypes.func,
  disabled: PropTypes.bool,
};

const defaultProps = {};

const CheckboxDropdownEditor = ({
  fieldData: { values },
  value,
  handleOnChange,
  disabled,
}) => {
  const id = useId();

  const label = (() => {
    switch (value.length) {
      case 0:
        return I18n.t([scope, 'label.notSelected']);
      case 1:
        return I18n.t([scope, 'label.selectedOne'], {
          selected: values.find(({ name }) => value.includes(name)).label,
        });
      default:
        return I18n.t([scope, 'label.selectedMulti'], {
          selected: values.find(({ name }) => value.includes(name)).label,
          others: value.length - 1,
        });
    }
  })();

  return (
    <Dropdown {...{ id, disabled }} bsSize='small' className='rule-value'>
      <Dropdown.Toggle>{label}</Dropdown.Toggle>
      <Dropdown.Menu>
        {values.map(({ name, label }, index) => (
          <li role='presentation' className='checkbox' key={index}>
            <label className='label-dropdown-report'>
              <input
                type='checkbox'
                disabled={disabled}
                checked={value.includes(name)}
                onChange={() => {
                  const selected = [...value];
                  const index = selected.findIndex((v) => v == name);
                  if (index < 0) {
                    selected.push(name);
                  } else {
                    selected.splice(index, 1);
                  }
                  handleOnChange(selected);
                }}
              />
              {label}
            </label>
          </li>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

CheckboxDropdownEditor.propTypes = propTypes;
CheckboxDropdownEditor.defaultProps = defaultProps;

export default CheckboxDropdownEditor;
