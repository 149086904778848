/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import * as BSEx from '../BootstrapExtends';
import AdPerformanceReportDataSource from '../../models/AdPerformanceReportDataSource';
import AttributeFieldsForm from './Form/AttributeFieldsForm';
import SegmentFieldsForm from './Form/SegmentFieldsForm';
import MetricFieldsForm from './Form/MetricFieldsForm';
import ExclusionConditionsForm from './Form/ExclusionConditionsForm';

const { DATA_SOURCE_TYPES, BREAKDOWNS, LEVELS } = AdPerformanceReportDataSource;

const scope = 'frontend.components.AdPerformanceReportDataSource.Form';

const propTypes = {
  dataSource: PropTypes.exact(AdPerformanceReportDataSource.propTypes),
  onChange: PropTypes.func,
};

class Form extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleDataSourceTypeChange =
      this.handleDataSourceTypeChange.bind(this);
    this.handleBreakdownChange = this.handleBreakdownChange.bind(this);
    this.handleAssignSegmentSourceOnUploadChanged =
      this.handleAssignSegmentSourceOnUploadChanged.bind(this);
  }

  handleChange(event) {
    event.preventDefault();
    const { dataSource, onChange } = this.props;
    const { name, value } = event.target;
    onChange({ ...dataSource, [name]: value });
  }

  handleDataSourceTypeChange(event) {
    event.preventDefault();
    const { dataSource: current, onChange } = this.props;
    const { value: dataSourceType } = event.target;
    const dataSource = new AdPerformanceReportDataSource({
      ...current,
      dataSourceType,
    });
    onChange({
      ...dataSource,
      assignSourceDateOnUpload: false,
      segmentFields: this.filterSegmentFields(dataSource),
      metricFields: this.filterMetricFields(dataSource),
    });
  }

  handleBreakdownChange(event) {
    event.preventDefault();
    const { dataSource: current, onChange } = this.props;
    const { value: breakdown } = event.target;
    const dataSource = new AdPerformanceReportDataSource({
      ...current,
      breakdown,
    });
    onChange({
      ...dataSource,
      attributeFields: this.filterAttributeFields(dataSource),
    });
  }

  filterAttributeFields(dataSource) {
    const {
      attributeFields,
      availableAttributeFieldTargetTableFields: availableTargetTableFields,
    } = dataSource;

    const filtered = attributeFields.filter((field) =>
      availableTargetTableFields.includes(field.targetTableField)
    );
    if (filtered.length == 0) {
      filtered.push(dataSource.buildAttributeField());
    }

    return filtered;
  }

  filterSegmentFields(dataSource) {
    const {
      segmentFields,
      availableSegmentFieldSourceDataTypes: availableSourceDataTypes,
    } = dataSource;

    const filtered = segmentFields.filter((field) =>
      availableSourceDataTypes.includes(field.sourceDataType)
    );
    if (filtered.length == 0) {
      filtered.push(dataSource.buildSegmentField());
    }

    return filtered;
  }

  filterMetricFields(dataSource) {
    const {
      metricFields,
      availableMetricFieldSourceDataTypes: availableSourceDataTypes,
    } = dataSource;

    const filtered = metricFields.filter((field) =>
      availableSourceDataTypes.includes(field.sourceDataType)
    );
    if (filtered.length == 0) {
      filtered.push(dataSource.buildMetricField());
    }

    return filtered;
  }

  handleAssignSegmentSourceOnUploadChanged(assignSourceDateOnUpload) {
    return () => {
      const { dataSource, onChange } = this.props;

      const segmentFields = assignSourceDateOnUpload
        ? []
        : dataSource.segmentFields.slice();
      if (!assignSourceDateOnUpload && segmentFields.length == 0) {
        segmentFields.push(dataSource.buildSegmentField());
      }

      onChange({ ...dataSource, assignSourceDateOnUpload, segmentFields });
    };
  }

  render() {
    const { dataSource, onChange } = this.props;
    const options = dataSource.options;

    return (
      <BS.Form>
        <BSEx.FormGroup
          label={I18n.t(`${scope}.field.name.label`)}
          model={dataSource}
          attribute='name'
        >
          <BS.FormControl
            type='text'
            name='name'
            value={dataSource.name}
            onChange={this.handleChange}
          />
        </BSEx.FormGroup>
        <BSEx.FormGroup
          label={I18n.t(`${scope}.field.dataSourceType.label`)}
          model={dataSource}
          attribute='dataSourceType'
        >
          <BS.FormControl
            componentClass='select'
            name='dataSourceType'
            value={dataSource.dataSourceType}
            onChange={this.handleDataSourceTypeChange}
          >
            {DATA_SOURCE_TYPES.map((dataSourceType, index) => (
              <option key={index} value={dataSourceType}>
                {I18n.t(
                  `${scope}.field.dataSourceType.selection.${dataSourceType}`
                )}
              </option>
            ))}
          </BS.FormControl>
        </BSEx.FormGroup>
        <BSEx.FormGroup
          label={I18n.t(`${scope}.field.breakdown.label`)}
          model={dataSource}
          attribute='breakdown'
        >
          <BS.FormControl
            componentClass='select'
            name='breakdown'
            value={dataSource.breakdown}
            onChange={this.handleBreakdownChange}
          >
            {BREAKDOWNS.map((breakdown, index) => (
              <option key={index} value={breakdown}>
                {I18n.t(`${scope}.field.breakdown.selection.${breakdown}`)}
              </option>
            ))}
          </BS.FormControl>
        </BSEx.FormGroup>
        <BSEx.FormGroup
          label={I18n.t(`${scope}.field.level.label`)}
          model={dataSource}
          attribute='level'
        >
          <BS.FormControl
            componentClass='select'
            name='level'
            value={dataSource.level}
            onChange={this.handleChange}
          >
            {LEVELS.map((level, index) => (
              <option key={index} value={level}>
                {I18n.t(`${scope}.field.level.selection.${level}`)}
              </option>
            ))}
          </BS.FormControl>
        </BSEx.FormGroup>

        <BSEx.FormGroup label={I18n.t(`${scope}.field.attributeFields.label`)}>
          <AttributeFieldsForm dataSource={dataSource} onChange={onChange} />
        </BSEx.FormGroup>

        <BSEx.FormGroup label={I18n.t(`${scope}.field.segmentFields.label`)}>
          {'assignSourceDateOnUpload' in options && (
            <>
              <BS.Radio
                name='assignSourceDateOnUpload'
                checked={!dataSource.assignSourceDateOnUpload}
                onChange={this.handleAssignSegmentSourceOnUploadChanged(false)}
              >
                {I18n.t(
                  `${scope}.field.assignSourceDateOnUpload.selection.disabled`
                )}
              </BS.Radio>
              <BS.Radio
                name='assignSourceDateOnUpload'
                checked={dataSource.assignSourceDateOnUpload}
                onChange={this.handleAssignSegmentSourceOnUploadChanged(true)}
              >
                {I18n.t(
                  `${scope}.field.assignSourceDateOnUpload.selection.enabled`
                )}
              </BS.Radio>
            </>
          )}
          {(!('assignSourceDateOnUpload' in options) ||
            !dataSource.assignSourceDateOnUpload) && (
            <SegmentFieldsForm dataSource={dataSource} onChange={onChange} />
          )}
        </BSEx.FormGroup>

        <BSEx.FormGroup label={I18n.t(`${scope}.field.metricFields.label`)}>
          <MetricFieldsForm dataSource={dataSource} onChange={onChange} />
        </BSEx.FormGroup>

        <BSEx.FormGroup
          label={I18n.t(`${scope}.field.exclusionConditions.label`)}
        >
          <ExclusionConditionsForm
            dataSource={dataSource}
            onChange={onChange}
          />
        </BSEx.FormGroup>
      </BS.Form>
    );
  }
}

export default Form;
