/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import * as DataTableBeLike from '../DataTableBeLike.propTypes';
import * as BS from 'react-bootstrap';

const propTypes = {
  totalPages: DataTableBeLike.propTypes.totalPages,
  currentPage: DataTableBeLike.propTypes.currentPage,
  left: DataTableBeLike.propTypes.pagingLeft,
  right: DataTableBeLike.propTypes.pagingRight,
  window: DataTableBeLike.propTypes.pagingWindow,
  onChange: DataTableBeLike.propTypes.onPageChange,
};

class Pagination extends React.Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.inRange = this.inRange.bind(this);
    this.pages = this.pages.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  inRange(page) {
    const { totalPages } = this.props;
    return 1 <= page && page <= totalPages;
  }

  pages() {
    const { totalPages, currentPage, left, right, window } = this.props;
    const pages = [];

    for (let page = 1; page <= left; page++) {
      pages.push(page);
    }

    for (
      let page = currentPage - window;
      page <= currentPage + window;
      page++
    ) {
      pages.push(page);
    }

    for (let page = totalPages; page >= totalPages - right + 1; page--) {
      pages.push(page);
    }

    return pages
      .sort((page1, page2) => page1 - page2)
      .filter(this.inRange)
      .reduce((ret, page, idx, src) => {
        // すでにページが存在している場合はスキップ
        if (ret.includes(page)) {
          return ret;
        }

        // 最初のページが 1 ページ目でない場合は三点リーダーを追加
        if (idx == 0 && page != 1) {
          ret.push(null);
        }

        // ページが連続していない場合は三点リーダーを追加
        if (idx > 0 && src[idx - 1] != page - 1) {
          ret.push(null);
        }

        ret.push(page);

        // 最初のページが総ページ数と同じでない場合は三点リーダーを追加
        if (idx == src.length - 1 && page != totalPages) {
          ret.push(null);
        }

        return ret;
      }, []);
  }

  handlePageClick(page) {
    const { onChange } = this.props;

    return (event) => {
      const { currentPage } = this.props;
      if (!this.inRange(page) || page == currentPage) {
        event.preventDefault();
        return;
      }

      onChange(page);
    };
  }

  render() {
    const pages = this.pages();
    if (pages.length == 0) {
      return null;
    }

    const { currentPage, totalPages } = this.props;
    return (
      <BS.Pagination>
        <BS.Pagination.Item
          onClick={this.handlePageClick(currentPage - 1)}
          disabled={currentPage == 1}
        >
          Previous
        </BS.Pagination.Item>

        {pages.map((page, index) =>
          page ? (
            <BS.Pagination.Item
              key={index}
              onClick={this.handlePageClick(page)}
              active={page == currentPage}
            >
              {page}
            </BS.Pagination.Item>
          ) : (
            <BS.Pagination.Ellipsis key={index} />
          )
        )}

        <BS.Pagination.Item
          onClick={this.handlePageClick(currentPage + 1)}
          disabled={currentPage == totalPages}
        >
          Next
        </BS.Pagination.Item>
      </BS.Pagination>
    );
  }
}

export default Pagination;
