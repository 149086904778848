/* eslint @typescript-eslint/explicit-function-return-type: off */

import React from 'react';
import { propTypes } from '../DataTableBeLike.propTypes';

const TableHeader = ({ columns }) => (
  <thead>
    <tr>
      {columns.map((column, index) => (
        <th
          key={index}
          className={
            typeof column.headerClassName == 'function'
              ? column.headerClassName(column)
              : column.headerClassName
          }
        >
          {column.header}
        </th>
      ))}
    </tr>
  </thead>
);

TableHeader.propTypes = {
  columns: propTypes.columns,
};

export default TableHeader;
